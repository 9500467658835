import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
 
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

import SavedDealController, { Props } from "./SavedDealController";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Grid } from '@material-ui/core';



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  spacing: 8,
});

const styles = {
  containerStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '300px',
    marginBottom: '500px',
    gap:'0px',

  
  }
};

export default class SavedDeal extends SavedDealController {

  constructor(props: Props) {
    super(props);
  
  }

  render() {
    return <>
 <Box margin='auto' display={'flex'} flexDirection={'column'} style={{gap:"24px"}} maxWidth={'1144px'} width={'100%'}  >
{this.props.checkHeader=="true"?<Box> 
<Typography style={{fontFamily: "Gotham", fontSize: "30px",fontWeight: "300",
lineHeight: "40px",
textAlign: "left",
color:'#013D4F'
}} >Pick up where you left off</Typography>

<Typography style={{fontFamily:"Gotham",
fontSize: "18px",
fontWeight: "300",
lineHeight: "18px",
textAlign: "left",
color:"#4B4C4B",
marginTop:'5px'
}}>
        Continue Creating Your Deal
</Typography>

</Box>:null}
     <Box display={'flex'} minWidth={'1144px'} justifyContent={'space-between'} alignItems={'center'} >
        <Typography style={{fontFamily: "Roboto",fontSize: "20px",fontWeight: 600,lineHeight: "24px",textAlign: "left",color:'#013D4F'}}>Your Saved Deals</Typography>
        <TextField
          placeholder="Search Dealership"
          variant="outlined"
          size="small"
          style={{ padding:'8px 0px' }}
        />
      </Box>
    </Box>
    <Box minWidth={'1144px'} width="100%" margin={'auto'}> 
    
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{//styleName: text-sm/font-medium;
fontFamily: "Roboto",
fontSize: "14px",
fontWeight: 500,
lineHeight: "22px",
textAlign: "left",
color:'#C5CBC9'
}}>Customer Last Name</TableCell>
            <TableCell style={{//styleName: text-sm/font-medium;
fontFamily: "Roboto",
fontSize: "14px",
fontWeight: 500,
lineHeight: "22px",
textAlign: "left",
color:"#C5CBC9"
}}>Deal ID</TableCell>
            <TableCell style={{//styleName: text-sm/font-medium;
fontFamily: "Roboto",
fontSize: "14px",
fontWeight: 500,
lineHeight: "22px",
textAlign: "left",
color:"#C5CBC9"
}}>Date & Time</TableCell>
            <TableCell style={{//styleName: text-sm/font-medium;
fontFamily: "Roboto",
fontSize: "14px",
fontWeight: 500,
lineHeight: "22px",
textAlign: "left",
color:"#C5CBC9"
}}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows.map((row:any, index:any) => (


            <TableRow key={index}>
              <TableCell style={{fontFamily: "Roboto",
fontSize: "14px",
fontWeight: 500,
lineHeight: "22px",
textAlign: "left",color:'#4B4C4B'
}} >{row?.attributes?.name}</TableCell>
              <TableCell>{row?.attributes?.id}</TableCell>
              <TableCell>{row?.attributes?.date_and_ime}</TableCell>
              <TableCell>
              <Typography data-test-id={`continue_editing_btn/${index}`} style={{fontFamily: "Roboto",
fontSize: "14px",
fontWeight: "700",
lineHeight: "24px",
textAlign: "left",
color:'#013D4F'}} onClick={()=>{
  localStorage.setItem("dId",row?.attributes?.id);
  this.props.handleModal()
  this.navigateToCreatedeal()
  } }>
                  Continue Editing
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    </>
  
  }
}

