// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  styled,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import TableHeadController from "./TableHeadController.web";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  SearchRounded,
} from "@material-ui/icons";
import { checkmark, group, trash } from "./assets";
import { ContentView, DealershipNormalized } from "./MyTeamController.web";

export default class TableHead extends TableHeadController {
  render() {
    const {
      currentView,
      dealershipsData = [],
      totalItems = 0,
      itemsPerPage = 10,
      checkedItems = 0,
      sort,
      search,
      roles,
      role,
      page,
      dealershipsFilter = [],
      isAllChecked,
      isAdmin,
      handleCheckAll,
      handleDealershipsModal,
      handleBulkDeleteUserPrompt,
      handleDealershipsFilterSelect,
      handleDealershipsFilterClose,
    } = this.props;

    return (
      <Container>
        <Box className="leftSideContainer">
          {isAdmin() && currentView !== ContentView.DEALERSHIPS && <CheckboxContainer onClick={handleCheckAll}>
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={()=>{}}
              className="checkbox"
              disabled={!totalItems}
            />
            <span className="checkmark">
              <img src={checkmark} alt="checkmark" />
            </span>
          </CheckboxContainer>}

          {checkedItems ? (
            <Box className="checkedItemsInfo">
              <Typography
                className="itemsQuantity"
                style={{ textTransform: "capitalize" }}
              >
                {`${checkedItems} ${this.findProperItemsName(
                  checkedItems
                )} selected`}
              </Typography>

              {currentView !== ContentView.DEALERSHIPS && (
                <Box className="selectedItemsControls">
                  <StyledTooltip title={`Delete user`}>
                    <IconButton
                      onClick={handleBulkDeleteUserPrompt}
                      style={{ padding: "5px" }}
                    >
                      <img src={trash} alt="delete" />
                    </IconButton>
                  </StyledTooltip>

                  <StyledTooltip title={`Assign to Dealership(s)`}>
                    <IconButton
                      onClick={handleDealershipsModal}
                      style={{ padding: "5px" }}
                    >
                      <img src={group} alt="assign" />
                    </IconButton>
                  </StyledTooltip>
                </Box>
              )}
            </Box>
          ) : (
            <Typography
              style={{ textTransform: "capitalize" }}
            >{`${totalItems} ${this.findProperItemsName(
              totalItems
            )}`}</Typography>
          )}
        </Box>

        <Box className="rightSideContainer">
          <Search
            value={search}
            onChange={this.handleSearch}
            placeholder="Start typing to search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded
                    width="12px"
                    height="12px"
                    style={{ color: "#4b4c4b", fontSize: "15px" }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />

          {currentView !== ContentView.DEALERSHIPS && currentView !== ContentView.SINGLE_DEALERSHIP_USERS && (
            <>
              {dealershipsData.length > 1 && <DealershipsFormControl>
                <Select
                  className="dealershipSelect"
                  value={dealershipsFilter}
                  renderValue={() =>{
                    if (!dealershipsFilter.length) {
                      return <span>Choose Dealerships</span>;
                    }

                    return dealershipsFilter
                      .map(
                        (dealershipId) =>
                          dealershipsData.find(
                            (dealershipToFind) =>
                              dealershipToFind.id === dealershipId
                          )?.name
                      )
                      .join(", ")
                  }}
                  onChange={handleDealershipsFilterSelect}
                  onClose={handleDealershipsFilterClose}
                  disabled={dealershipsData.length === 1}
                  multiple
                  displayEmpty
                  IconComponent={ExpandMoreRounded}
                  MenuProps={{
                    getContentAnchorEl: null,
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    PaperProps: {
                      style: {
                        width: 250,
                      },
                    },
                  }}
                >
                  {dealershipsData.length === 1 ? (
                    <MenuItem value={dealershipsData[0].id}>
                      {dealershipsData[0].name}
                    </MenuItem>
                  ) : (
                    
                      
                      dealershipsData.map(
                        (dealership: DealershipNormalized) => (
                          <MenuItem key={dealership.id} value={dealership.id}>
                            <ListItemText primary={dealership.name} />
                            <StyledCheckbox
                              checked={dealershipsFilter.includes(
                                dealership.id
                              )}
                              checkedIcon={
                                <CheckedIcon>
                                  <img src={checkmark} alt="checkmark" />
                                </CheckedIcon>
                              }
                              icon={<CheckboxIcon />}
                            />
                          </MenuItem>
                        )
                      )
                    
                  )}
                </Select>
              </DealershipsFormControl>}
            </>
          )}
          { (currentView === ContentView.SINGLE_DEALERSHIP_USERS || ContentView.USERS)  &&
            <StyledFormControl style={{ width: "135px" }}>
              <Select
                id="role-select"
                data-test-id="choose-role"
                value={role}
                displayEmpty
                onChange={this.handleSetRole}
                MenuProps={{
                  getContentAnchorEl: null,
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                IconComponent={ExpandMoreRounded}
                className="select-component"
              >
                <MenuItem disabled value="" style={{ display: "none" }}>
                  Choose Role
                </MenuItem>
                <MenuItem value="all">All</MenuItem>
                {roles?.map((role) => (
                  <MenuItem key={role.id} value={role.attributes.name}>
                    {role.attributes.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          }

          <StyledFormControl>
            <Select
              id="sort-select"
              value={sort}
              onChange={this.handleSetSort}
              displayEmpty
              MenuProps={{
                getContentAnchorEl: null,
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
              IconComponent={ExpandMoreRounded}
            >
              <MenuItem disabled value="" style={{ display: "none" }}>
                Sort by
              </MenuItem>
              <MenuItem value="A-Z">A to Z</MenuItem>
              <MenuItem value="Z-A">Z to A</MenuItem>
              {currentView === ContentView.DEALERSHIPS && (
                <MenuItem value="most_users">Most Users</MenuItem>
              )}
              {currentView === ContentView.DEALERSHIPS && (
                <MenuItem value="recent_created">Recently Created</MenuItem>
              )}
              {currentView !== ContentView.DEALERSHIPS && (
                <MenuItem value="recent_joined">Recently Joined</MenuItem>
              )}
            </Select>
          </StyledFormControl>

          <Box className="navigation">
            <Typography className="pagesText">{`${page} of ${Math.ceil(
              totalItems / itemsPerPage || 1
            )}`}</Typography>
            <button
              data-test-id="prev-page-button"
              className="paginationButton"
              onClick={this.prevPage}
            >
              <KeyboardArrowLeft
                width={5}
                height={8}
                style={{ color: "#4B4C4B" }}
              />
            </button>
            <button
              data-test-id="next-page-button"
              className="paginationButton"
              onClick={this.nextPage}
            >
              <KeyboardArrowRight
                width={5}
                height={8}
                style={{ color: "#4B4C4B" }}
              />
            </button>
          </Box>
        </Box>
      </Container>
    );
  }
}

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "space-between",
  height: "55px",
  padding: "0 40px 0 25px",
  borderBottom: "1px solid #F0FAFA",
  [theme.breakpoints.down("sm")]: {
    gap: "10px",
  },

  "& .leftSideContainer": {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: "15px",
  },

  "& .checkedItemsInfo": {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: "24px",

    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },

  "& .itemsQuantity": {
    textTransform: "capitalize",
    minWidth: "60px",
  },

  "& .selectedItemsControls": {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    gap: "24px",

    "& button": {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },

    "& img": {
      width: "18px",
    },

    [theme.breakpoints.down("sm")]: {
      gap: "5px",
    },
  },

  "& .rightSideContainer": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "5px"
  },

  "& .navigation": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    paddingLeft: "24px",
    gap: "6px",
    borderLeft: "1px solid #F0FAFA",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
    },
  },

  "& .pagesText": {
    minWidth: "33px",
    color: "#4B4C4B",
  },

  "& .paginationButton": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "16px",
    height: "16px",
    border: "none",
    backgroundColor: "inherit",
    cursor: "pointer",
  },

  "& *": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
}));

const CheckboxContainer = styled(Box)({
  position: "relative",
  width: "20px",
  height: "20px",
  border: "1px solid #C5CBC9",
  margin: 0,
  borderRadius: "6px",
  cursor: "pointer",
  overflow: "hidden",

  "& .checkbox": {
    appearance: "none",
  },

  "& .checkmark": {
    position: "absolute",
    top: "0",
    left: "0",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "#4FC8EC",
  },

  "& input:checked + span": {
    display: "flex",
  },
});

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#4B4C4B",
    boxShadow: theme.shadows[1],
    fontSize: "12px",
    padding: "16px",
  },
}))(Tooltip);

const Search = styled(TextField)({
  marginRight: "25px",

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
});

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#4FC8EC",
    width: "24px",
    height: "24px",
    borderRadius: "6px",
  },

  "&:hover": {
    backgroundColor: "transparent",
  },
});

const CheckedIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "6px",
  backgroundColor: "#4FC8EC",
});

const CheckboxIcon = styled("div")({
  boxSizing: "border-box",
  width: "24px",
  height: "24px",
  border: "1px solid #C5CBC9",
  borderRadius: "6px",
});

const DealershipsFormControl = styled(FormControl)(({ theme }) => ({
  width: "170px",

  "& .MuiSelect-root": {
    color: "#4B4C4B",
  },

  "& .MuiPaper-root": {
    width: "220px",
  },

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },

  "& .MuiSelect-select": {
    paddingLeft: "5px",
  },

  "& ul > li": {
    backgroundColor: "#F0FAFA",
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "fit-content",
  marginRight: "15px",

  "& .MuiSelect-root": {
    color: "#4B4C4B",
  },

  "& .MuiSelect-select": {
    paddingLeft: "5px",
  },

  "& .MuiList-padding": {
    paddingTop: "0",
    paddingBottom: "0",
  },

  "& .Muiselect-icon": {
    [theme.breakpoints.down("sm")]: {
      top: "calc(50% - 6px)",
    },
  },

  "& .MuiInputLabel-shrink": {
    display: "none",
  },

  "& .MuiInputLabel-formControl": {
    transform: "translate(0, 9px) scale(1)",
  },

  "& label + .MuiInput-formControl": {
    marginTop: 0,
  },

  "& .MuiFormLabel-root.Mui-focused": {
    color: "unset",
  },

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
}));

// Customizable Area End
