// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock"; // Assuming this is where IBlock is located

export const configJSON = require("./config");

export enum DashboardView {
  HOME = "HOME",
  ALL_DEALS = "ALL_DEALS",
  MY_TEAM = "MY_TEAM"
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  apiToken: string | null;
  anchorEl: HTMLElement | null;
  openModal: boolean;
  open: boolean;
  CreateDealStatus: any;
  loading: boolean;
  errorMsg: string;
  userData: { [key: string]: any } | undefined;
  currentView: DashboardView;
  savedDeals: any;
  dealerships: any;
}

interface SS {
  id: any;
}

export default class CreateDealMainController
  extends BlockComponent<Props, S, SS>
  implements IBlock {
  apiUserDataCallId = "";
  apiGetSavedDealsId = "";
  apiGetDealershipsId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      currentView: DashboardView.ALL_DEALS,
      apiToken: null,
      anchorEl: null,
      openModal: false,
      open: false,
      CreateDealStatus: "",
      loading: false,
      errorMsg: "",
      userData: undefined,
      savedDeals: [],
      dealerships: []
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getUserData();
    this.getDealershipsData();
    this.getSavedDealsData();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!webResponseJson.errors && webResponseJson) {
        switch (webApiRequestCallId) {
          case this.apiUserDataCallId:
            const userData = webResponseJson.data;
            const { attributes } = userData;
            this.setState({
              errorMsg: "",
              loading: false,
              userData: {
                ...userData,
                attributes: {
                  soloDealershipId: attributes.solo_dealership_id,
                  dealData: attributes.deal_data,
                  isDealPending: attributes.is_deal_pending,
                  groupCreatedOn: attributes.group_created_on,
                  profilePicture: attributes.profile_picture,
                  role: attributes.role,
                  dealerGroupName: attributes.dealer_group_name,
                  isDealerGroup: attributes.is_dealer_group,
                  updatedAt: attributes.updated_at,
                  createdAt: attributes.created_at,
                  fullName: attributes.full_name,
                  type: attributes.type,
                  phoneNumber: attributes.phone_number,
                  fullPhoneNumber: attributes.full_phone_number,
                  email: attributes.email,
                  countryCode: attributes.country_code,
                  activated: attributes.activated
                }
              }
            });
            break;
          case this.apiGetSavedDealsId:
            this.setState({ savedDeals: webResponseJson.data });
            break;
          case this.apiGetDealershipsId:
            this.setState({ dealerships: webResponseJson.dealerships.data });
            break;
          default:
            break;
        }
      } else if (webResponseJson.errors && webResponseJson) {
        if (this.apiUserDataCallId === webApiRequestCallId) {
          this.setState({
            loading: false,
            errorMsg: webErrorReponse
          });
        }
      }
    }
  }

  navigateToCreateDeal = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CreateDealForm");
    this.send(msg);
  };

  handleClick = (event: any) => {
    const { dealerships, savedDeals } = this.state;
    if (dealerships?.length === 1 && savedDeals?.length === 0) {
      localStorage.setItem("dInfoId", dealerships[0].id);
      this.navigateToCreateDeal();
      return;
    }
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  navigateToMyTeam = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyTeam");
    this.send(msg);
  };

  navigateToDashboard = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    this.send(msg);
  };

  setCurrentView = (view: DashboardView) => {
    this.setState({
      currentView: view
    });
  };

  getStyle = (
    condition: boolean,
    whenTrue: string | number,
    whenFalse: string | number
  ) => {
    return condition ? whenTrue : whenFalse;
  };

  getUserData(): boolean {
    this.setState({ loading: true });

    const webHeader = {
      "Content-Type": configJSON.createDealContentType,
      token: localStorage.getItem("authToken")
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUserDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDealInformationMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getDealershipsData() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDealershipsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMultipleDealershipApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMultipleDealershipApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSavedDealsData() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSavedDealsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSavedDealApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSavedDealApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
// Customizable Area End
