import React from "react";
// Customizable Area Start
import { Box,
  Button,
  Typography,
  styled,
  createTheme,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Tabs,
  Tab,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Select,
  FormControl,
  MenuItem
 } from "@material-ui/core";
 import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import SearchIcon from '@material-ui/icons/Search';
 import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
 import Chart from 'react-apexcharts';
 import { ApexOptions } from 'apexcharts';
 import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
 import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
 import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
 import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

 interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BlockController, {
  Props,
} from "./HomeDashboardController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  spTabelOneData = [
    { id: 1, dealership: "RoadPulse", states: "CA > NY", service: "Runner", payout: "$2,010.13", dateReceived: "5/10/2024", actions: { accept: "Accept", reject: "Reject" }},
    { id: 2, dealership: "CarOasis", states: "AZ > CA", service: "Full Service", payout: "$1,000.00", dateReceived: "4/28/2024", actions: { accept: "Accept", reject: "Reject" }},
    { id: 3, dealership: "RideNirvana", states: "GA > CA", service: "Onsite", payout: "$3,020.70", dateReceived: "4/22/2024", actions: { accept: "Accept", reject: "Reject" }},
    { id: 4, dealership: "CarSynergy", states: "OH > NY", service: "White Glove", payout: "$5,200.00", dateReceived: "4/20/2024", actions: { accept: "Accept", reject: "Reject" }},
    { id: 5, dealership: "AutoBlaze", states: "FL > AZ", service: "Full Service", payout: "$1,800.30", dateReceived: "4/18/2024", actions: { accept: "Accept", reject: "Reject" }}
  ];
  Reviews = [
    {
      id: 1,
      name: 'Kiss Dorka',
      company: 'DrivenAuto',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
      date: 'Mar 25, 2024',
      text: "Impressive results! Their Dealership went above and beyond to ensure my satisfaction. I couldn't be happier with the outcome.",
      thumbsUp: 860,
      thumbsDown: 140,
      positivePercent: '86%',
    },
    {
      id: 2,
      name: 'Kende Lili',
      company: 'RoadPulse',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      date: 'Mar 25, 2024',
      text: 'Outstanding service! The Dealership delivered above and beyond my expectations. Highly recommended!',
      thumbsUp: 860,
      thumbsDown: 140,
      positivePercent: '86%',
    },
    {
      id: 3,
      name: 'Sípos Veronika',
      company: 'CarOasis',
      avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
      date: 'Mar 25, 2024',
      text: 'Outstanding service! The Dealership delivered above and beyond my expectations. Highly recommended!',
      thumbsUp: 860,
      thumbsDown: 140,
      positivePercent: '86%',
    },
  ];
  ChartData = [
    { label: 'In Progress', count: 462, percentage: '50%', color: '#8fc9f7' },
    { label: 'Issues Sent to Dealer', count: 126, percentage: '15%', color: '#ffd700' },
    { label: 'Resolved, In Dealer Review', count: 168, percentage: '20%', color: '#d3d3d3' },
    { label: 'Dealer Flags Incomplete', count: 42, percentage: '5%', color: '#f08080' },
    { label: 'Completed', count: 42, percentage: '5%', color: '#8fc9f7' },
  ];

Documents = [
    { id: '#203193', customer: 'Smith', title: 'MV-1 Motor Vehicle Title Application.pdf', date: '2 days ago', provider: 'Blue Tree Service' },
    { id: '#203193', customer: 'Hoover', title: 'MV-1 Motor Vehicle Title Application.pdf', date: '2 days ago', provider: 'WheelsUp' },
    { id: '#943021', customer: 'Arthur', title: 'Fee Quote.pdf', date: '5 days ago', provider: 'Smart Lock Solutions' },
    { id: '#943021', customer: 'Arthur', title: 'Forms Checklist.pdf', date: '5 days ago', provider: 'Smart Lock Solutions' },
  ];
 ThirdTableData = [
    { id: '#203193', customerLastName: 'Smith', provider: 'WheelsUp', service: 'Runner', date: '5/12/2024' },
    { id: '#943021', customerLastName: 'Arthur', provider: 'TurboWheelz', service: 'Full Service', date: '5/01/2024' },
    { id: '#694031', customerLastName: 'Hoover', provider: 'SpeedZone', service: 'Onsite', date: '4/28/2024' },
    { id: '#304953', customerLastName: 'Cooper', provider: 'SpeedStreet', service: 'White Glove', date: '4/20/2024' },
    { id: '#001231', customerLastName: 'Davis', provider: 'RoadPulse', service: 'Full Service', date: '4/20/2024' },
    { id: '#203193', customerLastName: 'Smith', provider: 'WheelsUp', service: 'Runner', date: '5/12/2024' },
    { id: '#943021', customerLastName: 'Arthur', provider: 'TurboWheelz', service: 'Full Service', date: '5/01/2024' },
    { id: '#694031', customerLastName: 'Hoover', provider: 'SpeedZone', service: 'Onsite', date: '4/28/2024' },
    { id: '#304953', customerLastName: 'Cooper', provider: 'SpeedStreet', service: 'White Glove', date: '4/20/2024' },
    { id: '#001231', customerLastName: 'Davis', provider: 'RoadPulse', service: 'Full Service', date: '4/20/2024' },
    { id: '#203193', customerLastName: 'Smith', provider: 'WheelsUp', service: 'Runner', date: '5/12/2024' },
    { id: '#943021', customerLastName: 'Arthur', provider: 'TurboWheelz', service: 'Full Service', date: '5/01/2024' },
    { id: '#694031', customerLastName: 'Hoover', provider: 'SpeedZone', service: 'Onsite', date: '4/28/2024' },
    { id: '#304953', customerLastName: 'Cooper', provider: 'SpeedStreet', service: 'White Glove', date: '4/20/2024' },
    { id: '#001231', customerLastName: 'Davis', provider: 'RoadPulse', service: 'Full Service', date: '4/20/2024' },
  ];
  ThirdTableSPData = [
    { id: '#203193', customerLastName: 'Smith', provider: 'WheelsUp', service: 'Runner', date: '5/12/2024' },
    { id: '#943021', customerLastName: 'Arthur', provider: 'TurboWheelz', service: 'Full Service', date: '5/01/2024' },
    { id: '#694031', customerLastName: 'Hoover', provider: 'SpeedZone', service: 'Onsite', date: '4/28/2024' },
    { id: '#304953', customerLastName: 'Cooper', provider: 'SpeedStreet', service: 'White Glove', date: '4/20/2024' },
    { id: '#001231', customerLastName: 'Davis', provider: 'RoadPulse', service: 'Full Service', date: '4/20/2024' }
  ];

  tableData = [
    { id: "#203193", customerLastName: "Smith", dealership: "Luther Honda", states: "CA > NY", dateCreated: "5/10/2024", dateAccepted: "5/12/2024", service: "Runner" },
    { id: "#392051", customerLastName: "Arthur", dealership: "Luther Hyundai", states: "AZ > CA", dateCreated: "4/28/2024", dateAccepted: "-", service: "-" },
    { id: "#234011", customerLastName: "Hoover", dealership: "Luther Honda", states: "GA > CA", dateCreated: "4/22/2024", dateAccepted: "-", service: "-" },
    { id: "#875023", customerLastName: "Cooper", dealership: "Luther Hyundai", states: "OH > NY", dateCreated: "4/20/2024", dateAccepted: "4/20/2024", service: "White Glove" },
    { id: "#001231", customerLastName: "Davis", dealership: "Luther Honda", states: "FL > AZ", dateCreated: "4/18/2024", dateAccepted: "4/20/2024", service: "Full Service" }
  ];
  

 TabPanel(props : TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TableDealerOne = ()=>{
    return (
        <TableContainer component={Box} style={{ width:'100%',  overflowX: 'auto',}}>
        <Box style={{overflowX: 'auto', width: '100%',  }}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}> Deal ID</TabCellHeader>
              <TabCellHeader> Customer's Last Name </TabCellHeader>
              <TabCellHeader>Dealership </TabCellHeader>
              <TabCellHeader> States</TabCellHeader>
              <TabCellHeader>Date Created </TabCellHeader>
              <TabCellHeader> Date Accepted</TabCellHeader>
              <TabCellHeader style={{borderRight:'none'}} > Service</TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>

            {this.tableData.map((deal, index) => (
              <StyledTableRow className="tablehover" key={index}>
                <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                <TabCells> { deal.customerLastName } </TabCells>
                <TabCells> { deal.dealership} </TabCells>
                <TabCells> { deal.states } </TabCells>

                <TabCells> {deal.dateCreated }</TabCells>
                <TabCells> { deal.dateAccepted }</TabCells>
                <TabCells  style={{borderRight:'none'}}>{deal.service} </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
  }

  TableServiceProviderOne = ()=>{
    return (
        <TableContainer component={Box} style={{ overflowX: 'auto', width:'100%', }}>
        <Box style={{overflowX: 'auto', width: '100%'}}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none', paddingLeft:'20px'}}> Dealership</TabCellHeader>
              <TabCellHeader> States </TabCellHeader>
              <TabCellHeader>Service </TabCellHeader>
              <TabCellHeader>Payout </TabCellHeader>
              <TabCellHeader> Date Received</TabCellHeader>
              <TabCellHeader style={{borderRight:'none'}}> Actions</TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>

          {this.spTabelOneData.map((deal, index) => (
            <StyledTableRow className="tablehover" key={index} >
              <TabCells style={{borderLeft:'none', paddingLeft:'20px'}}> {deal.dealership} </TabCells>
              <TabCells> {deal.states} </TabCells>
              <TabCells> {deal.service}  </TabCells>
              <TabCells> {deal.payout}</TabCells>
              <TabCells> {deal.dateReceived} </TabCells>
              <TabCells style={{borderRight:'none'}}> 
                <Button><Typography style={{fontSize:'14px', color:'#26BBB3', fontWeight:700, fontFamily:'Roboto'}}>{deal.actions.accept}</Typography></Button>
                <Button><Typography  style={{fontSize:'14px',  fontWeight:700, fontFamily:'Roboto',color:'#C43937',}}>{deal.actions.reject}</Typography></Button>
              </TabCells>
            </StyledTableRow>
          ))}

            </TableBody>
        </Table>
        </Box>
    </TableContainer>
    )
  }

  TableDealerTwo = ()=>{
    return (
      <>
      <Box style={{display:'flex', justifyContent:'space-between'}}>
      <Box>
          <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Drafts</Typography>
          <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Pick back up where you left off on creating these deals</Typography>
      </Box>
      
      <Button variant="outlined" style={{border:'1px solid #013D4F', height:'44px', marginTop:'10px', borderRadius:'8px'}}>
          <Typography style={{color:'#013D4F', fontFamily:'Roboto', textTransform:'none', fontWeight:700, fontSize:'16px'}}>View All</Typography>
          <ArrowForwardIosIcon style={{color:'#013D4F',height:'14px' }}/>
      </Button>
    </Box>
        <TableContainer component={Box} style={{width:'100%',overflowX: 'auto',}} >
        <Box style={{width: '100%',overflowX: 'auto'}}>
        <Table stickyHeader aria-label="sticky table" style={{overflowX: 'auto',}}>
          <TableHead>
            <TableRow>
                <TabCellHeader style={{borderLeft:'none',}}> Deal ID </TabCellHeader>
                <TabCellHeader > Customer's Last Name </TabCellHeader>
                <TabCellHeader > Dealership</TabCellHeader >
                <TabCellHeader>States </TabCellHeader >
                <TabCellHeader> Date Created</TabCellHeader>
                <TabCellHeader> Date Accepted </TabCellHeader>
                <TabCellHeader style={{borderRight:'none'}}>Actions</TabCellHeader>
            </TableRow>
            </TableHead>
            <TableBody >
              {this.tableData.map((deal,index) =>(
              <StyledTableRow key={index} className="tablehover" >
                  <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                  <TabCells>{ deal.customerLastName}</TabCells >
                  <TabCells >{ deal.dealership}</TabCells>
                  <TabCells> {deal.states}</TabCells>
                  <TabCells >{deal.dateCreated}</TabCells >
                  <TabCells>{ deal.dateAccepted }</TabCells>
                  <TabCells style={{borderRight:'none'}}>
                      <Box style={{display:'flex', alignItems:'center'}}>
                          <Typography style={{fontFamily:'Roboto', color:'#4FC8EC', fontSize:'16px', fontWeight:700}}>Continue </Typography>
                          <span><ArrowForwardIosIcon style={{height:'14px', color:'#4FC8EC'}}/></span>
                      </Box>
                      
                  </TabCells>
              </StyledTableRow>
              ))
              }
            </TableBody>
        </Table>
        </Box>
    </TableContainer>
    </>
    )
  }

  TableServiceProviderTwo = ()=>{
    return (
      <>
      <Box style={{display:'flex', justifyContent:'space-between',marginBottom:'20px'}}>
      <Box>
          <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Recent Deals</Typography>
          <Typography style={{color:'#4B4C4B', fontWeight:400,fontFamily:'Roboto',  fontSize:'16px'}}>Deals you've recently worked on</Typography>
      </Box>
      
      <Button variant="outlined" style={{border:'1px solid #013D4F', height:'44px', marginTop:'10px', borderRadius:'8px'}}>
          <Typography style={{color:'#013D4F', fontFamily:'Roboto', fontWeight:700, fontSize:'16px', textTransform:'none'}}>View All Deals</Typography>
          <ArrowForwardIosIcon style={{color:'#013D4F', height:'14px',}}/>
      </Button>
    </Box>
        <TableContainer component={Box} style={{overflowX:'auto', width:'100%'}} >
        <Box style={{ width: '100%', overflowX: 'auto'}}>
          <Table stickyHeader aria-label="sticky table" style={{overflowX:'auto'}}>
              <TableHead>
                <TableRow>
                    <TabCellHeader style={{borderLeft:'none'} }> Deal ID</TabCellHeader>
                    <TabCellHeader>Customer's Last Name</TabCellHeader>
                    <TabCellHeader>Dealership</TabCellHeader>
                    <TabCellHeader>States </TabCellHeader>
                    <TabCellHeader>Date Received</TabCellHeader>
                    <TabCellHeader>Date Accepted </TabCellHeader>
                    <TabCellHeader style={{borderRight:'none'} }>Service</TabCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.tableData.map((deal, index) => (
                    <StyledTableRow key={index} className="tablehover">
                    <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                    <TabCells>{ deal.customerLastName}</TabCells>
                    <TabCells> {deal.dealership}</TabCells>
                    <TabCells>{deal.states}</TabCells>
                    <TabCells> {deal.dateCreated}</TabCells>
                    <TabCells>{deal.dateAccepted}</TabCells>
                    <TabCells style={{borderRight:'none'}}> {deal.service}</TabCells>
                    </StyledTableRow>
                ))}
              </TableBody>
            </Table>
        </Box>
    </TableContainer>
    </>
    )
  }

  TableDealerThree=()=>{
    return(
        <>
        <Box style={{display:'flex', justifyContent:'space-between'}}>
            <Box style={{width:'100%'}}>
                <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Review Service Providers </Typography>
                <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', }}>
                    <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Let us know how it was working with these Service Providers</Typography>
                    <TablePagination
                        component="div"
                        data-test-id="pagination"
                        count={this.ThirdTableData.length}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => `${from} of ${count}`}
                        style={{color:'#4B4C4B', fontFamily:'Roboto', fontSize:'14px', fontWeight:500}}
                    />
                </Box>
            </Box>
        </Box>
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
                <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                <TabCellHeader>Customer's Last Name</TabCellHeader>
                <TabCellHeader>Service Provider</TabCellHeader>
                <TabCellHeader>Service</TabCellHeader>
                <TabCellHeader>Date Completed</TabCellHeader>
                <TabCellHeader style={{borderRight:'none'}}>Actions</TabCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.ThirdTableData
              .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => (
                <StyledTableRow key={row.id} >
                  <TabCells>{row.id} </TabCells>
                  <TabCells>{ row.customerLastName}</TabCells>
                  <TabCells >{row.provider }</TabCells>
                  <TabCells>{row.service}</TabCells>
                  <TabCells >{row.date}</TabCells>
                    <TabCells>
                      <Box style={{display:'flex', alignItems:'center'}}>
                          <Typography style={{fontFamily:'Roboto',  fontSize:'16px',color:'#4FC8EC', fontWeight:700}}>Review Now </Typography>
                          <span><ArrowForwardIosIcon style={{color:'#4FC8EC',height:'14px',}}/></span>
                      </Box>
                    </TabCells>
                  </StyledTableRow>
              ))
              }
          </TableBody>
          </Table>
        </TableContainer>
        </>
    )
  }

  TableServiceProviderThree=()=>{
    return(
        <>
        <Box style={{display:'flex',marginBottom:'20px', justifyContent:'space-between'}}>
            <Box style={{width:'100%'}}>
                <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Review Dealers</Typography>
                <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Let us know how it was working with these Dealers</Typography>
            </Box>
        </Box>
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
                <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                <TabCellHeader>Customer's Last Name</TabCellHeader>
                <TabCellHeader>Dealer</TabCellHeader>
                <TabCellHeader>Service</TabCellHeader>
                <TabCellHeader>Date Completed</TabCellHeader>
                <TabCellHeader style={{borderRight:'none'}}> Actions</TabCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.ThirdTableSPData
              .map((row) => (
                <StyledTableRow key={row.id}>
                  <TabCells>{row.id}</TabCells>
                  <TabCells>{row.customerLastName}</TabCells>
                  <TabCells>{row.provider}</TabCells>
                  <TabCells>{row.service}</TabCells>
                  <TabCells>{row.date}</TabCells>
                  <TabCells>
                    <Box style={{display:'flex', alignItems:'center'}}>
                        <Typography style={{fontFamily:'Roboto', color:'#4FC8EC', fontSize:'16px', fontWeight:700}}>Review Now </Typography>
                        <span><ArrowForwardIosIcon style={{height:'14px', color:'#4FC8EC'}}/></span>
                    </Box>
                  </TabCells>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    )
  }

ChartListOne =()=>{
  return(
    <List style={{width:'100%'}}>
    {this.ChartData.map((item, index) => (
      <ListItem
        key={index}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          marginBottom: '8px',
          borderRadius: '8px',
        }}
      >
      <Box style={{display:'flex', flexDirection:'column', width:'100%'}}>
        <Box>
          <Typography style={{color:'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontWeight:'500', fontSize:'14px'}}>{item.label}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
          <Typography  style={{color:'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontWeight:'500', fontSize:'24px'}}>{item.count}</Typography>
          <Box style={{backgroundColor:'rgba(230, 249, 255, 1)', borderRadius:'26px', width:'50px', height:'30px'}}>
            <Typography style={{ color: item.color, textAlign:'center', fontFamily:'Roboto', fontWeight:'500', fontSize:'14px', paddingTop:'5px'}}>
              {item.percentage}
            </Typography>
          </Box>
        </Box>
      </Box>
      </ListItem>
    ))}
</List>
  )
}

 chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['Deal 1', 'Deal 2', 'Deal 3', 'Deal 4', 'Deal 5'],
    colors: ['#004c6d', '#fcb900', '#525252', '#00bcd4', '#f06725'], 
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Deals',
              fontSize: '14px',
              color: 'rgba(197, 203, 201, 1)', 
              fontFamily:'Roboto',
              fontWeight:500,
              formatter: () => '840',
            },
          },
        },
      },
    },
    stroke: {
      show: false,  // Remove the stroke between sections
    },
    dataLabels: {
      enabled: false  
    },
    legend: {
      show: false,  
    },
    tooltip: {
      enabled: false,  
    },
  }

  ReviewsOne=()=>{
    return(
      <>
      <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <Box style={{display:'flex', alignItems:'center'}}>
          <Box style={{backgroundColor:'rgba(240, 250, 250, 1)', height:'25px', display:'flex', alignItems:'center'}}>
            <ThumbUpAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)'}}/> <span style={{fontSize:'12px', color:'rgba(75, 76, 75, 1)'}}>885</span>
            <ThumbDownAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)', marginLeft:'10px'}}/><span style={{fontSize:'12px', color:'rgba(75, 76, 75, 1)'}}>85</span>
          </Box>
          <Typography style={{marginLeft:'10px', fontSize:'12px', fontWeight:500, fontFamily:'Roboto', color:'rgba(75, 76, 75, 1)',textDecoration:'underline', textDecorationStyle:'dashed'}}>1000+ Reviews</Typography>
        </Box>
        <Box style={{display:'flex', alignItems:'center'}}>
          <ThumbUpAltOutlinedIcon style={{height:'14px', color:'rgba(75, 76, 75, 1)'}}/>
          <Typography style={{fontSize:'12px', fontWeight:500, fontFamily:'Roboto', color:'rgba(75, 76, 75, 1)'}}>86% Positive</Typography>
        </Box>
      </Box>

      <List>
        {this.Reviews.map((review) => (
          <ListItem key={review.id} alignItems="flex-start" style={{ padding: '20px 0', borderBottom: '1px solid #f0f0f0' }}>
          <Box style={{display:'felx', flexDirection:'column'}}>
            <Box>
              <Box style={{display:'flex'}}>
                <ListItemAvatar>
                  <Avatar variant="square" alt={review.name} src={review.avatar} style={{ width: '50px', height: '50px', borderRadius:'8px' }} />
                </ListItemAvatar>
                <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box style={{display:'flex', alignItems:'center'}}>
                      <Typography style={{color:'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontSize:'16px', fontWeight:600}}>{review.name}</Typography>
                      <span style={{backgroundColor:'rgba(240, 250, 250, 1)', borderRadius:'5px'}}><ThumbUpAltOutlinedIcon style={{height:'15px', color:'rgba(75, 76, 75, 1)'}}/></span>
                    </Box>
                    
                    <Typography style={{color:'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'14px', fontWeight:500}}>
                      {review.date}
                    </Typography>
                  </Box>
                }
                secondary={
                  <>
                    <Typography style={{color:'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'16px', fontWeight:500}}>
                      {review.company}
                    </Typography>
                  </>
                }
              />
              </Box>
              
              
            </Box>
            
            <Box>
              <Typography style={{ marginTop: '8px', color:'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>
                  {review.text}
              </Typography>
            </Box>
          </Box>
          </ListItem>
        ))}
      </List>
      </>
    )
  }

  optionForDealer = [
    { name: 'Jackle', avatar: 'https://randomuser.me/api/portraits/women/1.jpg' }, 
    { name: 'Timithy', avatar: 'https://randomuser.me/api/portraits/women/3.jpg' },
    { name: 'Taylor ava', avatar: 'https://randomuser.me/api/portraits/women/4.jpg' },
    { name: 'Selena G', avatar: 'https://randomuser.me/api/portraits/women/5.jpg' },
  ];

  HeaderDealSelect = ()=>{
    return(
      <FormControl
      variant="outlined"
      style={{ width: '100%', marginTop: '20px', maxWidth: '200px', padding:'0'}}
    >
      <style>
        {`
        .MuiSelect-outlined.MuiSelect-outlined{
          padding:5px;
        }
        `}
      </style>
      <CustomSelect
        className="selecttest"
        data-test-id="selectoption"
        labelId="contactus-reason-label"
        id="contactus-reason"
        value={this.state.dealer}
        onChange={this.handleSelectUserDeal}
        IconComponent={ExpandMoreIcon}
        style={{
          borderRadius: '12px',
          paddingLeft: '8px',
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: '800px',
              borderRadius: '8px',
            },
          },
        }}
        renderValue={(selected) => {
          const selectedDealer = this.optionForDealer.find(
            (option) => option.name === selected
          );
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={selectedDealer ? selectedDealer.avatar: this.optionForDealer[0].avatar}
                style={{ marginRight: '8px',height:'35px', width:'35px'}}
              />
              <Typography style={{ color: 'rgba(1, 61, 79, 1)', fontSize: '14px', fontWeight: 500, fontFamily:'Roboto'}}>
                {selectedDealer ? selectedDealer.name : this.optionForDealer[0].name}
              </Typography>
             
            </div>
          );
        }}
      >
        {this.optionForDealer.map((option, index) => (
          <MenuItem key={index} value={option.name} style={{ height: '60px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={option.avatar}
                style={{ marginRight: '8px' }}
              />
              <span style={{ color: 'rgba(1, 61, 79, 1)', fontSize: '16px', fontWeight: 300 }}>
                {option.name}
              </span>
            </div>
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
    )
  }

  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        {this.HeaderDealSelect()}

        <Box sx={webStyles.headerRightSide}>
          <Button variant="outlined" style={{border:'1px solid rgba(1, 61, 79, 1)',boxShadow:'none', borderRadius:'8px'}}>
            <Box style={{display:'flex', alignItems:'center'}}>
              <ChatBubbleOutlineIcon style={{color:'rgba(1, 61, 79, 1)', height:'20px'}}/>
              <Typography style={{marginLeft:'5px', color:'rgba(1, 61, 79, 1)', fontFamily:'Roboto', fontWeight:700, fontSize:'16px', textTransform:'none'}}>Chats</Typography>
            </Box>
          </Button>
          <Button variant="contained" style={{backgroundColor:'rgba(79, 200, 236, 1)', borderRadius:'8px',boxShadow:'none', display: (this.state.role==='dealer_admin' || this.state.role==='dealer') ? 'block' : 'none'}}>
            <Typography style={{color:'white', fontFamily:'Roboto', fontWeight:700, fontSize:'16px', textTransform:'none'}}>
              Create deal
            </Typography>
          </Button>
          <Box
            data-test-id=""
            sx={webStyles.notificationsButton}
          >
            <NotificationsOutlinedIcon/>
          </Box>

          <Box style={webStyles.userAvatar}>
            <Avatar
              className="avatar"
              variant="square"
              src={ ''}
              style={{
                width: "102%",
                height: "102%",
                backgroundColor: 'rgb(240, 250, 250)', 
                borderRadius: "24px",   
                color: '#013D4F'
              }}
            >
               
            </Avatar>
          </Box>
        </Box>
      </Box>
    )
  }

  cards = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return(
      <Grid container spacing={2} style={{ marginTop: '20px', gap:'10px', display:'flex', justifyContent:'space-around'}}>
        <Grid item xs={5} sm={4} md={3} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)', padding:'15px', boxSizing: 'border-box', borderRadius:'8px'}}>
            <Box display={'flex'} alignItems="center">
                <StyledTypo>Ongoing Deals</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>786</StyledTypoDesc>
        </Grid>
        <Grid item xs={5} sm={3} md={3} lg={2} style={{  boxSizing: 'border-box',padding:'15px',border: '1px solid rgba(240, 250, 250, 1)', borderRadius:'8px' }}>
            <Box display={'flex'}  alignItems="center">
                <StyledTypo>In Progress Deals</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>462</StyledTypoDesc>
        </Grid>
        <Grid item xs={5} sm={3} md={3} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)', borderRadius:'8px', boxSizing: 'border-box',padding:'15px' }}>
            <Box display={'flex'} alignItems="center">
                <StyledTypo>No Provider Assigned</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>50</StyledTypoDesc>
        </Grid>
        <Grid item xs={5} sm={3} md={3} lg={2} style={{ padding:'15px', border: '1px solid rgba(240, 250, 250, 1)', boxSizing: 'border-box', borderRadius:'8px' }}>
            <Box display={'flex'}  alignItems="center">
                <StyledTypo>Needs Your Review</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>168</StyledTypoDesc>
        </Grid>
        <Grid item xs={5} sm={3} md={3} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)',boxSizing: 'border-box', borderRadius:'8px', padding:'15px' }}>
            <Box display={'flex'}   alignItems="center">
                <StyledTypo>Issues Sent to Dealer</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>126</StyledTypoDesc>
        </Grid>
      </Grid>
      )
    }
    else{
      return(
      <Grid container spacing={2} style={{ marginTop: '20px', justifyContent:'space-around',display:'flex', gap:'10px'}}>
        <Grid item xs={5} sm={5} md={5} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)', padding:'15px', boxSizing: 'border-box', borderRadius:'8px'}}>
            <Box style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <StyledTypo>ACCEPTED DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{height: '11px',color: '#C5CBC9'}} />
            </Box>
            <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <StyledTypoDesc>786</StyledTypoDesc>
              <Box style={{backgroundColor:'#E6F9FF', borderRadius:'16px', height:'26px', display:'flex', alignItems:'center', padding:'4px 7px'}}>
                <Typography style={{color:'#013D4F', fontFamily:'Roboto', fontWeight:500, fontSize:'12px'}}>3 New Deals</Typography>
              </Box>
            </Box>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={2} style={{  boxSizing: 'border-box',padding:'15px',border: '1px solid rgba(240, 250, 250, 1)', borderRadius:'8px' }}>
            <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <StyledTypo>IN PROGRESS DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px',color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>462</StyledTypoDesc>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={2} style={{ border: '1px solid rgba(240, 250, 250, 1)', borderRadius:'8px', boxSizing: 'border-box',padding:'15px' }}>
            <Box style={{display:'flex',  alignItems:'center', justifyContent:'space-between'}}>
                <StyledTypo>PENDING DEALS</StyledTypo>
                <ArrowForwardIosIcon style={{ height: '11px', color: '#C5CBC9'}} />
            </Box>
            <StyledTypoDesc>50</StyledTypoDesc>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={2} style={{ padding:'15px', border: '1px solid rgba(240, 250, 250, 1)', boxSizing: 'border-box', borderRadius:'8px' }}>
            <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <StyledTypo>DEALER FLAGS INCOMPLETE</StyledTypo>
                <ArrowForwardIosIcon style={{height: '11px', color: '#C5CBC9' }} />
            </Box>
            <StyledTypoDesc>168</StyledTypoDesc>
        </Grid>
      </Grid>
      )
    }
  }

  dealeraccordianOne = ()=>{
    return(
      <Accordion style={{boxShadow:'none'}}>
      <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      aria-controls="panel1a-content"
      id="panel1a-header"
      style={{boxShadow:'none'}}
      >
      <Box style={{display:'flex', flexDirection:'column', padding:'10px'}}>
          <Box style={{display:'flex', alignItems:'center'}}>
              <Typography style={{fontSize:'28px', color:'#013D4F', fontFamily:'Gotham', fontWeight:400}}>{this.getHeaderOfIssueAccordian()}</Typography>
              <InfoOutlinedIcon style={{fontSize:'28px', color:'#013D4F', marginLeft:'10px'}}/>
          </Box>
          
          <Typography style={{fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400}}>{this.getSubHeaderOfIssueAccordian()}</Typography>
      </Box>
      
      </AccordionSummary>
      <AccordionDetails style={{ maxHeight: '450px', overflowY: 'auto' }}>
          <Box style={{width:'100%'}}>
            {(this.state.role==='dealer_admin' || this.state.role==='dealer') && 
              <TextField
                  variant="outlined"
                  placeholder="Search for deal ID, customer name, provider, or last 6 of VIN..."
                  fullWidth
                  size="small"
                  style={{ marginBottom: '20px', width:'100%', backgroundColor:'rgba(241, 244, 243, 0.5)', borderRadius:'12px'}}
                  InputProps={{
                      startAdornment: <InputAdornment position="start" style={{paddingLeft:'10px'}}><SearchIcon style={{color:'rgba(152, 154, 152, 1)', height:'20px'}}/></InputAdornment>,
                      style: { borderRadius: '8px', paddingLeft:'5px', height:'52px'},
                  }}
              />
              }
              <List>
                  {this.Documents.map((doc, index) => (
                  <div key={index}>
                      <ListItem alignItems="flex-start">
                      <ListItemText
                          primary={
                          <Box>
                              <Typography  style={{ fontWeight: 500, color: 'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'14px'}}>
                              {doc.id} - {doc.customer}
                              </Typography>
                              <Typography style={{ fontWeight: 500, color: 'rgba(75, 76, 75, 1)', fontFamily:'Roboto', fontSize:'18px', marginTop:'5px'}}>
                              {doc.title}
                              </Typography>
                          </Box>
                          }
                          secondary={
                          <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'10px'}}>
                              <Typography style={{ fontWeight: 500, color: 'rgba(197, 203, 201, 1)', fontFamily:'Roboto', fontSize:'14px'}}>
                              {doc.date}
                              </Typography>
                              <Typography style={{ fontWeight: 500, color: 'rgba(197, 203, 201, 1)', fontSize:'14px',fontFamily:'Roboto', }}>
                              {doc.provider}
                              </Typography>
                          </Box>
                          }
                      />
                      </ListItem>
                      {index < this.Documents.length - 1 && <Divider component="li" />}
                  </div>
                  ))}
              </List>
          </Box>
      </AccordionDetails>
  </Accordion>
    )
  }

  dealeraccordianTwo = ()=>{
    return( <Accordion style={{boxShadow:'none'}}>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon/>}
    aria-controls="panel1a-content"
    id="panel1a-header"
    style={{boxShadow:'none'}}
    >
    <Box style={{display:'flex', padding:'10px', flexDirection:'column'}}>
        <Typography style={{fontSize:'28px', color:'#013D4F', fontFamily:'Gotham', fontWeight:400}}>Deals by Status</Typography>
        <Typography style={{fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400}}>Number of deals in each status</Typography>
    </Box>
    
    </AccordionSummary>
    <AccordionDetails style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Box style={{width:'100%'}}>
        <style>
          {`
           .apexcharts-datalabel-value{
            font-size:36px;
            font-weight:500;
            font-family:Roboto;
            margin-top:15px;
            color:rgba(75, 76, 75, 1);
          }
          `}
        </style>
          <Chart data-test-id="ApexCharts" options={this.chartOptions as ApexOptions} series={[40, 30, 15, 10, 5]} type="donut" />
          {(this.state.role==='dealer_admin' || this.state.role==='dealer') ? 
          <>
          <AppBar position="static" color="default" style={{ backgroundColor:'white', boxShadow:'none', marginTop:'20px', maxWidth:'390px'}}>
            <Tabs
                value={this.state.chartTabValue}
                data-test-id="tabs1"
                onChange={this.handleChangeChartTab}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
                classes={{
                  indicator: 'customIndicator', 
                }}
                >
                <Tab label="Service Provider Hire" {...this.a11yProps(0)} classes={{ root: 'customTab' }}/>
                <Tab label="No Service Provider" {...this.a11yProps(1)} classes={{ root: 'customTab' }}/>
            </Tabs>
        </AppBar>
        <style>
        {`
          .customTab {
            color: rgba(1, 61, 79, 1); 
            font-size: 16px;
            font-weight:500;
            font-family:Roboto;
            text-transform:none;
            min-width: auto;
          }

          .Mui-selected.customTab {
            color: rgba(1, 61, 79, 1); 
          }

          .customIndicator {
            background-color:rgba(1, 61, 79, 1);
          }
        `}
      </style>
          <this.TabPanel value={this.state.chartTabValue} index={0}>
           {this.ChartListOne()}
          </this.TabPanel>
          <this.TabPanel data-test-id="charttab" value={this.state.chartTabValue} index={1}>
            {this.ChartListOne()}
          </this.TabPanel>
          </>
        : this.ChartListOne()}
        </Box>
    </AccordionDetails>
</Accordion>)
  }

  tabelOneSection = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return(
        <>
         <Box style={{display:'flex', justifyContent:'space-between'}}>
            <Box>
              <Typography style={{color:'#013D4F', fontFamily:'Gotham', fontWeight:400, fontSize:'28px'}}>Recent Deals</Typography>
              <Typography style={{color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400, fontSize:'16px'}}>Deals you have recently worked on</Typography>
            </Box>
                        
            <Button variant="outlined" style={{border:'1px solid #013D4F',borderRadius:'8px', height:'44px', marginTop:'10px'}}>
              <Typography style={{color:'#013D4F', fontFamily:'Roboto', textTransform:'none', fontWeight:700, fontSize:'16px'}}>View All Deals</Typography>
              <ArrowForwardIosIcon style={{color:'#013D4F', height:'14px'}}/>
            </Button>
          </Box>

                    <AppBar position="static" color="default" style={{width:'350px', backgroundColor:'white', boxShadow:'none', marginTop:'20px'}}>
                        <Tabs
                            value={this.state.tabvalue}
                            data-test-id="tabs"
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            classes={{
                              indicator: 'customIndicator', 
                            }}
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            >
                            <Tab label="On Going" {...this.a11yProps(0)} classes={{ root: 'customTab' }}/>
                            <Tab label="Pending Hire" {...this.a11yProps(1)} classes={{ root: 'customTab' }}/>
                        </Tabs>
                    </AppBar>
                    <this.TabPanel value={this.state.tabvalue} index={0}>
                        {this.TableDealerOne()}
                    </this.TabPanel>
                    <this.TabPanel data-test-id="trackingDetailsTab" value={this.state.tabvalue} index={1}>
                        {this.TableDealerOne()}
                    </this.TabPanel>
          </>
      )
    }
    else{
      return(
        <>
        <Box style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>
           <Box>
             <Typography style={{color:'#013D4F',fontWeight:400,  fontFamily:'Gotham', fontSize:'28px'}}>Pending Deals</Typography>
             <Typography style={{color:'#4B4C4B', fontFamily:'Roboto',  fontSize:'16px',fontWeight:400, maxWidth:'800px'}}>You have the following incoming deal requests. You may accept or reject this deal. Please bear in mind that other providers may be able to accept this deal.</Typography>
           </Box>
                       
           <Button variant="outlined" style={{border:'1px solid #013D4F',borderRadius:'8px', height:'44px', marginTop:'10px'}}>
             <Typography style={{color:'#013D4F', fontFamily:'Roboto', textTransform:'none', fontWeight:700, fontSize:'16px'}}>View All Pending</Typography>
             <ArrowForwardIosIcon style={{color:'#013D4F', height:'14px'}}/>
           </Button>
         </Box>
          {this.TableServiceProviderOne()}
         </>
      )
    }
  }

  tabelTwoSection = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return this.TableDealerTwo()
    }
    else{
      return this.TableServiceProviderTwo()
    }
  }

  tabelThreeSection = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return this.TableDealerThree()
    }
    else{
      return this.TableServiceProviderThree()
    }
  }

  selectAcordianOne = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='delaer'){
      return this.dealeraccordianOne()
    }
    else{
      return this.dealeraccordianTwo()
    }
  }

  selectAcordianTwo = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='delaer'){
      return this.dealeraccordianTwo()
    }
    else{
      return this.dealeraccordianOne()
    }
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
      <Box height={'100vh'}>
        <Box>{this.headerr()}</Box>
        <Box>
            <Typography style={{fontFamily:'Gotham', color:'#4B4C4B', fontSize:'36px', fontWeight:400, paddingLeft:'20px'}}>
                Welcome Harry <span>&#128075;</span>
            </Typography>
        </Box>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={8} className="left side">
                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', padding:'20px', borderRadius:"24px"}}>
                    <Typography style={{ fontFamily: 'Gotham', color: '#013D4F', fontSize: '28px', fontWeight: 400 }}>
                      Deals Status
                    </Typography>
                    <Typography style={{ fontFamily: 'Roboto', color: '#4B4C4B', fontSize: '16px', fontWeight: 400 }}>
                      {this.getSubHeader()}
                    </Typography>

                  {this.cards()}
                </Grid>

                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', marginTop:'20px', padding:'20px', borderRadius:'24px'}}>
                   {this.tabelOneSection()}
                </Grid>

                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', marginTop:'20px', padding:'20px', borderRadius:'24px'}}>
                    {this.tabelTwoSection()}
                </Grid>

                <Grid item style={{ border: '1px solid rgba(240, 250, 250, 1)', marginTop:'20px', borderRadius:'24px', padding:'20px'}}>
                    {this.tabelThreeSection()}
                </Grid>
            </Grid>



            <Grid item xs={12} sm={4} className="rightside">
               <Grid item style={{border:'1px solid rgba(240, 250, 250, 1)', borderRadius:'24px', paddingTop:'10px'}}>
                   {this.selectAcordianOne()}
               </Grid>

               <Grid item style={{border:'1px solid rgba(240, 250, 250, 1)', marginTop:'10px', borderRadius:'24px'}}>
                   {this.selectAcordianTwo()}
               </Grid>

               <style>
                  {`
                    .customTabb {
                      color: rgba(1, 61, 79, 1); 
                      font-size: 16px;
                      font-family:Roboto;
                      font-weight:500;
                      text-transform:none;
                      min-width: auto;
                    }

                    .Mui-selected.customTabb {
                      color: rgba(1, 61, 79, 1); 
                    }

                    .customIndicatorr {
                      background-color:rgba(1, 61, 79, 1);
                    }
                  `}
                </style>
               <Grid item style={{border:'1px solid rgba(240, 250, 250, 1)',borderRadius:'24px', marginTop:'10px'}}>
                    <Accordion style={{boxShadow:'none'}}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{boxShadow:'none'}}
                        >
                        <Box style={{display:'flex', flexDirection:'column', padding:'10px'}}>
                            <Typography style={{fontSize:'28px', color:'#013D4F', fontFamily:'Gotham', fontWeight:400}}>Reviews</Typography>
                            <Typography style={{fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto', fontWeight:400}}>Feedback from your deals</Typography>
                        </Box>
                        
                        </AccordionSummary>
                        <AccordionDetails style={{ maxHeight: '420px', overflowY: 'auto' }}>
                            <Box style={{width:'100%'}}>
                              <AppBar position="static" color="default" style={{ backgroundColor:'white', boxShadow:'none', marginTop:'20px', maxWidth:'300px'}}>
                                <Tabs
                                    value={this.state.reviewTab}
                                    onChange={this.handleReviewTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    data-test-id="review1"
                                    classes={{
                                      indicator: 'customIndicatorr', 
                                    }}
                                    aria-label="full width tabs example"
                                    >
                                    <Tab label="Received" {...this.a11yProps(0)} classes={{ root: 'customTabb' }}/>
                                    <Tab label="Given" {...this.a11yProps(1)} classes={{ root: 'customTabb' }}/>
                                </Tabs>
                            </AppBar>
                              <this.TabPanel value={this.state.reviewTab} index={0}>
                               {this.ReviewsOne()}
                              </this.TabPanel>
                              <this.TabPanel value={this.state.reviewTab} data-test-id="reviewtab" index={1}>
                              {this.ReviewsOne()}
                              </this.TabPanel>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
               </Grid>
            </Grid>
        </Grid>
      </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const CustomSelect = styled(Select )({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(240, 250, 250, 1)', 
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(240, 250, 250, 1)', 
  },
})

const StyledTableRow = styled(TableRow)({
    '&:hover': {
      backgroundColor: '#F0FAFA',
    },
  });

const TabCellHeader = styled(TableCell )({
    fontFamily: 'Roboto', color: '#C5CBC9', border:'1px solid #F0FAFA', fontWeight:700, fontSize:'14px', backgroundColor:'white'
})

const TabCells = styled(TableCell)({
    border:'1px solid #F0FAFA', fontFamily: 'Gotham',color: '#191A19', fontWeight:400, fontSize:'16px'
})

const StyledTypo = styled(Typography)({
    fontFamily:'Roboto', fontSize:'14px', color:'#989A98', fontWeight:500
})
const StyledTypoDesc = styled(Typography)({
    fontFamily:'Roboto', fontSize:'36px', color:'#4B4C4B', fontWeight:500
})

const MenuItemStyled = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'rgba(240, 250, 250, 1)',  
  },
  '&:hover': {
    backgroundColor: 'rgba(240, 250, 250, 1)',  
  },
  
})

interface Styles {
  [key: string]: any;
}
const webStyles: Styles = {
  container: {
    flexDirection: "column",
    height: "100%",
    display: "flex",
    width: "100%",
  },
  header: {
    flexWrap: "nowrap",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 32px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    border: "none",
    padding: "unset",
  },

  headerLeftSide: { display: "flex",
    flexWrap: "nowrap",
    gap: "0px",
    alignItems: "center",
  },

  logo: {width: "52px"},

  notificationsButton: {
    
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
   
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },

  headerRightSide: {display: "flex",
    flexWrap: "nowrap",
   
    gap: "24px",
    alignItems: "center",
  },

  userAvatar: {
   
    height: "40px",
    width: "40px",
    borderRadius: "50%"
  },

 


  buttonText: {
    fontFamily: "Roboto, sans-serif",
    
    fontSize: "14px",
    color: "#013D4F",
    fontWeight: 500,
  },

  content: {
   
    marginLeft: "120px",
    width: "100%",
  },

  main: {
    display: "flex",
   
    position: "relative",
    flexWrap: "nowrap",
  },

}
// Customizable Area End