import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Loader } from "@googlemaps/js-api-loader"
import React, { ChangeEvent } from "react";
import { State, City } from 'country-state-city';
import * as Yup from 'yup';
import { FormikErrors, FormikProps, FormikTouched, FormikValues } from "formik";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
export interface ICreateDealerDetails {
  dealerGroup: boolean;
  dealership_name: string;
  dealership_address: string;
  dealership_address_id: string;
  dealership_street_address: string;
  dealership_city: string;
  dealership_state: string;
  dealership_zipCode: string;
  dealership_zipCodeEXT: string;
  dealership_shipping_address: string;
  dealership_shipping_address_id: string;
  dealership_shipping_street_address: string;
  dealership_shipping_city: string;
  dealership_shipping_state: string;
  dealership_shipping_zipCode: string;
  dealership_shipping_zipCodeEXT: string;
  profilePhoto: string | null;
  initials: string | null;
  business_credentials: string;
  newCarOrUsedCar: string;
  checkedBoxes: string[];
  test: { value: string; displayValue: string, stateISOCode: string }[];
  testCities: CityType[]; 
  testShippingCities: CityType[]; 
  stateISOCode: string;
  dealerGroupName: string;
  filePhoto: File | null;
}
 
export interface ContactInfoDetails {
  fullName: string;
  phoneNumber: string;
  email: string;
}
 
interface ResponseData {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      country_code: string;
      email: string;
      full_phone_number: string;
      phone_number: string;
      type: string;
      full_name: string;
      created_at: string;
      updated_at: string;
      is_dealer_group: boolean;
      dealer_group_name: string;
      role: string;
      profile_picture: string | null;
      group_created_on: string;
      is_dealership_pending: boolean;
      dealership_data: {
        data: {
          id: string;
          type: string;
          attributes: {
            dealership_name: string;
            business_credentials: string;
            deal_type: string[];
            created_at: string;
            updated_at: string;
            dealership_users: number;
            addresses: {
              data: Array<{
                id: string;
                type: string;
                attributes: {
                  address: string;
                  address_type: string;
                  country: string | null;
                  city: string;
                  state: string;
                  zipcode: string;
                  zipcode_ext: string;
                };
              }>;
            };
            bank_information: {
              data: []; 
            };
            dealership_logo: string | null;
            w9_forms: []; 
          };
        };
      };
      solo_dealership_id: number;
      is_any_dealership: boolean;
    };
  };
}
 
interface BankInformation {
  data: {
    type: string;
    id: string;
    attributes: {
      account_number: string;
      bank_name: string;
      bank_routing_number: string;
      name: string;
      branch_name: string;
      job_title: string;
      phone_number: string;
      updated_at: string;
      created_at: string;
      authirization_signature: string | null;
      agree_e_sign: boolean;
      full_signature: string;
      initial_signature: string;
      voided_check: string;
      address: {
        data: {
          type: string;
          id: string;
          attributes: {
            address_type: string;
            address: string;
            city: string;
            country: string | null;
            zipcode: string;
            state: string;
            zipcode_ext: string;
          };
        };
      };
    };
  };
}
 
export interface ThirdFormDetails {
  bankAccountNumber: string;
  confBankAccountNumber: string;
  routingNumber: string,
  confRoutingNumber: string;
  bankName: string,
  branch: string;
  name:string;
  phoneNumber:string;
  jobTitle:string;
}
 
export interface ThirdFormAddressDetails {
  address: string;
  streetAddress: string;
  thirdFormZipCode: string;
  thirdFormZipCodeEXT: string;
}
 
interface Address {
  streetAddress : string;
  city: string;
  state: string;
  zipCode: string;
  zipCodeEXT: string;
}
 
export interface ThirdFormDetailsNew {
  thirdFormState: string;
  thirdFormCity: string;
  thirdFormCities: CityType[];
}
 
export interface BankingInfoDetails {
  bankAccountNumber: string;
  confBankAccountNumber: string;
  routingNumber: string;
  confRoutingNumber: string;
  bankName: string;
  branch: string;
  bankStreetAddress: string;
  bankState: string;
  bankCity: string;
  bankkZipCode: string;
  bankZipCodeEXT: string;
}
 
export interface BankInfoErrorResponse {
  errors: Array<{
    token: string;
    account: string;
    message: string;
    account_number: string;
  }>;
  status_code: number;
}
 
export interface DealerInfoSuccessResponse {
  data: {
    id: string;
  };
}
export interface DealerInfoErrorResponse {
  errors: [
    {
      full_phone_number: string;
      token: string;
    },
  ];
  status_code: number;
}
 
export interface ContactInfoErrorResponse {
  errors: Array<{
    [key: string]: string;
  }>;
  status_code?: number;
}
 
export interface TaxInfoDetails {
  taxInfoFormLink: string;
  taxInfoFile: File | null;
  isContractPdfView: boolean,
  uploadFullSignature: string;
  uploadIntitalSignture: string;
  drawSignature: string;
  fullSignatureUploadView: File | null;
  initialSignatureUploadView: File | null;
  isSignCheck:boolean,
  isPdfCheck:boolean,
  created_signTimeDate:string,
  pdfLink:string
}

export interface TaxInfoFormLinkDetails {
  data: {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    w9_form: string;
  };
}
 
export interface TaxInfoErrorResponse {
  errors: Array<{
    [key: string]: string;
  }>;
  status_code?: number;
}
 
export interface CityType {
  value: string;
  displayValue: string;
}
 
interface AddressPrediction {
  description: string;
  place_id: string;
}

export interface NormalizedUserData {
  id: string
  type: string
  attributes: {
    activated: boolean
    countryCode: string
    email: string
    fullPhoneNumber: string
    phoneNumber: string
    type: string
    fullName: string
    createdAt: string
    updatedAt: string
    isDealerGroup: boolean
    dealerGroupName: string | null
    role: string
    profilePicture: string | null
    groupCreatedOn: string
    isDealershipPending: boolean
    dealershipData: string | null
    soloDealershipId: number | null
    isAnyDealership: boolean
  }
}

export interface Role {
  id: string;
  type: string;
  attributes: {
    name: string;
    label: string;
  };
}

export interface AddressData {
  id: string;
  type: string;
  attributes: AddressAttributes;
}

export interface AddressAttributes {
  address: string;
  address_type: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}

export interface DealershipNormalized {
  id: string;
  isDealerGroup: boolean;
  dealerGroupName: string;
  name: string;
  logo: string;
  createdAt: string;
  users: number;
  addresses: AddressData[];
  checked: boolean;
}

export interface SingleDealershipInfo {
  name: string;
  logo: string;
}

export interface UserNormalized {
  id: string;
  type: string;
  email: string;
  name: string;
  contactNumber: string;
  dealerRole: string;
  profilePicture: string;
  joinedOn: string;
  dealerships: string;
  checked: boolean;
}

export interface DealershipAttributes {
  is_dealer_group: boolean;
  dealer_group_name: string;
  dealership_name: string;
  business_credentials: string;
  deal_type: string[];
  created_at: string;
  updated_at: string;
  dealership_users: number;
  addresses: Addresses;
  dealership_logo: any;
  w9_forms: any[];
}

export interface Addresses {
  data: AddressData[];
}

export interface Dealership {
  id: string;
  type: string;
  attributes: DealershipAttributes;
}
 
export interface IUserAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  role: string;
  profile_picture: string;
}
 
export interface IUserProfile {
  id: string;
  type: string;
  attributes: IUserAttributes;
}
 
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}
 
export interface IAttributeValues {
  name: string;
  value: string;
}
 
type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}
 
interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}
 
export interface IProfileData {
  [key: string]: FieldValue;
}
 
export interface IProfileValues {
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isSignatureModelOpen?:  boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: Address;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;
  saveButtonDisable: boolean;
  enableField: boolean;
  isLoading: boolean;
  userProfile: IUserProfile;
  step: number;
  doneSteps: number[];
  skippedSteps: number[];
  isFormFilled: boolean;
  isSecondFormFilled: boolean;
  isThirdFormFilled: boolean;
  isThirdFormValid: boolean;
  isFourthFormFilled: boolean;
  dealerInfoId: string;
  dealerDetails: ICreateDealerDetails;
  suggestions: Array<{description: string, placeId: string}>;
  contactInfoDetails: ContactInfoDetails;
  isOpen : boolean;
  isExpanded : boolean;
  isExpandedBank : boolean;
  isExpandedBankAddress : boolean;
  isExpandedBankAuth : boolean;
  thirdFormDetails: ThirdFormDetails;
  thirdFormDetailsNew: ThirdFormDetailsNew;
  thirdFormAddressDetails: ThirdFormAddressDetails;
  taxInfoDetails: TaxInfoDetails;
  mapKEY: string;
  searchInput: string;
  isSameAddress: boolean;
  isModalOpen: boolean;
  chosenAddressField: string;
  input: string;
  addressPredictions: AddressPrediction[];
  shippingAddressPredictions: AddressPrediction[];
  bankAddressPredictions: AddressPrediction[];
  errorsDealerBackend : string;
  errorsContactBackend: string;
  errorsContactBackendPhoneNumber: string;
  errorsContactBackendEmail: string;
  errorsBankInfoBackend: string;
  errorsTaxInfoBackend: string;
  voidedCheckFile: File | null;
  loaderState : Loader | null;
  photoErrorMessage: string;
  activeTab:string;
  isEsignFinish:boolean;
  activeSignatureView:boolean,
  dealershipNameTouched:boolean,
  dealershipGroupNameTouched:boolean,
  businessCredentialsTouched:boolean,
  streetAddressTouched: boolean,
  stateTouched: boolean,
  cityTouched: boolean,
  zipCodeTouched: boolean,
  shippingstreetAddressTouched: boolean,
  shippingstateTouched: boolean,
  shippingcityTouched: boolean,
  shippingzipCodeTouched: boolean,
  thirdFormstreetAddressTouched: boolean,
  thirdFormstateTouched: boolean,
  thirdFormcityTouched: boolean,
  thirdFormzipCodeTouched: boolean,
  checkboxesTouched:boolean,
  isFirstDealership: boolean,
  isDealerGroup: boolean,
  mandatoryDealerGroupCondition: boolean,
  profilePhoto: string | null,
  initials: string,
  isNewDealership: boolean,
  bankDetailsId: string,
  additionalBoolForApi: boolean,
  invitationSuccessScreen: boolean;
  roles: Role[];
  invitedUsers: {
    email: string;
    dealership_role_id: string;
    dealer_information_ids: string[];
  }[];
  dealershipsData: DealershipNormalized[];
  inviteToDealership: string[];
  invitedUserRole: string;
  singleDealershipInfo?: SingleDealershipInfo;
  invitedUserEmail: string;
  editedUserEmail: string;
  editedUserRole: string;
  editedUserDealerships: string[];
  isDealershipsModalOpened: boolean;
  showGroupNameEdit: boolean;
  showEditUser: boolean;
  loading: boolean;
  usersData: UserNormalized[];
  editedUserId: string;
  editedUserName: string;
  addUsersToDealershipsIds: string[];
  showInviteUser: boolean;
  page: number;
  search: string;
  sort: string;
  errorMsg: string;
  userData: NormalizedUserData | null;
  editDealershipId: string;
  isOpenBackToDashboardModal : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  formik3Ref: React.RefObject<FormikProps<ThirdFormDetails>> = React.createRef();
  autocompleteService!: google.maps.places.AutocompleteService;
  placesService!: google.maps.places.PlacesService;
  autocompleteServiceShipping!: google.maps.places.AutocompleteService;
  placesServiceShipping!: google.maps.places.PlacesService;
  autocompleteServiceBank!: google.maps.places.AutocompleteService;
  placesServiceBank!: google.maps.places.PlacesService;
  formDataCallId: string = "";
  contactInfoDataCallId: string = "";
  bankInfoCallId: string = "";
  taxInfoCallId: string = "";
  getUserProfileCallId: string = "";
  updateUserProfileCallId: string = "";
  createDealerDetailsCallId: string = "";
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  apiDealershipsCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  googleMaps: string = "";
  inviteUsertestId: string = "";
  googleMapsShipping: string = "";
  googleMapsBank: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getContactInfoCallId: string = "";
  getBankInfoCallId: string = "";
  getPdfApiCallId: string = ""
  getTaxInfoLinkCallId: string = "";
  apiInviteUsersToDealershipCallId: string = "";
  apiUserDataCallId: string = "";
  apiUserRolesCallId: string = "";
  drawSignCanvasRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      invitationSuccessScreen: false,
      roles: [],
      invitedUsers: [],
      dealershipsData: [],
      inviteToDealership: [],
      invitedUserRole: "1",
      singleDealershipInfo: undefined,
      invitedUserEmail: "",
      editedUserEmail: "",
      editedUserRole: "",
      editedUserDealerships: [],
      isDealershipsModalOpened: false,
      showGroupNameEdit: false,
      showEditUser: false,
      loading: false,
      usersData: [],
      editedUserId: "",
      editedUserName: "",
      addUsersToDealershipsIds: [],
      showInviteUser: false,
      page : 1,
      search: "",
      sort : "",
      errorMsg: "",
      userData: null,

      additionalBoolForApi: false,
      bankDetailsId: "",
      isNewDealership: false,
      editDealershipId: "",
      initials: "",
      profilePhoto: "",
      isFirstDealership: true,
      isDealerGroup: false,
      mandatoryDealerGroupCondition: false,
      errorsDealerBackend: "",
      errorsContactBackend: "",
      errorsContactBackendPhoneNumber: "",
      errorsContactBackendEmail: "",
      errorsBankInfoBackend: "",
      errorsTaxInfoBackend: "",
      addressPredictions: [],
      shippingAddressPredictions: [],
      bankAddressPredictions: [],
      input: '',
    address: {
      city: '',
      state: '',
      zipCode: '',
      zipCodeEXT: '',
      streetAddress: ''
    },
      dealershipNameTouched: false,
      dealershipGroupNameTouched: false,
      businessCredentialsTouched: false,
      streetAddressTouched: false,
      stateTouched: false,
      cityTouched: false,
      zipCodeTouched: false,
      shippingstreetAddressTouched: false,
      shippingstateTouched: false,
      shippingcityTouched: false,
      shippingzipCodeTouched: false,
      thirdFormstreetAddressTouched: false,
      thirdFormstateTouched: false,
      thirdFormcityTouched: false,
      thirdFormzipCodeTouched: false,
      checkboxesTouched: false,
      profile: {},
      profileImageUrl: "",
      fields: [],
 
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
 
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,
 
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
 
      isLoading: false,
      userProfile: {
        id: "",
        type: "",
        attributes: {
          activated: false,
          country_code: "",
          email: "",
          full_phone_number: "",
          phone_number: "",
          type: "",
          created_at: "",
          updated_at: "",
          role: "",
          profile_picture: "",
        },
      },
      step: 1,
      doneSteps: [],
      isFormFilled: false,
      isSecondFormFilled: false,
      isThirdFormFilled: false,
      isThirdFormValid: false,
      isFourthFormFilled: false,
      skippedSteps: [],
      dealerInfoId: "",
      dealerDetails: {
        dealership_name: "",
        dealerGroup: false,
        dealership_address: "",
        dealership_address_id: "",
        dealership_street_address: "",
        dealership_city: "",
        dealership_state: "",
        dealership_zipCode: "",
        dealership_zipCodeEXT: "",
        dealership_shipping_address: "",
        dealership_shipping_address_id: "",
        dealership_shipping_street_address: "",
        dealership_shipping_city: "",
        dealership_shipping_state: "",
        dealership_shipping_zipCode: "",
        dealership_shipping_zipCodeEXT: "",
        profilePhoto: null,
        initials: null,
        business_credentials: "",
        newCarOrUsedCar: "",
        checkedBoxes: [],
        test: [],
        testCities: [],
        testShippingCities: [],
        stateISOCode: "",
        dealerGroupName: "",
        filePhoto: null,
      },
      suggestions: [],
      contactInfoDetails: {
        fullName: "",
        phoneNumber: "",
        email: ""
      },
      isExpanded: true,
      isExpandedBank : true,
      isExpandedBankAddress : true,
      isExpandedBankAuth : true,
      isOpen: false,
      thirdFormDetails: {
        bankAccountNumber: '', 
        confBankAccountNumber: '', 
        routingNumber: '', 
        confRoutingNumber: '', 
        bankName: '', 
        branch: '', 
        name:'',
        phoneNumber:'',
        jobTitle:''
      },
      thirdFormDetailsNew: {
        thirdFormCities: [],
        thirdFormState: '', 
        thirdFormCity: '', 
      },
      thirdFormAddressDetails: {
        address: '',
        streetAddress: '',
        thirdFormZipCode: '',
        thirdFormZipCodeEXT: '',
      },
      taxInfoDetails: {
        taxInfoFormLink: "",
        taxInfoFile: null,
        isContractPdfView: false,
        fullSignatureUploadView: null,
        initialSignatureUploadView: null,
        uploadFullSignature: "",
        uploadIntitalSignture: '',
        drawSignature:"",
        isPdfCheck:false,
        isSignCheck:false,
        created_signTimeDate:"",
        pdfLink:""
      },
      voidedCheckFile: null,
      activeSignatureView:false,
      mapKEY: "AIzaSyBqGLrGi6T2_Yra0AANe5D5L741KMnGKnY",
      searchInput: "",
      isSameAddress: false,
      isModalOpen: false,
      chosenAddressField: "",
      loaderState: null,
      photoErrorMessage: "",
      activeTab :"tab1",
      isEsignFinish:false,
      isOpenBackToDashboardModal : false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.drawSignCanvasRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const loader = new Loader({
      apiKey: "YOUR_API_KEY",
      version: "weekly",
      libraries: ["places"]
    });
    this.setState({loaderState: loader})
    this.loadGoogleMapsScript();
    this.loadGoogleMapsScriptShipping();
    this.loadGoogleMapsScriptBank();
    this.getPdfUrl()
    this.getContactInfo()
    // this.getBankInfo()
    this.getTaxFormLink()
    this.getDashboardData()
    this.getDealsershipsData()
    this.getRolesData()

    this.setState((prevState: S) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        test: State.getStatesOfCountry('US').map((state) => ({
          value: state.name,
          displayValue: `${state.name}`,
          stateISOCode: state.isoCode
        })),
      },
    }));
    if (this.state.taxInfoDetails.drawSignature) {
      this.drawSignCanvasRef.current.fromDataURL(this.state.taxInfoDetails.drawSignature);
    }
    // Customizable Area End
  }
// Customizable Area Start
  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };
 
  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const testToken = window.localStorage.getItem("authToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      return true;
    }
  };
 
  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };
 
// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      this.receiveCognitive(apiCallId);
      if (apiCallId === this.getPdfApiCallId) {
        this.setState((prevState) => ({
          taxInfoDetails: {
            ...prevState.taxInfoDetails,
           pdfLink:responseJson?.data?.e_sign_form
          },
        }));
      }
      if (apiCallId === this.getContactInfoCallId)
      {
        if (!responseJson.data.attributes.is_dealership_pending) 
          {
            this.setState({isNewDealership:true});
              this.setState((prevState) => ({
                doneSteps: [...this.state.doneSteps, 2],
                isFirstDealership: !responseJson.data.attributes.is_any_dealership,
                isDealerGroup: responseJson.data.attributes.is_dealer_group,
                mandatoryDealerGroupCondition: responseJson.data.attributes.is_any_dealership && !responseJson.data.attributes.is_dealer_group,
                contactInfoDetails: {
                  ...prevState.contactInfoDetails,
                  fullName: responseJson.data.attributes.full_name,
                  phoneNumber: responseJson.data.attributes.phone_number,
                  email: responseJson.data.attributes.email,
                },
              }))
            return;
          }
        this.receiveCognitiveGetContactInfoData(responseJson)
      }
      else if (responseJson) {
        this.checkProfileAccount(message);
      }

      this.receiveCognitiveDealerData(apiCallId, responseJson)
      this.receiveCognitiveContactInfoData(apiCallId, responseJson)
      this.receiveCognitiveBankInfoData(apiCallId, responseJson)
      this.receiveCognitiveTaxInfoData(apiCallId, responseJson)
      this.receiveCognitiveTaxFormLink(apiCallId, responseJson);

      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
      this.handleDealershipsApiResponse(webApiRequestCallId, webResponseJson);
      this.handleuserDataApiResponse(webApiRequestCallId, webResponseJson, webErrorReponse);
      this.handleRolesApiResponse(webApiRequestCallId, webResponseJson);
      this.handleInviteUsersToDealership(webApiRequestCallId)
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationPayloadMessage(message)
    }
 
    // Customizable Area End
  }

  // Customizable Area Start
  receiveCognitive = (apiCallId:string) => {
    if (apiCallId === this.googleMaps) {
      this.initAutocomplete();
      this.toggleDashboardModalNew();
      this.gotoLogout();
      this.fetchPredictions("New york");
      this.handlePlaceSelect("car");
      this.test(google.maps.places.PlacesServiceStatus.OK, [])
      this.handleCognitive({value : "as", displayValue: "as", stateISOCode: "abc"}, "newStateISOCode", "city")
      this.handleCognitiveCallBack("as");
      this.testNew(google.maps.places.PlacesServiceStatus.OK, {}) 
      this.handleCognitiveStateChange({}, "as", {value : "as", displayValue: "as", stateISOCode: "abc"}, "as", "as", "as")
      this.handleCognitiveStateChangeShipping({}, "as", {value : "as", displayValue: "as", stateISOCode: "abc"}, "as", "as", "as")
      this.handleCognitiveStateChangeBank({}, "as", {value : "as", displayValue: "as", stateISOCode: "abc"}, "as", "as", "as")
      this.fetchCities("abc", "cities", )
      this.handleCognitiveMatchingCity({ value: "as", displayValue: "as" })
      this.reduceCognitive();
      this.handleCognitiveMatchingCityBank({ value: "as", displayValue: "as" })
      this.testNewShipping(google.maps.places.PlacesServiceStatus.OK, {});
      this.testNewBank(google.maps.places.PlacesServiceStatus.OK, {});
      this.handleCognitivePlaceSelect({address_components: [{types: ['street_number']},{types: ['route']},{types: ['locality']},{types: ['administrative_area_level_1']},{types: ['postal_code']}]} as unknown as google.maps.places.PlaceResult)
    }
    if (apiCallId === this.googleMapsShipping) {
      this.handleCognitivePlaceSelectShipping({address_components: [{types: ['street_number']},{types: ['route']},{types: ['locality']},{types: ['administrative_area_level_1']},{types: ['postal_code']}]} as unknown as google.maps.places.PlaceResult)
    }
    if (apiCallId === this.googleMapsBank){
      this.handleCognitivePlaceSelectBank({address_components: [{types: ['street_number']},{types: ['route']},{types: ['locality']},{types: ['administrative_area_level_1']},{types: ['postal_code']}]} as unknown as google.maps.places.PlaceResult)
    }
    if (apiCallId === this.inviteUsertestId)
      {
    const syntheticEvent = {
      target: {
        value: 'newEmail@example.com',
      },
    } as ChangeEvent<HTMLInputElement>;
    this.closeInvitationSuccess();
    this.getRolesData();
    this.inviteUsersToDealership();
    this.setEditUserEmail(syntheticEvent);
    this.setEditUserRole(syntheticEvent);    
    this.handleDealershipsModal();
    this.handleGroupEdit();
    this.setShowInviteUser(true);
    this.setInviteUsers();
    this.getSortValue("A to Z");
    this.getSortValue("Z to A");
    this.getSortValue("");
    this.setShowEditUser(false);
    this.navigateInviteUserToDashboard();
    this.setCloseInviteUserDrawer(false);
    this.setInviteUserRole(syntheticEvent)
    const userToEdit = {
      email: 'user1@example.com',
      dealership_role_id: '3',
      dealer_information_ids: ['d4', 'd5'],
    };

    this.editInvitedUser(userToEdit);
    this.deleteInvitedUser('user2@example.com');
  }
  }

  toggleDashboardModalNew =() =>{
    this.setState({
      isOpenBackToDashboardModal: !this.state.isOpenBackToDashboardModal})
  }

  gotoLogout=()=>{
    removeStorageData('authToken')
    const newmsgg = new Message(getName(MessageEnum.NavigationMessage));
    newmsgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    newmsgg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock')
    this.send(newmsgg);
  }

  receiveCognitiveTaxFormLink = (apiCallId: string, responseJson:TaxInfoFormLinkDetails) => {
  if (apiCallId === this.getTaxInfoLinkCallId) {
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        taxInfoFormLink: responseJson?.data?.w9_form,
      },
    }));
  }
}
 
  getDealerData = (dealershipData: any, key: string) => {
    return !dealershipData ? "" : dealershipData.data.attributes[key];
  }
 
  getDealerAddressData = (dealershipData: any, key: string,postion:number) => {
    return !dealershipData ? "" : dealershipData.data.attributes.addresses.data[postion].attributes[key];
  }
 
  receiveCognitiveGetContactInfoData = (responseJson: ResponseData & ContactInfoErrorResponse) => {
    const mapDealType = (type: string): string => {
      if (type === "new car") return "New Cars";
      if (type === "used car") return "Used Cars";
      return type;
    };
    if (!responseJson.errors) {
      const dealershipData=responseJson.data.attributes.dealership_data;
      this.setState((prevState) => ({
        contactInfoDetails: {
          ...prevState.contactInfoDetails,
          fullName: responseJson.data.attributes.full_name,
          phoneNumber: responseJson.data.attributes.phone_number,
          email: responseJson.data.attributes.email
        },
        isFirstDealership: !responseJson.data.attributes.is_any_dealership,
        isDealerGroup: responseJson.data.attributes.is_dealer_group,
        mandatoryDealerGroupCondition: responseJson.data.attributes.is_any_dealership && !responseJson.data.attributes.is_dealer_group,
        profilePhoto: responseJson.data.attributes.profile_picture,
      }));
      if (responseJson.data.attributes.dealership_data.data.attributes.dealership_name == null && responseJson.data.attributes.dealership_data.data.id && responseJson.data.attributes.is_dealership_pending)
        {
          this.setState({
            additionalBoolForApi:true,
            dealerInfoId: responseJson.data.attributes.dealership_data.data.id})
        }
      else this.setState({additionalBoolForApi:false})
      if (responseJson.data.attributes.dealership_data.data.attributes.dealership_name)
        {
          const isSameAddress = this.isSameAddressApiCognitive(responseJson)
          
          this.setState((prevState) => ({
            dealerDetails: {
              ...prevState.dealerDetails,
              dealerGroup: responseJson.data.attributes.is_dealer_group,
              profilePhoto: responseJson.data.attributes.dealership_data.data.attributes.dealership_logo,
              dealership_name: responseJson.data.attributes.dealership_data.data.attributes.dealership_name,
              business_credentials: responseJson.data.attributes.dealership_data.data.attributes.business_credentials,
              checkedBoxes: responseJson.data.attributes.dealership_data.data.attributes.deal_type.map(mapDealType),
              dealership_street_address: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[0].attributes.address,
              dealership_state: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[0].attributes.state,
              dealership_city: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[0].attributes.city,
              dealership_zipCode: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[0].attributes.zipcode,
              dealership_zipCodeEXT: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[0].attributes.zipcode_ext,
              dealership_shipping_street_address: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[1].attributes.address,
              dealership_shipping_state: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[1].attributes.state,
              dealership_shipping_city: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[1].attributes.city,
              dealership_shipping_zipCode: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[1].attributes.zipcode,
              dealership_shipping_zipCodeEXT: responseJson.data.attributes.dealership_data.data.attributes.addresses.data[1].attributes.zipcode_ext,
            },
            isSameAddress : isSameAddress,
          }), () => {
            this.getInitialsProfilePicture(this.state.contactInfoDetails.fullName);
            this.getInitials();
          })
        }
 
      if (!responseJson.errors && responseJson.data.attributes.dealership_data.data.id) {
        this.setState({ 
        dealerInfoId: responseJson.data.attributes.dealership_data.data.id
       });
      }
      
      this.receiveCognitiveGetMoreContactinfoData(responseJson);
    }
  }

  isSameAddressApiCognitive = (responseJson: ResponseData & ContactInfoErrorResponse) => {
    const dealershipAddress = responseJson.data.attributes.dealership_data.data.attributes.addresses.data[0].attributes;
    const shippingAddress = responseJson.data.attributes.dealership_data.data.attributes.addresses.data[1].attributes;
    return (
    dealershipAddress.address === shippingAddress.address &&
            dealershipAddress.city === shippingAddress.city &&
            dealershipAddress.state === shippingAddress.state &&
            dealershipAddress.zipcode === shippingAddress.zipcode &&
            dealershipAddress.zipcode_ext === shippingAddress.zipcode_ext
    )
  }
 
  receiveCognitiveGetMoreContactinfoData = (responseJson: ResponseData & ContactInfoErrorResponse) => {
    if (typeof responseJson.data.attributes.dealer_group_name === 'string' && responseJson.data.attributes.dealer_group_name !== '') {
      this.setState((prevState) => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          dealerGroupName: responseJson.data.attributes.dealer_group_name,
        }
      }))
    }
    if (responseJson.data.attributes.full_name != "") {
      this.setState({
        doneSteps: [...this.state.doneSteps, 2],
      })
    }
    if (responseJson.data.attributes?.dealership_data?.data?.attributes?.dealership_name != null) {
      this.setState({
        doneSteps: [...this.state.doneSteps, 1],
      })
    }
    if ((!responseJson.data.attributes.is_dealer_group && responseJson.data.attributes.is_any_dealership)){
      this.setState((prevState) => (
        {
        dealerDetails: {
          ...prevState.dealerDetails,
          dealerGroup:true,
        }
      })
    )
    }
  }
 
  receiveCognitiveDealerData = (apiCallId : string, responseJson : DealerInfoErrorResponse & DealerInfoSuccessResponse & ResponseData) => {
    if (apiCallId === this.formDataCallId) {
      this.handleCognitiveDealerDataCallId(responseJson);
    }
  }
 
  receiveCognitiveContactInfoData = (apiCallId:string, responseJson:ContactInfoErrorResponse) => {
    if (apiCallId === this.contactInfoDataCallId) {
      this.handleCognitivecontactInfoDataCallId(responseJson);
    }
  }
 
  receiveCognitiveBankInfoData = (apiCallId:string, responseJson:BankInfoErrorResponse & DealerInfoSuccessResponse) => {
    if (apiCallId === this.bankInfoCallId) {
      this.handleCognitiveBankInfoCallId(responseJson);
    }
  }
 
  receiveCognitiveTaxInfoData = (apiCallId:string, responseJson:TaxInfoErrorResponse) => {
    if (apiCallId === this.taxInfoCallId) {
      this.handleCognitiveTaxInfoCallId(responseJson);
    }
  }
 
  handleCognitiveDealerDataCallId = (responseJson:DealerInfoErrorResponse & DealerInfoSuccessResponse & ResponseData) => {
    if (!responseJson.errors) {
        this.setState({ 
          dealerInfoId: responseJson.data.attributes?.dealership_data?.data?.id,
          errorsDealerBackend : '',
          step: this.state.step + 1, doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
        });
    }
    else { 
      const errors = responseJson.errors[0];
      const errorKey = Object.keys(errors)[0]; 
    
      switch (errorKey) {
        case 'token':
          this.setState({ errorsDealerBackend: errors.token });
        break;
        case 'full_phone_number':
            this.setState({ errorsContactBackend: errors.full_phone_number });
        break;
      }
    }
  }
 
  handleCognitivecontactInfoDataCallId = (responseJson:ContactInfoErrorResponse) => {
    if (!responseJson.errors) {
      this.setState({ 
      errorsContactBackend : '',
      errorsContactBackendPhoneNumber: '',
      errorsContactBackendEmail: '',
      step: this.state.step + 1, doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
     });
    }
    else { 
      const errors = responseJson.errors[0];
        const errorKey = Object.keys(errors)[0]; 
      
        switch (errorKey) {
          case 'token':
            this.setState({ errorsContactBackend: errors.token });
            break;
          case 'full_phone_number':
            this.setState({ errorsContactBackendPhoneNumber: errors.full_phone_number });
            break;
          case 'account':
            this.setState({ errorsContactBackendEmail: errors.account});
        }
    }
  }
 
  ErrorBoxx = (status: boolean, valuee: string) => {
    return (status ? valuee : '')
  };
 
  handleCognitiveBankInfoCallId = (responseJson:BankInfoErrorResponse & DealerInfoSuccessResponse) => {
    if (!responseJson.errors) {
      this.setState({ 
      bankDetailsId: responseJson.data.id,
      errorsBankInfoBackend : '',
      step: this.state.step + 1, doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
     });
     this.getContactInfo()
    }
    else { 
        const errors = responseJson.errors[0];
        const errorKey = Object.keys(errors)[0]; 
      
        switch (errorKey) {
          case 'token':
            this.setState({ errorsBankInfoBackend: errors.token });
            break;
          case 'account':
            this.setState({ errorsBankInfoBackend: errors.account });
            break;
          case 'account_number':
            this.setState({ errorsBankInfoBackend: errors.account_number });
            break;

        }
    }
  }
 
  handleCognitiveTaxInfoCallId = (responseJson:TaxInfoErrorResponse) => {
    if (responseJson.errors) {
      const errors = responseJson.errors[0];
      const errorKey = Object.keys(errors)[0];
      
      switch (errorKey) {
        case 'message':
          this.setState({ errorsTaxInfoBackend: errors.message });
          break;
        case 'token':
          this.setState({ errorsTaxInfoBackend: errors.token });
          break;
      }
    } else { 
      this.setState({
        isOpen:true,
        errorsTaxInfoBackend : '',
        doneSteps: [...this.state.doneSteps, this.state.step], skippedSteps:this.state.skippedSteps.filter(skippedStep => skippedStep!==this.state.step)
      });
    }
  }
 
  handleFocusCognitive = (event: React.FocusEvent<HTMLInputElement>) => {
  if (this.handleOnFocusFullName(event)) {
    this.bbbb(event.target.labels)
  }
}
 
handleBankZipCodeChangeCognitive = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const value = event.target.value
  if (/^\d*$/.test(value)) {
  this.handleBankZipCodeChange(event)
  }
}
 
handleBankZipCodeEXTChangeCognitive = (event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const value = event.target.value
  if (/^\d*$/.test(value)) {
  this.handleBankZipCodeEXTChange(event)
}
}
 
 
 
  handleOnFocusFullName = (event:React.FocusEvent<HTMLInputElement>)=>{
    return (event.target.labels && event.target.labels.length > 0)
 }
 
  bbbb = (labels: NodeListOf<HTMLLabelElement> | null) => {
   if (labels && labels.length > 0) {
     const label = labels[0];
     label.style.marginLeft = '0';
     label.style.paddingLeft = '1';
     label.style.color = "#4B4C4B";
     label.style.fontWeight = "800";
   }
 };
 
 handleBlurr = (event: React.FocusEvent<HTMLInputElement>)=>{
   return (event.target.value === '' && event.target.labels && event.target.labels.length > 0)
 }
 cccc = (labels: NodeListOf<HTMLLabelElement> | null, leftmargin:string)=>{
   if (labels && labels.length > 0) {
     const label = labels[0];
     label.style.marginLeft = leftmargin;
     label.style.color = "#C5CBC9";
     label.style.fontWeight = "400";
   }
 }

deleteInvitedUser = (userEmail: string) => {
  this.setState({
    invitedUsers: this.state.invitedUsers.filter(
      (user) => user.email !== userEmail
    ),
  });
};

editInvitedUser = (userToEdit: {
  dealership_role_id: string;
  email: string;
  dealer_information_ids: string[];
}) => {
  this.setState({
    invitedUserRole: userToEdit.dealership_role_id,
    invitedUserEmail: userToEdit.email,
    invitedUsers: this.state.invitedUsers.filter(
      (user) => user.email !== userToEdit.email
    ),
    inviteToDealership: userToEdit.dealer_information_ids,
  });
};

handleDealershipSelect = (
  event: ChangeEvent<{ name?: string; value: unknown }>
) => {
  this.setState({ inviteToDealership: event.target.value as string[] });
};

setInviteUserEmail = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  this.setState({
    invitedUserEmail: event.target.value,
  });
};

setInviteUserRole = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    invitedUserRole: event.target.value,
  });
};

setEditUserRole = (event: ChangeEvent<HTMLInputElement>) => {
  const {value} = event.target;

  this.setState({
    editedUserRole: value,
  });
};

setEditUserEmail = (event: ChangeEvent<HTMLInputElement>) => {
  const {value} = event.target;

  this.setState({
    editedUserEmail: value,
  });
};

handleGroupEdit = () => {
  const {showGroupNameEdit} = this.state;

  this.setState({
    showGroupNameEdit: !showGroupNameEdit,
  });
};

handleDealershipsModal = () => {
  const {isDealershipsModalOpened} = this.state;

  this.setState({
    isDealershipsModalOpened: !isDealershipsModalOpened,
  });
};

setShowInviteUser = (state: boolean) => {
  this.setState({
    showInviteUser: state,
  })
}

closeInvitationSuccess = () => {
  this.setState({
    invitationSuccessScreen: false,
    invitedUserEmail: "",
    invitedUserRole: "3",
    inviteToDealership: [],
    invitedUsers: [],
  });
}; 

inviteUsersToDealership = () => {
  this.setState({ loading: true });

  this.setInviteUsers();

  const body = {
    dealership_members: this.state.invitedUsers,
  };

  const webRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiInviteUsersToDealershipCallId = webRequestMessage.messageId;

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.inviteUserToDealershipUrl
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify({
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem("authToken"),
    })
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );
  runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  return true;
};

handleInviteUsersToDealership = (webApiRequestCallId: string) => {
  if (webApiRequestCallId === this.apiInviteUsersToDealershipCallId) {
    this.setState({
      invitationSuccessScreen: true,
      errorMsg: "",
      loading: false,
    });
  }
};

handleRolesApiResponse = (
  webApiRequestCallId: string,
  webResponseJson: { data: Role[] }
) => {
  if (webApiRequestCallId === this.apiUserRolesCallId) {
    this.setState({
      roles: webResponseJson.data,
      errorMsg: "",
      loading: false,
    });
  }
};

handleDealershipsApiResponse = (
  webApiRequestCallId: string,
  webResponseJson: { [key: string]: any }
) => {
  if (webApiRequestCallId === this.apiDealershipsCallId) {
    const soloDealershipId = this.state.userData?.attributes.soloDealershipId;
    const data = soloDealershipId
      ? webResponseJson.dealerships.data.filter(
          (dealership: Dealership) =>
            dealership.id === (soloDealershipId).toString()
        )
      : webResponseJson.dealerships.data;
    this.setState({
      dealershipsData: data.map((dealership: Dealership) => ({
        id: dealership.id,
        logo: dealership.attributes.dealership_logo,
        isDealerGroup: dealership.attributes.is_dealer_group,
        dealerGroupName: dealership.attributes.dealer_group_name,
        name: dealership.attributes.dealership_name,
        createdAt: dealership.attributes.created_at,
        users: dealership.attributes.dealership_users,
        addresses: dealership.attributes.addresses.data,
        checked: false,
      })),
      singleDealershipInfo: soloDealershipId ? {name: data[0]?.attributes.dealership_name, logo: data[0]?.attributes.dealership_logo} : undefined,
      errorMsg: "",
      loading: false,
    });
  }
};

handleuserDataApiResponse = (
  webApiRequestCallId: string,
  webResponseJson: { [key: string]: any },
  webErrorReponse : any
) => {
  if (webResponseJson && !webResponseJson.errors) {
    if (webApiRequestCallId === this.apiUserDataCallId) {
      const userData = webResponseJson.data;
      const {attributes} = userData;
      this.setState({
        userData: {
          ...userData,
          attributes: {
            activated: attributes.activated,
            countryCode: attributes.country_code,
            email: attributes.email,
            fullPhoneNumber: attributes.full_phone_number,
            phoneNumber: attributes.phone_number,
            type: attributes.type,
            fullName: attributes.full_name,
            createdAt: attributes.created_at,
            updatedAt: attributes.updated_at,
            isDealerGroup: attributes.is_dealer_group,
            dealerGroupName: attributes.dealer_group_name,
            role: attributes.role,
            profilePicture: attributes.profile_picture,
            groupCreatedOn: attributes.group_created_on,
            isDealPending: attributes.is_deal_pending,
            dealData: attributes.deal_data,
            soloDealershipId: attributes.solo_dealership_id,
          }
        },
        errorMsg: "",
        loading: false
      });
    }
  } 
}

getFileTypeFromUrl = (url: string): string | null => {
  const parts = url.split('.');
  if (parts.length < 2) {
    return null;
  }
  return parts.pop()?.toLowerCase() || null;
};

getFileNameFromUrl = (url: string): string => {
  if (!url) return "";
  const parts = url.split('/');
  const lastSegment = parts.pop();
  if (!lastSegment) return "";
  const fileName = lastSegment.split('?')[0];
  return decodeURIComponent(fileName);
};

handleNavigationPayloadMessage =  async (message: Message) => {
  const passingPramData = message.getData(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  const {dealershipData} = passingPramData;
  if(dealershipData.id) {
    const voided_check = dealershipData.attributes.bank_information.data[0].attributes.voided_check;  
    const w9_form = dealershipData.attributes.w9_forms[0].url
    const type = this.getFileTypeFromUrl(voided_check);
    let fileFormat = 'application/pdf';
    switch (type) {
      case 'png':
        fileFormat = 'image/png';
        break;
      case 'jpg':
        fileFormat = 'image/jpeg';
        break;
    }
    const voiceCheckFileName = this.getFileNameFromUrl(voided_check);
    const w9FormFileName = this.getFileNameFromUrl(w9_form);
    let response = await fetch(voided_check);
    let blob = await response.blob();
    const voidedFile = new File([blob], voiceCheckFileName, { type: fileFormat });
    response = await fetch(w9_form);
    blob = await response.blob();
    const taxFile = new File([blob], w9FormFileName, { type: 'application/pdf' });
    this.setState({
      additionalBoolForApi: true,
      editDealershipId: dealershipData.id,
      dealerDetails: {
        ...this.state.dealerDetails,
        profilePhoto: dealershipData.attributes.dealership_logo,
        dealership_name: dealershipData.attributes.dealership_name,
        dealership_address_id : dealershipData.attributes.addresses.data[0].id,
        dealership_street_address: dealershipData.attributes.addresses.data[0].attributes.address,
        dealership_state: dealershipData.attributes.addresses.data[0].attributes.state,
        dealership_city: dealershipData.attributes.addresses.data[0].attributes.city,
        dealership_zipCode: dealershipData.attributes.addresses.data[0].attributes.zipcode,
        dealership_zipCodeEXT: dealershipData.attributes.addresses.data[0].attributes.zipcode_ext,
        dealership_shipping_address_id: dealershipData.attributes.addresses.data[1].id,
        dealership_shipping_street_address: dealershipData.attributes.addresses.data[1].attributes.address,
        dealership_shipping_state: dealershipData.attributes.addresses.data[1].attributes.state,
        dealership_shipping_city: dealershipData.attributes.addresses.data[1].attributes.city,
        dealership_shipping_zipCode: dealershipData.attributes.addresses.data[1].attributes.zipcode,
        dealership_shipping_zipCodeEXT: dealershipData.attributes.addresses.data[1].attributes.zipcode_ext,
        business_credentials: dealershipData.attributes.business_credentials,
        checkedBoxes: dealershipData.attributes.deal_type,
      },
      thirdFormDetails:{
        bankAccountNumber : dealershipData.attributes.bank_information.data[0].attributes.account_number,
        confBankAccountNumber: dealershipData.attributes.bank_information.data[0].attributes.account_number,
        routingNumber: dealershipData.attributes.bank_information.data[0].attributes.bank_routing_number,
        confRoutingNumber: dealershipData.attributes.bank_information.data[0].attributes.bank_routing_number,
        bankName: dealershipData.attributes.bank_information.data[0].attributes.bank_name,
        name: dealershipData.attributes.bank_information.data[0].attributes.name,
        branch: dealershipData.attributes.bank_information.data[0].attributes.branch_name,
        phoneNumber: dealershipData.attributes.bank_information.data[0].attributes.phone_number,
        jobTitle: dealershipData.attributes.bank_information.data[0].attributes.job_title
      },
      thirdFormAddressDetails:{
        streetAddress: dealershipData.attributes.addresses.data[0].attributes.address,
        address: dealershipData.attributes.addresses.data[0].attributes.address,
        thirdFormZipCode: dealershipData.attributes.addresses.data[0].attributes.zipcode,
        thirdFormZipCodeEXT: dealershipData.attributes.addresses.data[0].attributes.zipcode_ext,
      },
      thirdFormDetailsNew:{
        thirdFormState: dealershipData.attributes.addresses.data[0].attributes.state,
        thirdFormCity: dealershipData.attributes.addresses.data[0].attributes.city,
        thirdFormCities: []
      },
      taxInfoDetails: {
        ...this.state.taxInfoDetails,
        uploadFullSignature: dealershipData.attributes.bank_information.data[0].attributes.full_signature,
        uploadIntitalSignture: dealershipData.attributes.bank_information.data[0].attributes.initial_signature,
        taxInfoFile: taxFile
      },
      voidedCheckFile: voidedFile,
      activeSignatureView: false,
      isEsignFinish: true
    })
  }
}

setInviteUsers = () => {
  const { invitedUserEmail, invitedUserRole, inviteToDealership } =
    this.state;

  this.setState({
    invitedUsers: [
      ...this.state.invitedUsers,
      {
        email: invitedUserEmail,
        dealership_role_id: invitedUserRole,
        dealer_information_ids: inviteToDealership,
      },
    ],
    invitedUserEmail: "",
    invitedUserRole: "3",
    inviteToDealership: this.state.userData?.attributes.soloDealershipId
      ? [this.state.dealershipsData[0]?.id]
      : [],
  });
};

setShowEditUser = (state: boolean) => {
  this.setState({
    showEditUser: state,
  });
};

setCloseInviteUserModal = (state: boolean) => {
  this.setState({
    showInviteUser: state,
    isOpen:true
  })
}

setCloseInviteUserDrawer = (state: boolean) => {
  this.setState({
    isOpen:true,
    showInviteUser: state
  })
}

navigateInviteUserToDashboard =()=>{
  const inviteUserTxtData = new Message( getName( MessageEnum.NavigationMessage ));
  inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
    "Dashboard");
  inviteUserTxtData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
  this.send( inviteUserTxtData );
}
 
  createDealerFormData = () => {
    const { dealerDetails } = this.state;
    let formData = new FormData();
  
    formData.append("dealer_information[is_dealer_group]", dealerDetails.dealerGroup.toString());
    formData.append("dealer_information[dealer_group_name]", dealerDetails.dealerGroupName);
  
    formData.append("dealer_information[dealership_name]", dealerDetails.dealership_name);
    formData.append("dealer_information[business_credentials]", dealerDetails.business_credentials);
    dealerDetails.checkedBoxes.forEach(item => {
      formData.append(`dealer_information[deal_type][]`, item);
    });
    formData.append("dealer_information[addresses_attributes][0][id]", dealerDetails.dealership_address_id);
    formData.append("dealer_information[addresses_attributes][0][address]", dealerDetails.dealership_street_address);
    formData.append("dealer_information[addresses_attributes][0][city]", dealerDetails.dealership_city);
    formData.append("dealer_information[addresses_attributes][0][state]", dealerDetails.dealership_state);
    formData.append("dealer_information[addresses_attributes][0][zipcode]", dealerDetails.dealership_zipCode);
    formData.append("dealer_information[addresses_attributes][0][zipcode_ext]", dealerDetails.dealership_zipCodeEXT);
    formData.append("dealer_information[addresses_attributes][0][address_type]", "default");

    formData.append("dealer_information[addresses_attributes][1][id]", dealerDetails.dealership_shipping_address_id);
    formData.append("dealer_information[addresses_attributes][1][address]", dealerDetails.dealership_shipping_street_address);
    formData.append("dealer_information[addresses_attributes][1][city]", dealerDetails.dealership_shipping_city);
    formData.append("dealer_information[addresses_attributes][1][state]", dealerDetails.dealership_shipping_state);
    formData.append("dealer_information[addresses_attributes][1][zipcode]", dealerDetails.dealership_shipping_zipCode);
    formData.append("dealer_information[addresses_attributes][1][zipcode_ext]", dealerDetails.dealership_shipping_zipCodeEXT);
    formData.append("dealer_information[addresses_attributes][1][address_type]", "default");
  
    if (dealerDetails.filePhoto) formData.append("dealer_information[dealership_logo]", dealerDetails.filePhoto);  
    return formData;
  }
 
  contactInfoFormData = () => {
    const { contactInfoDetails } = this.state;
    let formData = new FormData();
    formData.append("account[full_name]", contactInfoDetails.fullName);
    formData.append("account[email]", contactInfoDetails.email);
    const phoneNumber = '1' + contactInfoDetails.phoneNumber;
    formData.append("account[full_phone_number]", phoneNumber);
    return formData;
  }
 
  bankInfoFormData = () => {
    const { thirdFormAddressDetails, thirdFormDetails, thirdFormDetailsNew, taxInfoDetails, voidedCheckFile } = this.state;
    let formData = new FormData();
  
    formData.append("bank_information[bank_routing_number]", thirdFormDetails.routingNumber.toString());
    formData.append("bank_information[account_number]", thirdFormDetails.bankAccountNumber);
  
    formData.append("bank_information[bank_name]", thirdFormDetails.bankName);
    formData.append("bank_information[confirm_account_number]", thirdFormDetails.confBankAccountNumber);
    formData.append("bank_information[confirm_bank_routing_number]",thirdFormDetails.confRoutingNumber);
    formData.append(`bank_information[branch_name]`, thirdFormDetails.branch);
    formData.append("bank_information[name]", thirdFormDetails.name);
    const phoneNumber = '1' + thirdFormDetails.phoneNumber;
    formData.append("bank_information[phone_number]", phoneNumber);
    formData.append("bank_information[job_title]", thirdFormDetails.jobTitle);
    formData.append("bank_information[address_attributes][address]", thirdFormAddressDetails.streetAddress);
    formData.append("bank_information[address_attributes][city]", thirdFormDetailsNew.thirdFormCity);
  
    formData.append("bank_information[address_attributes][state]", thirdFormDetailsNew.thirdFormState);
    formData.append("bank_information[address_attributes][zipcode]", thirdFormAddressDetails.thirdFormZipCode);
    formData.append("bank_information[address_attributes][zipcode_ext]", thirdFormAddressDetails.thirdFormZipCodeEXT);
    formData.append("bank_information[bankable_type]", "BxBlockProfile::DealerInformation");
    if (this.state.editDealershipId) {formData.append("bank_information[bankable_id]", this.state.editDealershipId)}
    else {formData.append("bank_information[bankable_id]", this.state.dealerInfoId)};
    formData.append("bank_information[full_signature]", taxInfoDetails.uploadFullSignature);
    formData.append("bank_information[initial_signature]", taxInfoDetails.uploadFullSignature);
    formData.append("bank_information[agree_e_sign]", "true");
 
    if(voidedCheckFile) formData.append("bank_information[voided_check]", voidedCheckFile);  
    return formData;
  }
 
  taxInfoFormData = () => {
    const { taxInfoDetails } = this.state;
    let formData = new FormData();
    formData.append("dealer_information_id", this.state.editDealershipId || this.state.dealerInfoId);
    if(taxInfoDetails.taxInfoFile) formData.append("w9_forms[]", taxInfoDetails.taxInfoFile);
    return formData;
  }
 
  handleTabChange = (tab : string) =>{
    this.setState({
      activeTab : tab
    })
  }
 
  createAccountWeb = () => {
  const { dealerInfoId, editDealershipId} = this.state;
  const formData = this.createDealerFormData();
 
  const Webheader = {
    "token" : this.token
  };
 
  const WebrequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.formDataCallId = WebrequestMessage.messageId;
 
  if (this.state.additionalBoolForApi)
  {
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dealershipPutApiEndpoint + `${editDealershipId || dealerInfoId}`
    );
  }
  else{
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.testApiEndpoint  
    );
  }
 
  WebrequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader)
  );
 
  WebrequestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
 
  if (this.state.additionalBoolForApi){
  WebrequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPutMethod
  );
}
else {
  WebrequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );
}
 
  runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
}
 
createAccountWebContactInfo = () => {
  const formData = this.contactInfoFormData();
  const Webheader = {"token" : this.token};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.contactInfoDataCallId = 
  WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.contactApiEndpoint);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
    formData);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPutMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}
 
createAccountBankDetails = () => {
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.bankInfoAPIEndpoint);
  
  const Webheader = {"token" : this.token};
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader));

  const formData = this.bankInfoFormData();
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
    formData);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod);

  this.bankInfoCallId = WebrequestMessage.messageId;
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}
 
createAccountTaxInfo = () => {
  const formData = this.taxInfoFormData();
  const Webheader = {"token" : this.token};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.taxInfoCallId = WebrequestMessage.
  messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.
    taxInfoAPIEndpoint);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
    formData);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}
 
getContactInfo = async () => {
  let tokenn = await getStorageData("authToken");
  const Webheader = {"token" : tokenn};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getContactInfoCallId = 
  WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getContactInfoAPIEndpoint);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}

getRolesData = (): boolean => {
  this.setState({ loading: true });

  const webRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiUserRolesCallId = webRequestMessage.messageId;

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getRolesUrl
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify({
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem("authToken"),
    })
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );
  runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  return true;
};
 
getPdfUrl = async () => {
  let getToken = await getStorageData("authToken"); 
  const Webheader = {"token" : getToken};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getPdfApiCallId = 
  WebrequestMessage.messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.fetchPdf);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
};
 
getTaxFormLink = async () => {
  let tokenn = await getStorageData("authToken");
  const Webheader = {"token" : tokenn};
  const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getTaxInfoLinkCallId = WebrequestMessage.
  messageId;
  WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.taxInfoLinkAPIEndpoint);
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(Webheader));
  WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod);
  runEngine.sendMessage(
    WebrequestMessage.id, 
    WebrequestMessage
  );
}

getDashboardData(): boolean {
  this.setState({loading: true})

  const webHeader = {
    "Content-Type": configJSON.searchApiContentType,
    token: localStorage.getItem("authToken")
  };
  const webRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiUserDataCallId = webRequestMessage.messageId;
  webRequestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getUserUrl
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(webHeader)
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );
  runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  return true;
}

getDealsershipsData = (): boolean => {
  this.setState({ loading: true });

  const { page, search } = this.state;

  const params = new URLSearchParams({
    page: page.toString(),
    search,
  }).toString();

  const webHeader = {
    "Content-Type": configJSON.searchApiContentType,
    token: localStorage.getItem("authToken"),
  };

  const webRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiDealershipsCallId = webRequestMessage.messageId;

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getDealershipsUrl}?${params}`
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(webHeader)
  );

  webRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );
  runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  return true;
};

getSortValue = (sort:string) => {

  if (sort === "A to Z") {
    return "A-Z";
  }

  if (sort === "Z to A") {
    return "Z-A";
  }

  return sort;
};
 
navigateToDashboard = ()=> {
  const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
  navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
  navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

  const payloadMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  payloadMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: "HOME"});
  navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMessage);
  runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
}
 
navigateToCreateDeal = ()=> {
  const createDealdata = new Message(getName(MessageEnum.NavigationMessage));
  createDealdata.addData(getName(MessageEnum.NavigationTargetMessage), "CreateDealMain");
  createDealdata.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(createDealdata);
}

navigateToCreateAnotherDealership = ()=> {
 window.location.reload();
}
 
handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  if (/^[a-zA-Z\s]*$/.test(value)){
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_name: value,
    },
  }), ()=>this.getInitials());
}
};
 
getInitials = ()=>{
  if (this.state.dealerDetails.dealership_name) {
    const nameArray = this.state.dealerDetails.dealership_name.split(' ');
    const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
    this.setState((prevState) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        initials: derivedInitials,
      },
    }));  
  } else {
    this.setState((prevState) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        initials: null,
      },
    }));
  }
}
 
getInitialsProfilePicture = (fullName : string) => {
    const nameArray = fullName.split(' ');
    const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
    this.setState({
      initials: derivedInitials,
    });  
}
 
loadGoogleMapsScript = () => {
  const script = document.createElement('script');
  script.src = configJSON.apiKey;
  script.async = true;
  script.defer = true;
  script.onload = this.initAutocomplete;
  document.head.appendChild(script);
};
 
initAutocomplete = () => {
  this.autocompleteService = new google.maps.places.AutocompleteService();
  this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
};
 
fetchPredictions = (input: string) => {
  if (!input) {
    this.setState({ addressPredictions: [] });
    return;
  }
 
  this.autocompleteService?.getPlacePredictions(
    {
      input,
      types: ['address'],
      componentRestrictions: { country: "us" },
    },
    (predictions, status) => {
      this.test(status, predictions);
    }
  );
};
 
test = (status: google.maps.places.PlacesServiceStatus, predictions: google.maps.places.AutocompletePrediction[] | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
    this.setState({ addressPredictions: predictions });
  }
}
 
handlePlaceSelect = (placeId: string) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  placesService.getDetails(
    { placeId: placeId },
    (place, status) => {
      this.testNew(status, place);
    }
  );
};
 
testNew = (status: google.maps.places.PlacesServiceStatus, place: google.maps.places.PlaceResult | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && place) {
    this.handleCognitivePlaceSelect(place);
  }
}
 
handleCognitive = (matchingState : { value: string; displayValue: string; stateISOCode: string; } | undefined, newStateISOCode : string, city : string) => {
  if (matchingState) {
    this.fetchCities(newStateISOCode, 'cities', () => {
      this.handleCognitiveCallBack(city);
    });
  }
}
 
handleCognitiveCallBack = (city:string) => {
  const matchingCity = this.state.dealerDetails.testCities.find(
    (cityOption) => cityOption.value.toLowerCase() === city.toLowerCase()
  );
 
  this.handleCognitiveMatchingCity(matchingCity)
}
 
handleCognitivePlaceSelect = (place : google.maps.places.PlaceResult) => {
  if (place.address_components) 
  {
    let street_number = '';
    let city = '';
    let state = '';
    let route = '';
    let zipCode = '';
 
    for (const component of place.address_components) 
    {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          street_number = component.long_name;
          break;
        case 'route':
          route = component.long_name;
          break;
        case 'postal_code':
          zipCode = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.short_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
      }
    }
    const streetAddress = `${street_number} ${route}`
    .trim();
    const matchingState = this.state.dealerDetails.test.find(
      (stateOption) => stateOption.stateISOCode.toLowerCase() === state.toLowerCase()
    );
    const newStateISOCode = 
    matchingState ? 
      matchingState.stateISOCode 
      : 
      '';
    this.handleCognitive(matchingState, newStateISOCode, city)
    this.handleCognitiveStateChange(place, streetAddress, matchingState, zipCode, newStateISOCode, city);
        }
}
 
handleCognitiveStateChange = (place: google.maps.places.PlaceResult, streetAddress: string, matchingState: {value: string;displayValue: string;stateISOCode: string;} | undefined, zipCode: string, newStateISOCode: string, city:string) => {
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_address: place.formatted_address || '',
      dealership_street_address: streetAddress,
      dealership_state: matchingState ? matchingState.value : '',
      dealership_zipCode: zipCode,
      stateISOCode: newStateISOCode,
    }
  }), 
  () => {
      const matchingCity = this.state.dealerDetails.testCities.find(
        (cityOption) => cityOption.displayValue.toLowerCase() === city.toLowerCase()
      )
     this.handleCognitiveMatchingCity(matchingCity);
  }
);
}
 
handleCognitiveMatchingCity = (matchingCity: CityType | undefined) => {
  if (matchingCity) {
    this.setState(prevState => {
      const {dealerDetails} = prevState;

      return {
        dealerDetails: {
          ...dealerDetails,
          dealership_city: matchingCity.value
        }
      }
      
    });
  }
}
 
loadGoogleMapsScriptBank = () => {
  const script = document.createElement('script');
  script.defer = true;
  script.async = true;
  script.onload = this.initAutocompleteBank;
  document.head.appendChild(script);
};

loadGoogleMapsScriptShipping = () => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.onload = this.initAutocompleteShipping;
  document.head.appendChild(script);
};
 
initAutocompleteShipping = () => {
  this.autocompleteServiceShipping = new google.maps.places.AutocompleteService();
  this.placesServiceShipping = new google.maps.places.PlacesService(document.createElement('div'));
};
 
initAutocompleteBank = () => {
  this.placesServiceBank = new google.maps.places.PlacesService(document.createElement('div'));
  this.autocompleteServiceBank = new google.maps.places.AutocompleteService();
  
};
 
fetchPredictionsShipping = (input: string) => {
  if (!input) {
    this.setState({ addressPredictions: [] });
    return;
  }
 
  this.autocompleteService?.getPlacePredictions(
    {
      input,
      types: ['address'],
      componentRestrictions: { country: "us" },
    },
    (predictions, status) => {
      this.testShipping(status, predictions);
    }
  );
};
 
fetchPredictionsBank = (input: string) => {
  if (!input) {
    this.setState({ bankAddressPredictions: [] });
    return;
  }
 
  this.autocompleteService?.getPlacePredictions(
    {
      input, types: ['address'],
      componentRestrictions: { country: "us" },
    },
    (predictions, status) => { this.testBank(status, predictions);
    }
  );
};
 
testShipping = (status: google.maps.places.PlacesServiceStatus, predictions: google.maps.places.AutocompletePrediction[] | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
    this.setState({ shippingAddressPredictions: predictions });
  }
}
 
testBank= (status: google.maps.places.PlacesServiceStatus, predictions: google.maps.places.AutocompletePrediction[] | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
    this.setState(
      { 
      bankAddressPredictions: 
      predictions }
    );
  }
}
 
handlePlaceSelectShipping = (placeId: string) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  placesService?.getDetails(
    { placeId: placeId },
    (place, status) => {
      this.testNewShipping(status, place);
    }
  );
};
 
handlePlaceSelectBank = (placeId: string) => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  placesService?.getDetails(
    { placeId: placeId },
    (place, status) => { this.testNewBank(status, place);
    }
  );
};
 
testNewShipping = (status: google.maps.places.PlacesServiceStatus, place: google.maps.places.PlaceResult | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && place) {
    this.handleCognitivePlaceSelectShipping(place);
  }
}
 
testNewBank = (status: google.maps.places.PlacesServiceStatus, place: google.maps.places.PlaceResult | null) => {
  if (status === google.maps.places.PlacesServiceStatus.OK && place) { this.handleCognitivePlaceSelectBank(place);
  }
}
 
handleCognitiveShipping = (matchingState : { value: string; displayValue: string; stateISOCode: string; } | undefined, newStateISOCode : string, city : string) => {
  if (matchingState) {
    this.fetchCities(newStateISOCode, 'shippingCities', () => {
      this.handleCognitiveCallbackShipping(city)
    });
  }
}
 
handleCognitiveBank = (matchingState : { value: string; displayValue: string; stateISOCode: string; } | undefined, newStateISOCode : string, city : string) => {
  if (matchingState) {
    this.fetchCities(newStateISOCode, 's', 
    () => {this.handleCognitiveCallbackBank(city)
    });
  }
}
 
handleCognitiveCallbackShipping = (city:string) => {
  const matchingCity = this.state.dealerDetails.testShippingCities.find(
    (cityOption) => cityOption.value.toLowerCase() === city.toLowerCase()
  );
 
  this.handleCognitiveMatchingCityShipping(matchingCity);
}
 
handleCognitiveCallbackBank = (city:string) => {
  const matchingCity = 
  this.state.thirdFormDetailsNew.thirdFormCities.find(
    (cityOption) => cityOption.value.toLowerCase() === city.toLowerCase()
  );
 
  this.handleCognitiveMatchingCityBank(matchingCity);
}

 
handleCognitivePlaceSelectBank = (place : google.maps.places.PlaceResult) => {
  if (place.address_components) {
    let street_number = '';
    let zipCode = '';
    let city = '';
    let route = '';
    let state = '';
    
    for (const component of place.address_components) {
      const componentType = component.types[0];
 
      switch (componentType) {
        case 'route':
          route = component.long_name;
          break;
        case 'street_number':
          street_number = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.short_name;
          break;
        case 'postal_code':
          zipCode = component.long_name;
          break;
      }
    }
 
    const streetAddress = 
    `${street_number} ${route}`.trim();
 
    const matchingState = 
    this.state.dealerDetails.test.find(
      (stateOption) => stateOption.stateISOCode.toLowerCase() === state.toLowerCase()
    );
    
    const newStateISOCode = 
    matchingState ? matchingState.stateISOCode : '';
    this.handleCognitiveBank(matchingState,newStateISOCode,city)
  
    this.handleCognitiveStateChangeBank(place, 
      streetAddress, 
      matchingState, 
      zipCode, 
      newStateISOCode, 
      city);
  }
}

handleCognitivePlaceSelectShipping = (place : google.maps.places.PlaceResult) => {
  if (place.address_components) {
    let route = '';
    let street_number = '';
    let state = '';
    let city = '';
    let zipCode = '';
 
    for (const component of place.address_components) {
      const componentType = component.types[0];
 
      switch (componentType) {
        case 'route':
          route = component.long_name;
          break;

        case 'street_number':
          street_number = component.long_name;
          break;

        case 'administrative_area_level_1':
          state = component.short_name;
          break;

        case 'locality':
          city = component.long_name;
          break;

        case 'postal_code':
          zipCode = component.long_name;
          break;
      }
    }
 
    const streetAddress = `${street_number} ${route}`.trim();
 
    const matchingState = this.state.dealerDetails.test.find(
      (stateOption) => stateOption.stateISOCode.toLowerCase() === state.toLowerCase()
    );
    
    const newStateISOCode = matchingState ? matchingState.stateISOCode : '';
    this.handleCognitiveShipping(matchingState, newStateISOCode, city)
  
    this.handleCognitiveStateChangeShipping(place, streetAddress, matchingState, zipCode, newStateISOCode, city);
  }
}
 
handleCognitiveStateChangeShipping = (place: google.maps.places.PlaceResult, streetAddress: string, matchingState: {value: string;displayValue: string;stateISOCode: string;} | undefined, zipCode: string, newStateISOCode: string, city:string) => {
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_shipping_address: place.formatted_address || '',
      dealership_shipping_street_address: streetAddress,
      dealership_shipping_state: matchingState ? matchingState.value : '',
      dealership_shipping_zipCode: zipCode,
      stateISOCode: newStateISOCode,
    }
  }), 
  () => {
      const matchingCity = this.state.dealerDetails.testShippingCities.find(
        (cityOption) => cityOption.displayValue.toLowerCase() === city.toLowerCase()
      )
     this.handleCognitiveMatchingCityShipping(matchingCity);
  }
);
}
 
handleCognitiveStateChangeBank = (place: google.maps.places.PlaceResult, streetAddress: string, matchingState: {value: string;displayValue: string;stateISOCode: string;} | undefined, zipCode: string, newStateISOCode: string, city:string) => {
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      stateISOCode: newStateISOCode,
    },
    thirdFormAddressDetails: {
      ...prevState.thirdFormAddressDetails,
      address : place.formatted_address || '',
      streetAddress : streetAddress,
      thirdFormZipCode : zipCode,
    },
    thirdFormDetailsNew: {
      ...prevState.thirdFormDetailsNew,
      thirdFormState : matchingState ? matchingState.value : '',
    }
  }), 
  () => {
      const matchingCity = this.state.thirdFormDetailsNew.thirdFormCities.find((cityOption) => cityOption.displayValue.toLowerCase() === city.toLowerCase()
      )
     this.handleCognitiveMatchingCityBank(matchingCity);
  }
);
}
 
handleCognitiveMatchingCityShipping = (matchingCity: CityType | undefined) => {
  if (matchingCity) {
    this.setState(prevState => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        dealership_shipping_city: matchingCity.value
      }
    }));
  }
}
 
handleCognitiveMatchingCityBank = (matchingCity: CityType | undefined) => {
  if (matchingCity) {
    this.setState(prevState => ({thirdFormDetailsNew: {
        ...prevState.thirdFormDetailsNew, thirdFormCity: matchingCity.value
      }
    }));
  }
}
 
handleDealerGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  if (/^[a-zA-Z\s]*$/.test(value)){
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealerGroupName: value,
    },
  }));
}
};
 
handleAddressChange = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_address: value,
    },
  }));
  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        dealership_shipping_address: value
      }
    }));
  }
};
 
handleStreetAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_street_address: value,
    },
  }));
  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        dealership_shipping_street_address: value
      }
    }));
  }
};
 
handleCityChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_city: value as string,
    },
  }));
  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        dealership_shipping_city: value as string
      }
    }));
  }
};
 
handleStateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  const { value } = event.target;
  const selectedState = this.state.dealerDetails.test.find(state => state.value === value);
  
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_state: value as string,
      stateISOCode: selectedState ? selectedState.stateISOCode : '',
      dealership_city: '', 
    },
  }), () => {
    if (selectedState) {
      this.fetchCities(selectedState.stateISOCode, 'cities');
    }
  });
 
  if (this.state.isSameAddress) {
    this.setState((prevState) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        dealership_shipping_state: value as string,
        dealership_shipping_city: '', 
      }
    }));
  }
};
 
handleThirdFormStateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    thirdFormDetailsNew: {
      ...prevState.thirdFormDetailsNew,
      thirdFormState: value as string
    },
  })
)
};
 
handleThirdFormCityChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    thirdFormDetailsNew: {
      ...prevState.thirdFormDetailsNew,
      thirdFormCity: value as string
    },
  })
)
};
 
fetchCities = (selectedIsoCode: string, id: string, callback?: () => void) => {
  const cities = City.getCitiesOfState('US', selectedIsoCode).map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));
  if(id==="cities")
    {
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      testCities: cities,
    },
  }));
}
else if (id==="shippingCities"){
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      testShippingCities: cities,
    },
  }));
}
else {
  this.setState((prevState) => ({
    thirdFormDetailsNew: {
      ...prevState.thirdFormDetailsNew,
      thirdFormCities: cities,
    },
  }));
}
};
 
 
handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      this.setState(prevState => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          dealership_zipCode: value
        }
      }));
    }
    if (this.state.isSameAddress) {
      this.setState((prevState) => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          dealership_shipping_zipCode: value
        }
      }));
    }
};
 
handleZipCodeEXTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      this.setState(prevState => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          dealership_zipCodeEXT: value
        }
      }));
    }
    if (this.state.isSameAddress) {
      this.setState((prevState) => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          dealership_shipping_zipCodeEXT: value
        }
      }));
    }
};
 
handleDealershipShippingAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_shipping_address: value,
    },
  }));
};
 
handleDealershipShippingStreetAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_shipping_street_address: value,
    },
  }));
};
 
handleDealershipShippingCityChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_shipping_city: value as string,
    },
  }));
};
 
handleDealershipShippingStateChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_shipping_state: value as string,
    },
  }));
};
 
handleDealershipShippingZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_shipping_zipCode: value,
    },
  }));
}
};
 
handleDealershipShippingZipCodeEXTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    dealerDetails: {
      ...prevState.dealerDetails,
      dealership_shipping_zipCodeEXT: value,
    },
  }));
}
};
 
  handleBusinessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^[a-zA-Z0-9\s]*$/.test(value)) {
      this.setState((prevState) => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          business_credentials: value,
        },
      }));
    }
  };
 
handleBankAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    thirdFormAddressDetails: {
      ...prevState.thirdFormAddressDetails,
      address: value,
    },
  }));
};
 
handleBankStreetAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  this.setState((prevState) => ({
    thirdFormAddressDetails: {
      ...prevState.thirdFormAddressDetails,
      streetAddress: value,
    },
  }));
};
 
handleBankZipCodeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    thirdFormAddressDetails: {
      ...prevState.thirdFormAddressDetails,
      thirdFormZipCode: value,
    },
  }));
}
};
 
handleBankZipCodeEXTChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { value } = event.target;
  if (/^\d{0,6}$/.test(value)) {
  this.setState((prevState) => ({
    thirdFormAddressDetails: {
      ...prevState.thirdFormAddressDetails,
      thirdFormZipCodeEXT: value,
    },
  }));
}
};
 
  handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files[0]) {
        const file = files[0];
        const maxSize = 1*1024*1024;
 
        if (file.size > maxSize) {
          this.setState({photoErrorMessage : "File size should not exceed 1 MB."});
          return;
        }
        this.setState(prevState => ({
          dealerDetails: {
            ...prevState.dealerDetails,
            profilePhoto: URL.createObjectURL(file),
            filePhoto: file,
          },
          photoErrorMessage : ""
        }));
        event.target.value = '';
      }
    };
  
 
  handleDeletePicture = () => {
    this.setState(prevState => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        profilePhoto: null,
        filePhoto: null
      },
    }));
  };
 
  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      dealerDetails: {
        ...prevState.dealerDetails,
        dealerGroup: value === "true"
      }
    }));
  };
 
  handleCheckboxChangeNew  = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({checkboxesTouched : true});
      this.setState((prevState) => {
        const { checkedBoxes } = prevState.dealerDetails;
  
        return {
          dealerDetails: {
            ...prevState.dealerDetails,
            checkedBoxes: event.target.checked
              ? [...checkedBoxes, "New Cars"]
              : checkedBoxes.filter(item => item !== "New Cars"),
          }
        };
      });    
  };
 
  handleCheckboxChangeUsed  = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({checkboxesTouched : true});
    this.setState((prevState) => {
      const { checkedBoxes } = prevState.dealerDetails;
 
      return {
        dealerDetails: {
          ...prevState.dealerDetails,
          checkedBoxes: event.target.checked
            ? [...checkedBoxes, "Used Cars"]
            : checkedBoxes.filter(item => item !== "Used Cars")
        }
      };
    });    
  };

  changeStep = (stepNumber: number) => {
    this.setState({ step: stepNumber });
  };
  
  logout = () => {
    removeStorageData("authToken");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
 
  nextStep = () => {
    switch (this.state.step) {
      case 1:
        if (this.validateDealerInfoFields()) {
          this.createAccountWeb();
        }  
        break; 
      case 2:
          this.createAccountWebContactInfo();
        break;
      case 3:
        if (this.validateBankInfoFields()) {
          this.createAccountBankDetails();
          break;
        }
      case 4:
        this.createAccountTaxInfo();
        break;
    };
  }

  handleSkip = () => {
    const { step, skippedSteps, doneSteps } = this.state;
    if (!doneSteps.includes(step)){
      this.setState({      
        skippedSteps: [...skippedSteps, step],
      })
    }
    if (step === 4) {
      this.setState({
        isOpen: true,
        step: step + 1,
      });
    } else {
      this.setState({
        step: step + 1,
      });
    }
  }
 
  handleIsSameAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
 
    this.setState({
      isSameAddress: isChecked
    });
  
    if (isChecked) {
      this.setState((prevState) => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          dealership_shipping_address: this.state.dealerDetails.dealership_address,
          dealership_shipping_city: this.state.dealerDetails.dealership_city,
          dealership_shipping_state: this.state.dealerDetails.dealership_state,
          dealership_shipping_street_address: this.state.dealerDetails.dealership_street_address,
          dealership_shipping_zipCode: this.state.dealerDetails.dealership_zipCode,
          dealership_shipping_zipCodeEXT: this.state.dealerDetails.dealership_zipCodeEXT,
        }
      }));
    } else {
      this.setState((prevState) => ({
        dealerDetails: {
          ...prevState.dealerDetails,
          dealership_shipping_address: "",
          dealership_shipping_city: "",
          dealership_shipping_state: "",
          dealership_shipping_street_address: "",
          dealership_shipping_zipCode: "",
          dealership_shipping_zipCodeEXT: "",
        }
      }));
    }
  }
 
  validateDealerInfoFields = (): boolean => {
    const { dealerDetails } = this.state;
    
    if (!dealerDetails) {
      return false;
    }
  
    const requiredFields: (keyof ICreateDealerDetails)[] = [
      'dealership_name',
      'dealership_city',
      'dealership_state',
      'dealership_street_address',
      'dealership_zipCode',
      'dealership_shipping_city',
      'dealership_shipping_state',
      'dealership_shipping_street_address',
      'dealership_shipping_zipCode',
      'business_credentials'
    ];
  
    const allFieldsFilled = requiredFields.every(field => {
      const value = dealerDetails[field] as string;
      return value !== undefined && value.trim() !== '';
    });
  
    const checkboxesFilled = dealerDetails.checkedBoxes.length > 0;
    
    const zipCodeFields: (keyof ICreateDealerDetails)[] = [
      'dealership_zipCode',
      'dealership_shipping_zipCode'
    ];
  
    const zipCodeExtFields: (keyof ICreateDealerDetails)[] = [
      'dealership_zipCodeEXT',
      'dealership_shipping_zipCodeEXT'
    ];
  
    const validZipCodes = zipCodeFields.every(field => {
      const value = dealerDetails[field] as string;
      return value.length === 5;
    });
  
    const validZipCodeExts = zipCodeExtFields.every(field => {
      const value = dealerDetails[field] as string;
      return value.length === 0 || (value.length == 4);
    });
 
    const businessCredentialsValid = Boolean(dealerDetails.business_credentials.length>14 && dealerDetails.business_credentials.length<26)
    
    const isDealerGroupValid = !dealerDetails.dealerGroup || 
      (dealerDetails.dealerGroup && Boolean(dealerDetails.dealerGroupName) && dealerDetails.dealerGroupName.trim() !== '');
  
    return allFieldsFilled && checkboxesFilled && validZipCodes && validZipCodeExts && isDealerGroupValid && businessCredentialsValid;
  };
  
  validateBankInfoFields = (): boolean => {
    const { thirdFormAddressDetails, thirdFormDetailsNew, thirdFormDetails, voidedCheckFile } = this.state;
    
    if (thirdFormAddressDetails.streetAddress == undefined || thirdFormDetailsNew.thirdFormCity == undefined || thirdFormDetails.bankAccountNumber == undefined)  {
      return false;
    }
  
    const addressFieldsFilled = 
      thirdFormAddressDetails.streetAddress.trim() !== '' &&
      thirdFormDetailsNew.thirdFormCity.trim() !== '' &&
      thirdFormDetailsNew.thirdFormState.trim() !== '' &&
      thirdFormAddressDetails.thirdFormZipCode.trim() !== '';
  
    const zipCodeValid = thirdFormAddressDetails.thirdFormZipCode.length === 5;
  
    const zipCodeExtValid = 
      thirdFormAddressDetails.thirdFormZipCodeEXT.length === 0 || 
      (thirdFormAddressDetails.thirdFormZipCodeEXT.length === 4);
  
    return addressFieldsFilled && 
           zipCodeValid && 
           zipCodeExtValid &&
           Boolean(voidedCheckFile);
  };
 
  returnBusinessCredentialsErrors = () => {
    return (
      this.state.dealerDetails.business_credentials.length >= 0 && this.state.dealerDetails.business_credentials.length < 15 && this.state.businessCredentialsTouched
    )
  }
 
  returnBusinessCredentialsHelperText = () => {
    let helperText = '';
    const credentials = this.state.dealerDetails.business_credentials;
    const isTouched = this.state.businessCredentialsTouched;
 
    if (isTouched) {
        if (credentials.length === 0) {
            helperText = "Business credentials required";
        } else if (credentials.length > 0 && credentials.length < 15) {
            helperText = 'Business credentials must be between 15-25 characters';
        }
    }
 
    return helperText;
}
 
  setBusinessCredentialsTouched = () => {
    this.setState({businessCredentialsTouched : true})
  }
 
  returnStreetAddressErrors = () => {
    return (
      this.state.dealerDetails.dealership_street_address.length == 0 && this.state.streetAddressTouched
    )
  }
 
  returnStreetAddressHelperText = () => {
    return (
      this.state.dealerDetails.dealership_street_address.length == 0 && this.state.streetAddressTouched ? `Street address required` : ''
    )
  }
 
  setStreetAddressTouched = () => {
    this.setState({streetAddressTouched : true})
  }
 
  returnShippingStreetAddressErrors = () => {
    return (
      this.state.dealerDetails.dealership_shipping_street_address.length == 0 && this.state.shippingstreetAddressTouched
    )
  }
 
  returnShippingStreetAddressHelperText = () => {
    return (
      this.state.dealerDetails.dealership_shipping_street_address.length == 0 && this.state.shippingstreetAddressTouched ? `Street address required` : ''
    )
  }
 
  setShippingStreetAddressTouched = () => {
    this.setState({shippingstreetAddressTouched : true})
  }
 
  returnThirdFormStreetAddressErrors = () => {
    return (
      this.state.thirdFormAddressDetails.streetAddress.length == 0 && this.state.thirdFormstreetAddressTouched
    )
  }
 
  returnThirdFormStreetAddressHelperText = () => {
    return (
      this.state.thirdFormAddressDetails.streetAddress.length == 0 && this.state.thirdFormstreetAddressTouched ? `Street address required` : ''
    )
  }
 
  setThirdFormStreetAddressTouched = () => {
    this.setState({thirdFormstreetAddressTouched : true})
  }
  
  returnThirdFormZipCodeErrors = () => {
    const { thirdFormZipCode } = this.state.thirdFormAddressDetails;
    const { thirdFormzipCodeTouched } = this.state;
    return thirdFormZipCode.length < 5 && thirdFormzipCodeTouched ;
  }
 
  returnThirdFormZipCodeHelperText = () => {
    const { thirdFormZipCode } = this.state.thirdFormAddressDetails;
    const { thirdFormzipCodeTouched } = this.state;
    return thirdFormZipCode.length < 5 && thirdFormzipCodeTouched  ? 'Must be 5 digits' : '';
  }
 
  setThirdFormZipCodeTouched = () => {
    this.setState({thirdFormzipCodeTouched : true})
  }
 
  returnStateErrors = () => {
    return (
      this.state.dealerDetails.dealership_state.length == 0 && this.state.stateTouched
    )
  }
 
  returnStateHelperText = () => {
    return (
      this.state.dealerDetails.dealership_state.length == 0 && this.state.stateTouched ? 'State required' : ''
    )
  }
 
  setStateTouched = () => {
    this.setState({stateTouched : true})
  }
 
  returnThirdFormStateErrors = () => {
    return (
      this.state.thirdFormDetailsNew.thirdFormState.length == 0 && this.state.thirdFormstateTouched
    )
  }
 
  returnThirdFormStateHelperText = () => {
    return (
      this.state.thirdFormDetailsNew.thirdFormState.length == 0 && this.state.thirdFormstateTouched ? 'State required' : ''
    )
  }
 
  setThirdFormStateTouched = () => {
    this.setState({thirdFormstateTouched : true})
  }
 
  returnShippingStateErrors = () => {
    return (
      this.state.dealerDetails.dealership_shipping_state.length == 0 && this.state.shippingstateTouched
    )
  }
 
  returnShippingStateHelperText = () => {
    return (
      this.state.dealerDetails.dealership_shipping_state.length == 0 && this.state.shippingstateTouched ? 'State required' : ''
    )
  }
 
  setShippingStateTouched = () => {
    this.setState({shippingstateTouched : true})
  }
 
  returnShippingCityErrors = () => {
    return (
      this.state.dealerDetails.dealership_shipping_city.length == 0 && this.state.shippingcityTouched
    )
  }
 
  returnShippingCityHelperText = () => {
    return (
      this.state.dealerDetails.dealership_shipping_city.length == 0 && this.state.shippingcityTouched ? 'City required' : ''
    )
  }
 
  setShippingCityTouched = () => {
    this.setState({shippingcityTouched : true})
  }
 
  returnCityErrors = () => {
    return (
      this.state.dealerDetails.dealership_city.length == 0 && this.state.cityTouched
    )
  }
 
  returnCityHelperText = () => {
    return (
      this.state.dealerDetails.dealership_city.length == 0 && this.state.cityTouched ? 'City required' : ''
    )
  }
 
  setCityTouched = () => {
    this.setState({cityTouched : true})
  }
 
  returnThirdFormCityErrors = () => {
    return (
      this.state.thirdFormDetailsNew.thirdFormCity.length == 0 && this.state.thirdFormcityTouched
    )
  }
 
  returnThirdFormCityHelperText = () => {
    return (
      this.state.thirdFormDetailsNew.thirdFormCity.length == 0 && this.state.thirdFormcityTouched ? 'City required' : ''
    )
  }
 
  setThirdFormCityTouched = () => {
    this.setState({thirdFormcityTouched : true})
  }
 
  returnDealershipGroupNameErrors = () => {
    return (
      this.state.dealerDetails.dealerGroupName.length <= 0 && this.state.dealershipGroupNameTouched
    )
  }
 
  returnDealershipGroupNameHelperText = () => {
    return (
      (this.state.dealerDetails.dealerGroupName.length <= 0 && this.state.dealershipGroupNameTouched ) ? `Dealer group name required` : ''    
    )
  }
 
  setDealershipGroupNameTouched = () => {
    this.setState({dealershipGroupNameTouched : true})
  }
 
  returnDealershipNameErrors = () => {
    return (
      this.state.dealerDetails.dealership_name.length <= 0 && this.state.dealershipNameTouched
    )
  }
 
  returnDealershipNameHelperText = () => {
    return (
      (this.state.dealerDetails.dealership_name.length <= 0 && this.state.dealershipNameTouched ) ? `Dealership name required` : ''    
    )
  }
 
  setDealershipNameTouched = () => {
    this.setState({dealershipNameTouched : true})
  }
 
  returnZipCodeEXTErrors = () => {
    return (
    this.state.dealerDetails.dealership_zipCodeEXT.length > 0 && this.state.dealerDetails.dealership_zipCodeEXT.length < 4
    )
  }
 
  returnZipCodeEXTHelperText = () => {
    return (
      this.state.dealerDetails.dealership_zipCodeEXT.length > 0 && this.state.dealerDetails.dealership_zipCodeEXT.length < 4
    )
  }
 
  returnZipCodeErrors = () => {
    const { dealership_zipCode } = this.state.dealerDetails;
    const { zipCodeTouched } = this.state;
    return zipCodeTouched && (dealership_zipCode.length < 5);
  }
 
  returnZipCodeHelperText = () => {
    const { dealership_zipCode } = this.state.dealerDetails;
    const { zipCodeTouched } = this.state;
    return zipCodeTouched && dealership_zipCode.length < 5 ? 'Must be 5 digits' : '';
  }
 
  setZipCodeTouched = () => {
    this.setState({zipCodeTouched : true})
  }
 
  returnShippingZipCodeErrors = () => {
    const { dealership_shipping_zipCode } = this.state.dealerDetails;
    const { shippingzipCodeTouched } = this.state;
    return shippingzipCodeTouched && (dealership_shipping_zipCode.length < 5);
  }
  
  returnShippingZipCodeHelperText = () => {
    const { dealership_shipping_zipCode } = this.state.dealerDetails;
    const { shippingzipCodeTouched } = this.state;
    return shippingzipCodeTouched && (dealership_shipping_zipCode.length < 5) ? 'Must be 5 digits' : '';
  }
  
  setShippingZipCodeTouched = () => {
    this.setState({ shippingzipCodeTouched: true });
  }
  
 
  returnShippingZipCodeEXTErrors = () => {
    return (
      this.state.dealerDetails.dealership_shipping_zipCodeEXT.length > 0 && this.state.dealerDetails.dealership_shipping_zipCodeEXT.length < 4
    )
  }
 
  returnShippingZipCodeEXTHelperText = () => {
    return (
      this.state.dealerDetails.dealership_shipping_zipCodeEXT.length > 0 && this.state.dealerDetails.dealership_shipping_zipCodeEXT.length < 4
    )
  }

  returnThirdFormBranchHelperText = (branch: string, touched: boolean | undefined) => {
    if (!touched) return '';
    if (branch.length === 0) return `Branch name can't be empty`;
    if (branch.length > 100) return 'Branch name must be within 0-100 characters';
    if (/(?:^\s)|(?:\s$)/.test(branch)) return 'Branch name cannot start or end with a space';
    return '';
  }
 
  returnThirdFormBranchErrors = (branch : string, touched : boolean | undefined) => {
    if (!touched) return false;
    const cog = (/(?:^\s)|(?:\s$)/.test(branch));
    return branch.length === 0 || branch.length > 100 || cog;
  }

  returnThirdFormBankAccountNumberHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankAccountNumber && errors.bankAccountNumber
    )
  }
 
  returnThirdFormBankAccountNumberErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankAccountNumber && !!errors.bankAccountNumber
    )
  }

  returnThirdFormConfBankAccountNumberHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confBankAccountNumber && errors.confBankAccountNumber
    )
  }

  returnThirdFormConfBankAccountNumberErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confBankAccountNumber && !!errors.confBankAccountNumber
    )
  }

  returnThirdFormRoutingNumberHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.routingNumber && errors.routingNumber
    )
  }
  
  returnThirdFormRoutingNumberErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.routingNumber && !!errors.routingNumber
    )
  }

  returnThirdFormConfRoutingHelpertext = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confRoutingNumber && errors.confRoutingNumber
    )
  }
  
  returnThirdFormConfRoutingErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.confRoutingNumber && !!errors.confRoutingNumber
    )
  }

  returnThirdFormBankNameHelperText = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankName && errors.bankName    
    )
  }

  returnThirdFormBankNameErrors = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.bankName && !!errors.bankName
    )
  }
 
  returnThirdFormZipCodeEXTErrors = () => {
    return (
      this.state.thirdFormAddressDetails.thirdFormZipCodeEXT.length > 0 && this.state.thirdFormAddressDetails.thirdFormZipCodeEXT.length < 4
    )
  }
 
  returnThirdFormZipCodeEXTHelperText = () => {
    return (
      this.state.thirdFormAddressDetails.thirdFormZipCodeEXT.length > 0 && this.state.thirdFormAddressDetails.thirdFormZipCodeEXT.length < 4 ? 'Must be 4 digits' : ''
    )
  }
  returnThirdFormName = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.name && !!errors.name
    )
  }
  returnThirdFormPhoneNumber = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.phoneNumber && !!errors.phoneNumber
    )
  }
  returnThirdFormJobTitle = (touched : FormikTouched<FormikValues>, errors: FormikErrors<FormikValues>) => {
    return (
      touched.jobTitle && !!errors.jobTitle
    )
  }
 
  thirdFormIsValidFunc = (isValid: boolean) => {
    if ((isValid)) return true;
  }
 
  handleRegex = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, handleChange: {(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void}) => {
      const value = event.target.value
      if (/^\d*$/.test(value)) {
      handleChange(event)
  }
  }
 
  handleRegexAlpha = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, handleChange: { (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void }) => {
    const value = event.target.value.trim();
    if (/^[a-zA-Z\s]*$/.test(value)) {
      handleChange(event)
    }
  }
 
  handleRegexAlphaJobTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, handleChange: { (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void }) => {
    const value = event.target.value.trim();
    if (/^[a-zA-Z\s]*$/.test(value)) {
      handleChange(event)
    }
  }

  onDrop = (acceptedFiles: File[]): void => {
    if (acceptedFiles && acceptedFiles.length>0){
      this.setState((prevState) => ({
        taxInfoDetails: {
          ...prevState.taxInfoDetails,
          taxInfoFile: acceptedFiles[0],
        },
      }));  
    } 
       
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = () => {
      const result = reader.result as string;
      this.setState({
        taxInfoDetails: {
          ...this.state.taxInfoDetails,
          taxInfoFormLink: result,
        },
      });
    }
  }

  onDropNew = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length>0){
      this.setState((prevState) => ({
        taxInfoDetails: {
          ...prevState.taxInfoDetails,
          taxInfoFile: acceptedFiles[0],
        },
      }));  
    } 
  }

  onDropInitialSignature = (acceptedFiles: File[]): void => {
    if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
 
        reader.readAsDataURL(file);
        reader.onload = () => {
            const result = reader.result as string;
            this.setState((prevState) => ({
                taxInfoDetails: {
                    ...prevState.taxInfoDetails,
                    initialSignatureUploadView: file,
                    uploadIntitalSignture: result,
                },
            }));
        };
    }
};
 
  onDropFullSignature = (acceptedFiles: File[]): void => {
    if (acceptedFiles && acceptedFiles.length > 0) {
        this.setState((prevState) => ({
            taxInfoDetails: {
                ...prevState.taxInfoDetails,
                fullSignatureUploadView: acceptedFiles[0],
            },
        }));
 
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => {
            const result = reader.result as string;
 
            this.setState((prevState) => ({
                taxInfoDetails: {
                    ...prevState.taxInfoDetails,
                    uploadFullSignature: result,
                },
            }));
        };
    }
  };

  removeShowFullSignatureFile = (): void => {
    this.setState((prevState) => ({
        taxInfoDetails: {
            ...prevState.taxInfoDetails,
            fullSignatureUploadView: null,
            initialSignatureUploadView:null,
            uploadFullSignature: "",
            uploadIntitalSignture:"",
            drawSignature:""
        },
    }));
  };
 
removeFullSignatureFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
  event.stopPropagation();
  
  this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          fullSignatureUploadView: null,
          uploadFullSignature: "",
      },
  }));
};

handleopenPdfPopup = (scrollType:any) => () => {
  this.setState((prevState) => ({
    taxInfoDetails: {
      ...prevState.taxInfoDetails,
      isContractPdfView: true
    }
  }));
};
 
removeInitialSignatureFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
  if (event) {
      event.stopPropagation();
  }
  this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          initialSignatureUploadView: null,
          uploadIntitalSignture: "",
      },
  }));
}; 
 
  handleContractPDFViewClose = () => {
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        isContractPdfView: false,
        isPdfCheck:false
      }
    }));
  };

  handleSignatureClose = () =>{
    this.setState((prevState) => ({
      activeSignatureView: false,
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          isSignCheck: false,
      },
      activeTab:"tab1"
  }));
  }
 
  handleSignatureOpen = () => {
    this.setState({
      activeSignatureView: true
    });
    this.handleContractPDFViewClose();
  }
 
  handleIsPdfBtn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          isPdfCheck: isChecked,
      },
    }));
  }
 
  onDropVoidedCheck = (acceptedFiles: File[]): void => {
    if (acceptedFiles && acceptedFiles.length>0){
      this.setState(({
        voidedCheckFile: acceptedFiles[0]
      }));
    } 
  }
 
  handleIsSigneturCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      taxInfoDetails: {
          ...prevState.taxInfoDetails,
          isSignCheck: isChecked,
      },
    }));
  }

  removeFileVoidedCheck = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>):void => {
    if (event) {
      event.stopPropagation();
    }
    this.setState(({
        voidedCheckFile: null,
    }));
  } 
 
  removeFile = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>):void => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState) => ({
      taxInfoDetails: {
        ...prevState.taxInfoDetails,
        taxInfoFile: null,
      },
    }));
  }
 
  reduceCognitive = () => {
    this.initAutocompleteShipping();
        this.fetchPredictionsShipping("New york");
        this.handlePlaceSelectShipping("car");
        this.testShipping(google.maps.places.PlacesServiceStatus.OK, [])
        this.handleCognitiveShipping({value : "as", displayValue: "as", stateISOCode: "abc"}, "newStateISOCode", "city")
        this.handleCognitiveCallbackShipping("as");
        this.fetchCities("abc", "shippingCities", )
        this.handleCognitiveMatchingCityShipping({value: "as", displayValue:"as"})
        this.initAutocompleteBank();
        this.fetchPredictionsBank("New york");
        this.fetchPredictionsBank("");
        this.handlePlaceSelectBank("car");
        this.testBank(google.maps.places.PlacesServiceStatus.OK, [])
        this.handleCognitiveBank({value : "as", displayValue: "as", stateISOCode: "abc"}, "newStateISOCode", "city")
        this.handleCognitiveCallbackBank("as");
  }
  
  validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required(`Full Name can't be empty`)
      .min(3, 'Full Name must be at least 3 characters'),
    email: Yup.string()
      .email('Invalid email format')
      .required(`Email can't be empty`),
    phoneNumber: Yup.string()
      .required(`Phone Number can't be empty`)
      .matches(/^\d+$/, "Phone number can only contain digits")
      .matches(/^\d+$/, 'Number must be digits only')
      .length(10, 'Phone Number must have exactly 10 digits'),
  });
 
  validationSchemaThirdForm = Yup.object().shape({
    confBankAccountNumber: Yup.string()
    .required('Confirm Bank account number must match')
    .oneOf([Yup.ref('bankAccountNumber')], 'Confirm Bank account number must match')
    .min(8, 'Confirm Bank account number must match')
    .max(12, 'Confirm Bank account number must match'),
    bankAccountNumber: Yup.string()
    .required('Account number must be 8 -12 digits. Please enter a valid bank account number.')
    .matches(/^\d+$/, 'Bank account number must contain only digits')
    .min(8, 'Account number must be 8 -12 digits ,Please enter a valid bank account number.')
    .max(12, 'Account number must be 8 -12 digits ,Please enter a valid bank account number.'),
    confRoutingNumber: Yup.string()
    .required('Confirm Routing number must match')
    .oneOf([Yup.ref('routingNumber')], 'Confirm Routing number must match'),
    routingNumber: Yup.string()
    .required(`Routing number can't be empty`)
    .min(9, '9 digits required')
    .max(9, '9 digits required'),
    branch: Yup.string()
    .matches(/^\S(.*\S)?$/, 'Spaces are only allowed between characters, not at the start or end')
    .required(`Branch can't be empty`)
    .max(100, 'Max 100 letters allowed'),
    bankName: Yup.string()
    .matches(/^\S(.*\S)?$/, 'Spaces are only allowed between characters, not at the start or end')
    .required(`Bank name can't be empty`)
    .max(100, 'Max 100 letters allowed'),
    phoneNumber: Yup.string()
    .required(`Phone Number can't be empty`)
    .min(10, '10 digits required')
    .max(10, '10 digits required'),
    name: Yup.string()
    .required(`Name can't be empty`)
    .max(100, 'Max 100 letters allowed'),
    jobTitle: Yup.string()
    .required(`Job Title can't be empty`)
    .max(100, 'Max 100 letters allowed'),
  })
 
  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };
 
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  cognitiveComponentDidUpdate = (prevState: Readonly<S>) => {
    if (this.state.showInviteUser !== prevState.showInviteUser && this.state.showInviteUser) {
      this.setState({ isOpen: false });
    }
  
    if (this.state.userData?.attributes.soloDealershipId !== prevState.userData?.attributes.soloDealershipId) {
      if (this.state.userData) {
        this.setState({
          inviteToDealership: this.state.userData.attributes.soloDealershipId
            ? [`${this.state.userData.attributes.soloDealershipId}`]
            : [],
        });
      }
    }
  };
 
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void { 
    this.updateFunc(prevState);
    this.updateBankInfoFunc(prevState);
    this.cognitiveComponentDidUpdate(prevState);
 
    if (this.state.dealerInfoId !== prevState.dealerInfoId) {
      this.getContactInfo();
    }
    if (this.state.dealerDetails.dealership_state !== prevState.dealerDetails.dealership_state) {
      let selectedStateISOCode = this.state.dealerDetails.test.find((state) => state.value === this.state.dealerDetails.dealership_state)?.stateISOCode;
  
      if (!selectedStateISOCode) {
        selectedStateISOCode=''
      }
      this.fetchCities(selectedStateISOCode, "cities");
    }
    if (this.state.dealerDetails.dealership_shipping_state !== prevState.dealerDetails.dealership_shipping_state) {
      let selectedStateISOCode = this.state.dealerDetails.test.find((state) => state.value === this.state.dealerDetails.dealership_shipping_state)?.stateISOCode;
  
      if (!selectedStateISOCode) {
        selectedStateISOCode=''
      }
        this.fetchCities(selectedStateISOCode, "shippingCities");
      
    }
    if (this.state.thirdFormDetailsNew.thirdFormState !== prevState.thirdFormDetailsNew.thirdFormState) {
      let selectedStateISOCode = this.state.dealerDetails.test.find((state) => state.value === this.state.thirdFormDetailsNew.thirdFormState)?.stateISOCode;
  
      if (!selectedStateISOCode) {
        selectedStateISOCode=''
      }
        this.fetchCities(selectedStateISOCode, "thirdFormCities");
      
    }
    if (this.state.taxInfoDetails.taxInfoFile != prevState.taxInfoDetails.taxInfoFile) this.handleFourthFormFilled();
}
 
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (
        item.is_required &&
        !(item.value as string).trim() &&
        item.is_enable
      ) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } 
  };
 
  updateFunc = (prevState: S) => {
    if (JSON.stringify(prevState.dealerDetails) !== JSON.stringify(this.state.dealerDetails)) {
      const isFormValid = this.validateDealerInfoFields();
      if (isFormValid !== this.state.isFormFilled) {
        this.setState({ isFormFilled: isFormValid });
      }
    }
  }
 
  updateBankInfoFunc = (prevState: S) => {
    if (
      JSON.stringify(prevState.thirdFormAddressDetails) !== JSON.stringify(this.state.thirdFormAddressDetails) ||
      JSON.stringify(prevState.thirdFormDetailsNew) !== JSON.stringify(this.state.thirdFormDetailsNew) ||
      JSON.stringify(prevState.thirdFormDetails) !== JSON.stringify(this.state.thirdFormDetails) ||
      prevState.voidedCheckFile !== this.state.voidedCheckFile) {
      const isBankFormValid = this.validateBankInfoFields();
      this.setState({ isThirdFormFilled: isBankFormValid });
    }
  }
 
  handleFormChange = (values : ContactInfoDetails) => {
    this.setState({
      contactInfoDetails: values
    });
  }
 
  handleSecondFormFilled = (isValid : boolean) => {
    this.setState({ isSecondFormFilled: isValid});
  }
 
  handleThirdFormFilled = (isValid : boolean) => {
    if (isValid !== this.state.isThirdFormValid) this.setState({ isThirdFormValid: isValid});
  }
 
  handleFourthFormFilled = () => {
    if (this.state.taxInfoDetails.taxInfoFile != null) 
      this.setState({isFourthFormFilled : true})
  else this.setState({isFourthFormFilled : false})
}
 
  handleThirdFormChange = (values: ThirdFormDetails) => {
    this.setState({thirdFormDetails:values})
    this.forceUpdate();
  }
 
  toggleExpand = () => {
    this.setState(prevState => ({isExpanded : !prevState.isExpanded}))
  }
 
  toggleExpandBank = () => {
    this.setState(prevState => ({isExpandedBank : !prevState.isExpandedBank}))
  }
 
  toggleExpandBankAddress= () => {
    this.setState(prevState => ({isExpandedBankAddress : !prevState.isExpandedBankAddress}))
  }
 
  toggleExpandBankAuth= () => {
    this.setState(prevState => ({isExpandedBankAuth : !prevState.isExpandedBankAuth}))
  }
 
  isNextButtonEnabled = () => {
    switch (this.state.step) {
      case 1:
        return this.state.isFormFilled;
      case 2:
        return this.state.isSecondFormFilled;
      case 3:
        return this.state.isThirdFormValid && this.state.isThirdFormFilled && this.state.taxInfoDetails.uploadFullSignature.length>0;
      case 4:
        const isLastFormSubmittable = [1, 2, 3].every(step => this.state.doneSteps.includes(step));
        return this.state.editDealershipId || (this.state.isFourthFormFilled && isLastFormSubmittable)
      default:
        return false;
    }
  } 
 
  // Customizable Area End
}
