import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from 'yup';
import { FormikValues } from "formik";
import {gapi} from 'gapi-script'
import {  GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import {   setStorageData } from "../../../framework/src/Utilities";

export interface GetUserResponseSuccesss {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
  is_any_dealership: boolean,
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showPassword: boolean;
  receivedError: string;

  fullName: string;
  profilePicture: string;
  Gmail: string;
  accessTokenGoogle: string;
  googlePhone: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  apiGetPhoneCallId: string = "";
  googleLoginBackendCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  getuserDataApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.doEmailLogIn = this.doEmailLogIn.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPassword: false,
      receivedError: '',

      fullName: '',
      profilePicture: '',
      Gmail: '',
      accessTokenGoogle:'',
      googlePhone:''
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    function start(){
      gapi.client.init({
        clientId: "441584058688-05ov470a7n99j7884oatccmqk4q5f2r8.apps.googleusercontent.com",
        scope: 'profile email https://www.googleapis.com/auth/user.phonenumbers.read'
      })
    };
    gapi.load('client:auth2', start)
    // Customizable Area End
  }
  // Customizable Area Start
   responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('profileObj' in response) {
      this.setState({
        fullName: response.profileObj.name,
        Gmail: response.profileObj.email,
        profilePicture: response.profileObj.imageUrl
      });
      if ('tokenObj' in response && 'access_token' in response.tokenObj) {
        this.setState({ accessTokenGoogle: response.tokenObj.access_token }, () => {
          this.getPhoneNumber();
        });
      }
    } 
  }

  getPhoneNumber = async ()=>{
    const header = {
      'Authorization': `Bearer ${this.state.accessTokenGoogle}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPhoneCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneNumberApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  googleLoginApi(){
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const attrs = {
      email: this.state.Gmail,
      full_name: this.state.fullName,
      full_phone_number: this.state.googlePhone
    };
    const data = {
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.googleLoginBackendCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.googleLoginApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

   validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is Required"),
    password: Yup.string()
      .min(8, "Invalid Password")
      .matches(/[a-z]/, "Invalid Password")
      .matches(/[A-Z]/, "Invalid Password")
      .matches(/\d/, "Invalid Password")
      .required("Password is Required")
  });

  isErrorborder = (touched:boolean | undefined, error:string | undefined, color1: string, color2: string)=>{
    if(touched && error){
    return color1
    }
    return color2
   }

  handleSubmit = (values: FormikValues) => {
      this.setState({
        email: values.email,
        password: values.password
      },this.doEmailLogIn)
    }
  

  handleMouseDownPassword = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };
  handleShowPass = () => {
   this.setState({showPassword: !this.state.showPassword})
  };

  checkRole=(token:string)=>{
    console.log('rrrtoken', token)
    const Webheader = {
      "Content-Type": 'application/json',
      "token": token
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  handleUserData=(responseJson:GetUserResponseSuccesss)=>{
   if(responseJson.data.attributes.is_any_dealership){
    this.navigateToDashboard()
   }
   else if(responseJson.data.attributes.role == 'dealer_admin'){
    this.gotoCreateDeal()
   }
   else if(responseJson.data.attributes.role == 'service_provider_admin'){
    this.gotoSpDeal()
   }
   else{
    this.navigateToDashboard()
   }
  }

  gotoSpDeal = ()=>{
    const routetogo = new Message(getName(MessageEnum.NavigationMessage));
    routetogo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    routetogo.addData(getName(MessageEnum.NavigationTargetMessage), 'ServiceProviderProfiles');
    this.send(routetogo);
  }

  gotoCreateDeal = ()=>{
    const routetogo = new Message(getName(MessageEnum.NavigationMessage));
    routetogo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    routetogo.addData(getName(MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
    this.send(routetogo);
  }

  goToSignUp = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountRegistration');
    this.send(msg);
  }
  navigateToForgotPassword = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ResetPassword');
    this.send(msg);
  }

  navigateToDashboard = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: "HOME"});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage("MergeEngineUtilities", msg);
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValuie: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(responseJson.errors){
        this.setState({receivedError:responseJson.errors[0].failed_login})
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      switch (apiRequestCallId) {
        case this.apiEmailLoginCallId:
          if (responseJson.meta.token) {
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.checkRole(responseJson.meta.token)
          } 
          break;
    
        case this.apiGetPhoneCallId:
          if (responseJson.phoneNumbers) {
            this.setState({ googlePhone: responseJson.phoneNumbers[0].value }, () => {
              this.googleLoginApi();
            });
          } else {
            this.googleLoginApi();
          }
          break;
    
        case this.googleLoginBackendCallId:
          if (responseJson.meta.token) {
            setStorageData("authToken", responseJson.meta.token)
            this.navigateToDashboard();
          }
          break;
          
        case this.getuserDataApiCallId:
          this.handleUserData(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
