import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock"; // Assuming this is where IBlock is located

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  checkHeader: string;
  handleModal: any;
}

interface S {
  apiToken: string | null;
  anchorEl: HTMLElement | null;
  openModal: boolean;
  rows: any;
  selectedTab: any;
  dealershipData: any;
}

interface SS {
  id: any;
}

export default class SavedDealController extends BlockComponent<Props, S, SS>
  implements IBlock {
  getSavedDealApi: string = "";
  getMultipleDealershipApi: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      apiToken: null,
      anchorEl: null,
      openModal: false,
      rows: [],
      selectedTab: "startFromScratch",
      dealershipData: []
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);

      this.setState({
        apiToken: token ? token : localStorage.getItem("authToken")
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSavedDealApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ rows: resJson.data ? resJson.data : [] });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getMultipleDealershipApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.dealWithDealershipData(resJson);
    }
  }

  dealWithDealershipData(resJson: any){
    if(resJson?.dealerships?.data){
      this.setState({dealershipData:resJson.dealerships.data})
    } else{
      this.setState({dealershipData:[]})
    }
  }
  async componentDidMount() {
    this.getToken();

    // Customizable Area End
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken != this.state.apiToken &&
      this.state.apiToken != null
    ) {
      this.getSavedDeal();
      this.getMultipleDealership();
    }
  }
  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  navigateToCreatedeal = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CreateDealForm");
    this.send(msg);
  };

  handleTabChange = (tab: any) => {
    this.setState({ selectedTab: tab });
  };

  getMultipleDealership() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.apiToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMultipleDealershipApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMultipleDealershipApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMultipleDealershipApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getSavedDeal() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.apiToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedDealApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSavedDealApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSavedDealApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
