import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"
export interface GetUserResponseSuccess {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabvalue:number,
  page:number,
  rowsPerPage:number,

  chartTabValue: number,
  dealer:string,
  reviewTab:number,
  role:string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HomeDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changeNameApiCallId:string="";
  getuserDataApiCallId:string="";
  sendContactUsApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
        tabvalue:0,
        page:0,
        rowsPerPage:5,
        chartTabValue:0,
        dealer:'Dealer 1',
        reviewTab:0,
        role:''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
       if(apiRequestCallId===this.getuserDataApiCallId){
        this.handleUserData(responseJson)
       }
      }
    } 

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getUserDataFromBe()
  }
  handleUserData=(responseJson:GetUserResponseSuccess)=>{
    if(responseJson.data.attributes){
      this.setState({role:responseJson.data.attributes.role})
    }
  }

  getSubHeader = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return 'Quick statistics on deals in your dealership'
    }else{
      return 'Quick statistics on deals in your account'
    }
  }

  getHeaderOfIssueAccordian = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return 'Attention Required (126)'
    }else{
      return 'Issue Sent to Dealer'
    }
  }

  getSubHeaderOfIssueAccordian = ()=>{
    if(this.state.role==='dealer_admin' || this.state.role==='dealer'){
      return 'Below are the documents that require your attention'
    }else{
      return 'These documents require Dealer attention'
    }
  }

  getUserDataFromBe=async()=>{ 
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader) );
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),'GET');
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({tabvalue: newValue});
  };

  handleChangeChartTab=(event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({chartTabValue: newValue});
  };

  handleReviewTab=(event: React.ChangeEvent<{}>, newValue: number)=>{
    this.setState({reviewTab: newValue});
  }

  handleSelectUserDeal=(event: React.ChangeEvent<{ value: unknown }>)=>{
    this.setState({dealer: event.target.value as string})
  }
    handleChangePage = (event:React.MouseEvent<HTMLButtonElement> | null, newPage:number) => {
        this.setState({page: newPage})
  };

   handleChangeRowsPerPage = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({rowsPerPage: +event.target.value, page:0})
  };
  // Customizable Area End

}