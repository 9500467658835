import React from "react";
// Customizable Area Start
import { Box, ButtonBase, Drawer, InputAdornment, TextField, Typography, styled,} from "@material-ui/core";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

interface Deal {
  id: number;
  lastName: string;
}

// Customizable Area End

import HamburgerMenuController, {
  Props,
} from "./HamburgerMenuController";
import { arrowShowMore, arrowTop, car, hamburger_icon_selected, home, logo, people, searchIcon } from "./assets";

export default class HamburgerMenu extends HamburgerMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  dealData: Deal[] = [
    { id: 1, lastName: "Smith" },
    { id: 2, lastName: "Johnson" },
    { id: 3, lastName: "Williams" },
    { id: 4, lastName: "Brown" },
  ];

  allDealsContent = () => {
    const filteredDeals = this.dealData.filter(deal => 
      deal.lastName.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
      deal.id.toString().includes(this.state.searchTerm)
    );
    return (
      <Box style={{ width: '100%',  marginTop:'35px'   }}>
        <ButtonBase style={{ width: '100%' }}>
          <Box style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '260px',
            height: '45px',
            justifyContent: 'space-between',
            transition: 'all 0.9s ease',
          }}>
            <Box style={{
              display: 'flex',
              flexDirection: this.state.isOpen ? 'row' : 'column',
              alignItems: 'center',
              height: '45px',
              justifyContent: 'start',
              transition: 'all 0.9s ease',
              overflow: 'hidden',
              width: this.state.isOpen ? '228px' : '80px',
            }}
            onClick={this.toggleExpand} data-test-id="accordion"
            >
              <img src={car} style={{
                width: '24px',
                height: '24px',
                marginLeft: this.state.isOpen ? '10px' : '0px',
                flexShrink: 0,
              }} />
              <Typography style={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#013D4F',
                marginLeft: this.state.isOpen ? '10px' : '0px',
                whiteSpace: 'nowrap',
              }}>All Deals</Typography>
            </Box>
            <Box style={{
              width: "32px",
              height: "32px",
              display: this.state.isOpen ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
            }}>
              <img src={arrowTop} style={{
                width: "24px",
                height: "24px",
                transform: this.state.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.5s ease-in-out'
              }} 
              onClick={this.toggleExpand} data-test-id="accordionArrow"
              />
            </Box>
          </Box>
        </ButtonBase>
        <Box className="collapsibleContent" style={{ maxHeight: this.state.isExpanded ? "199px" : "0px", overflow: "hidden", transition: 'max-height 0.5s ease-in-out', justifyContent:'center', display:'flex', flexDirection:'column', width:'100%', marginLeft:'10px' }}>
          <Box style={{width:'166px', height:'186px'}}>
              <Box style={{width:'166px', height:'32px'}}>
                <TextField 
                placeholder="Search in deals"
                data-test-id = "searchInDealstestId"
                onChange={this.handleSearchChange}
                InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start" style={{ cursor: "pointer" }}>
                    <img src={searchIcon} alt="map" id="searchIcon" style={{width:'20px', height:'20px'}} />
                  </InputAdornment>
                </>
              ),
            }} 
            />
              </Box>
            <Box style={{marginTop:'10px'}}>
            {(this.state.searchTerm === '' ? this.dealData : filteredDeals).map((deal) => (
          <Box key={deal.id}>
            <Typography style={{fontWeight:500, fontSize:'14px', color:'#989A98'}} variant="body1">
              {`${deal.lastName}`}
            </Typography>
          </Box>
        ))}
        <Box style={{width:'132px', height:'25px', display:'flex', justifyContent:'space-between', marginTop:'6px'}}>
            <Typography style={{fontWeight:400, fontSize:'16px', color:'#C5CBC9'}}>show more</Typography>
            <img src={arrowShowMore} style={{
                width: "24px",
                height: "24px",
                transform: this.state.showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.5s ease-in-out',
                color:'#C5CBC9',
                marginRight:'16px'
              }} />
        </Box>
        </Box>

          </Box>
        </Box>
      </Box>
    )
  }

  mainSideBarContent = () => {
    return (
      <Box style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '40px',
                width: this.state.isOpen ? '260px' : '80px',
                height: this.state.isOpen ? '205px' : '259px',
                maxHeight: '460px',
                transition: 'all 0.9s ease',
              }}>
                <ButtonBase style={{ width: '100%'}}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: this.state.isOpen ? 'row' : 'column',
                    alignItems: 'center',
                    width: this.state.isOpen ? '260px' : '330px',
                    height: this.state.isOpen ? '45px' : '65px',
                    justifyContent: this.state.isOpen ? 'start' : 'center',
                    transition: 'all 0.3s ease',
                  }}>
                    <img src={home} style={{
                      width: '24px',
                      height: '24px',
                      paddingLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'padding-left 0.9s ease',
                    }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#013D4F',
                      marginLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'margin-left 0.9s ease',
                    }}>Home</Typography>
                  </Box>
                </ButtonBase>
                {this.allDealsContent()}
                <ButtonBase style={{ width: '100%', marginTop:'35px' }}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: this.state.isOpen ? 'row' : 'column',
                    alignItems: 'center',
                    width: this.state.isOpen ? '260px' : '330px',
                    height: this.state.isOpen ? '45px' : '65px',
                    justifyContent: this.state.isOpen ? 'start' : 'center',
                    transition: 'all 0.9s ease',
                  }}>
                    <img src={people} style={{
                      width: '24px',
                      height: '24px',
                      paddingLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'padding-left 0.9s ease',
                    }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#013D4F',
                      marginLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'margin-left 0.9s ease',
                    }}>My Teams</Typography>
                  </Box>
                </ButtonBase>
              </Box>
            </Box>
    )
  }

  renderOverlay = () => {
    if (!this.state.isOpen) return null;

    return (
      <Box
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: this.state.isOpen ? 'rgba(1, 61, 79, 0.4)' : 'rgba(0, 0, 0, 0)',
          zIndex: 1100,
          transition: 'background-color 0.3s ease-in-out',
        }}
        onClick={this.isOpen} 
      />
    );
  }

  renderHamburgerMenu = () => {
    return (
      <Box>
        {this.renderOverlay()}
        <Drawer
          anchor={"left"}
          open={true}
          style={{ zIndex: 1200 }}
          onClose={this.isOpen}
          variant="persistent"
          PaperProps={{
            style: {
              width: this.state.isOpen ? '330px' : '120px',
              transition: 'width 0.9s',
              overflowX: 'hidden',
              borderRight: '1px solid #F0FAFA',
            },
          }}
        >
          <Box style={{
            width: `${this.state.isOpen ? '330px' : '120px'}`,
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: "flex-start",
            transition: 'width 0.9s ease',
          }}>
            <Box style={{width:'100%', display:'flex', justifyContent:'center',}}>
              <Box style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                marginTop: this.state.isOpen ? '25px' : '8px',
                marginRight: this.state.isOpen ? '21px' : '0px',
                width: this.state.isOpen ? '239px' : '83.69px',
                height: this.state.isOpen ? '40px' : '53px',
                transition: 'all 0.9s ease',
              }}>
                <ButtonBase style={{ width: '40px' }} >
                  <Box onClick={this.isOpen} data-test-id="hamburgerId" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.isOpen
                      ? <img src={hamburger_icon_selected} style={{ width: '40px', height: '40px' }} />
                      : <Box onClick={this.isOpen} data-test-id="menuButtonTestId" style={{ width: '24px', height: '24px' }}>
                        <MenuRoundedIcon style={{ color: '#C5CBC9' }} />
                      </Box>
                    }
                  </Box>
                </ButtonBase>
                <Box>
                  <img src={logo} style={{ width: '51.69px', height: '24px' }} />
                </Box>
              </Box>
            </Box>
            {this.mainSideBarContent()}
          </Box>
        </Drawer>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <HamburgerMenuStyle>
          {this.renderHamburgerMenu()}
        </HamburgerMenuStyle>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const HamburgerMenuStyle = styled(Box)({
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: '#C5CBC9',
      opacity: 1,
      fontWeight:400,
      fontSize:'14px'
    },
  },
})
// Customizable Area End
