import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    Checkbox,
    FormControlLabel,
    Avatar,
    InputAdornment ,
    IconButton,
    styled,
    Modal,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
  } from "@material-ui/core";
  import OtpInput from 'react-otp-input';
  import {vitulogoimage,googleimageicon, banner,usaflag, filledCheckbox, emptyCheckbox, inviteduserphoto} from '../../email-account-registration/src/assets'
  import { Formik, Form, Field  } from 'formik';
  import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
  import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
  import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
  import {isError} from '../../../components/src/Loader.web';
  import CheckCircleIcon from '@material-ui/icons/CheckCircle';
  import CancelIcon from '@material-ui/icons/Cancel';
  import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
  import { GoogleLogin } from 'react-google-login';
  import CloseIcon from '@material-ui/icons/Close';
  import { createTheme } from "@material-ui/core/styles";


  const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });
// Customizable Area End

import BlockController, {
  Props,
} from "./InviteUserSignupController";


export default class InviteUserSignup extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  errorBoxAvatar = () => {
    if (this.state.photoErrorMessage) {
      return (
        <Typography style={{ color: '#C43937', marginTop: '7px', marginLeft:'4px', fontSize: '12px' }}>
          {this.state.photoErrorMessage}
        </Typography>
      );
    } else {
      return null;
    }
  };

  successMessageBoxGoogle = () => {
    return ((this.state.phoneConfirmed && !this.state.gotGphone) && (
        <Box>
          <Typography style={{  fontSize: '12px',  marginTop: '10px', fontFamily:'Roboto',color: '#26BBB3',  }}>
            Phone number verified.
          </Typography>
        </Box>
      ));
  };

  dialogBox  = ()=>{
    return (
      <Dialog
      open={this.state.alertDialogOpen}
      onClose={this.handleCloseAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{color:'#C43937'}}>{"Account not matched!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography style={{fontSize:'16px', color:'#4B4C4B'}}>{'You are trying to signup with a wrong account, the expected email is '}</Typography>
          <Typography style={{color:'#013D4F', fontWeight:500, fontSize:'16px'}}>{this.state.email}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleCloseAlert} data-test-id="closedialog">
         <Typography style={{textTransform:'none', color:'#4B4C4B'}}>Understood</Typography>
        </Button>
      </DialogActions>
    </Dialog>
    )
  }

  googleErrorBoxNew = (value2 : string, align:string)=>{
    return ( <Typography style={{ color: '#DC2626', fontSize:'12px', marginTop:'2px',marginBottom:'7px', textAlign:align == 'left' ? 'left' : 'center'}}>{value2 ? value2 : null}</Typography>)
  }

  newModalGoogle = ()=>{
    return(
      <Modal
      open={this.state.openModalGoogle}
      aria-labelledby="simple-modal-title"
      onClose={this.handleCloseGoogle}
      aria-describedby="simple-modal-description"
      style={{  display: 'flex', justifyContent: 'center',alignItems: 'center'
   }}
    >

      <Box style={{ 
        border: '1px solid #FFFFFF',
        maxWidth: '512px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius:'8px',
        backgroundColor:'#FFFFFF'
        }}>
        <Box display={'flex'} justifyContent={'end'} style={{width:'104%'}}>
          <Button data-test-id="closebtn" onClick={this.handleCloseGoogle}><CloseIcon/></Button>
        </Box>
  
       <Box>
          <Typography style={{color:'#013D4F', fontSize:'30px', fontWeight:300, fontFamily:'Gotham light'}}>{this.getGoogleHeaderr()}</Typography>
          <Typography className="gothamText" style={{fontWeight:300,fontSize:'18px',color:'#4B4C4B',}}>
            {this.getGoogleSubHeaderr()}
            <span style={{ color: '#013D4F', fontWeight: 500, fontFamily:'Roboto'}}>{this.getPhonedisabilityy() && this.formatPhoneNumberr(this.state.googlePhone)}</span>
          </Typography>
       </Box>
  
       {(!this.state.gotGphone) &&
       <Box>
       <Box style={{display:this.getDisplayy(!this.state.otpSent || this.state.phoneConfirmed), marginTop:'24px', position:"relative",flexDirection:'row',alignItems:'center', border:this.isGoogleError(true, this.state.receivedGooglePhoneError ,'1px solid #C43937', '1px solid #C5CBC9'),height:'56px',borderRadius:'8px',padding:'10px, 24px, 10px, 24px', justifyContent:'space-between'}}>
       <Box position="end" style={{cursor: "pointer" ,display:"flex", alignItems:"center", position:'absolute', top:2, left:25}}>
               <img src={usaflag} style={{height:"14px", width:"18px"}}/>
               <p style={{marginLeft:"8px", fontSize:"16px", color:this.getColor(this.state.googlePhone)}}>+</p>
               <Typography style={{fontSize:"16px",color:this.getColor(this.state.googlePhone), marginTop:'2px'}}>1</Typography>
               <p style={{marginLeft:"12px", marginRight:"10px",color:this.getColor(this.state.googlePhone), fontSize:"16px"}}>|</p>
         </Box>
           <TextField
            type="text"
            data-test-id="phoneGoogle"
             placeholder="Mobile Number"
             InputProps={{
                 disableUnderline: true,
               style: {
                 border: 'none',
                 height:'',
                 paddingTop:'6px'
               },
             }}
             inputProps={{
               maxLength: 10,
               style: { borderRadius: "8px",marginLeft:'100px'}
             }}
             InputLabelProps={{
               style:{marginLeft:'80px', color:'gray'}
             }}
             value={this.state.googlePhone}
             onChange={this.handleChangeGooglePhone}
             
             disabled={this.checkIsValid(Boolean(!this.state.receivedGooglePhoneError), this.state.otpSent)}
           /> 
            <ValidatePhoneInvite data-test-id="validateGoogle" disabled={this.state.googlePhone.length<10} onClick={this.handlePhoneVlidateGoogle} variant="outlined" style={{border:`1px solid ${this.getColorBasedOnCondition(this.state.googlePhone.length<10,'gray','#013D4F')}`,  borderRadius:'8px', paddingLeft:'10px', paddingRight:'10px', display:this.getDisbalitiofButn((Boolean(!this.state.receivedGooglePhoneError) && this.state.otpSent))}}>
             <Typography style={{color:this.getColorBasedOnCondition(this.state.googlePhone.length<10,'gray','#013D4F'), fontSize:'16px', fontWeight:700, fontFamily:'Roboto', textTransform:'none'}}>Validate Phone</Typography>
           </ValidatePhoneInvite>
      </Box>
      
      <Box style={{display:(!this.state.otpSent)?'flex':'none', width:'100%',  justifyContent:'space-between'}}>
        <Box>
          {this.googleErrorBoxNew(this.state.receivedGooglePhoneError, 'left')}
        </Box>
      </Box>
  
      {this.checkIsValid(this.state.openOtp, !this.state.phoneConfirmed) &&
      <Box>
      <Box style={{justifyContent:'center',display:'flex',  marginTop:'15px'}}>
         <OtpInput
          data-test-id="otpGoogle"
                  value={this.state.googleOtp}
                  onChange={this.handleChangeOtp}
                  numInputs={4}
                  inputType="tel"
                  inputStyle={{
                    border: `0.2px solid ${this.isGoogleError(true,this.state.receivedGoogleOtpError, '#C43937', '#C5CBC9')}`,
                    height:'65px',
                    width:'65px',
                      fontSize:'30px',
                      borderRadius:'8px',
                      color:'#4B4C4B',
                      fontWeight:300,
                      textAlign:'center',
                      margin:'8px'
                  }}
                  renderInput={(props) => <input {...props} />}
                  />
        </Box>
        {this.googleErrorBoxNew(this.state.receivedGoogleOtpError, 'center')}

            <ButtonDbb data-test-id="verifyGoogleOtp" onClick={this.verifyOtpGoogle} disabled={this.state.googleOtp.length<4} fullWidth variant="contained" style={{boxShadow:'none'}}>
              <Typography style={{fontSize:'16px',textTransform:'none',color:this.getColorBasedOnCondition(this.state.googleOtp.length<4,'#75D3F0', 'white'), fontFamily:'Roboto', fontWeight:700}}>
                Verify
              </Typography>
            </ButtonDbb>

          <Box style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', marginLeft:'auto', marginRight:'auto', marginTop:'20px'}}>
              <Button onClick={this.handleCloseGoogle}>
                <Typography style={{fontSize:'16px',textTransform:'none',color:'#013D4F', fontFamily:'Roboto', fontWeight:500}}>
                  Not you?
                </Typography>
              </Button>

              <Box display="flex" alignItems="center">
                <Typography style={{fontWeight:500,fontFamily:'Roboto',padding:'0px',fontSize:'16px',textTransform:'none',color :'#4B4C4B'}}>Didn't receive OTP? </Typography>
                <Button variant="text" disabled={this.state.timer>0 || this.state.counter===2} onClick={this.resendOtpGoogle} data-test-id="resendotp">
                  <Typography data-test-id="timerDisplay" style={{fontSize:'16px',fontFamily:'Roboto', fontWeight:500, color :(this.state.timer>0 || this.state.counter===2) ? '#4B4C4B' :'#013D4F',textTransform:'none'}}>Resend <span style={{display: this.state.timer >0 ? '' : 'none'}}>in {this.formatTimeGoogle(this.state.timer)}</span></Typography>
                </Button>
              </Box>
          </Box>
        </Box>
      }
      </Box>
      }
  
        {this.successMessageBoxGoogle()}
        <Box style={{display:this.getDisplaytwo(!this.state.otpSent || this.state.phoneConfirmed)}}>
  
            <Box style={{ display:'flex',justifyContent:'start',alignItems:'center',alignContent:'start',marginBottom:'16px', marginTop:'14px'}}>
              <Checkbox
              style={{paddingLeft:'0px'}}
              checked={this.state.checkedGoogle}
              onChange={(event)=>{this.handleGoogleCheck(event)}}
              data-test-id="checkboxGoogle"
              inputProps={{ 'aria-label': 'controlled' }}
              icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
              checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
            />
              <Typography  style={{fontWeight:500,color:'#4B4C4B',fontSize:'16px', fontFamily:'Roboto'}}>I agree to the 
              <Button onClick={this.handleTermsCondition} data-test-id="termstest" variant="text" style={{fontSize:'16px',fontWeight:500,color:'#013D4F', textTransform:'none',fontFamily:'Roboto'}}>Terms and Conditions</Button> 
              </Typography>
      
            </Box>
        
       
            <ButtondbGoogle data-test-id="submitgoogledetail" disabled={this.checkGoogleButtonDisabilityy()} variant="contained"  style={{height:'56px'}} onClick={this.handleGoogleSignUpSubmitBe}>
              <Typography style={{fontWeight:700,fontSize:'16px', textTransform:'none'}}>Confirm Details</Typography>
            </ButtondbGoogle>
        </Box>
      </Box>

      </Modal>
    )
  }

  SignUpForm = ()=>{
    return (
      <Grid
      container
      direction="column"
      justifyContent="center"
      style={{ marginTop:'50px'}}
    >
    <Box style={{paddingLeft:'18%'}}>
      <FieldBox style={{display:"flex",flexDirection:'column',justifyContent:'flex-start',alignItems:'start',marginBottom:'40px'}}>
        <Box className="gothamLightText" style={{fontWeight:300,color:'#013D4F',fontSize:'30px',marginBottom:'12px'}}>Let’s Setup Your Profile</Box>
        <Typography className="gothamText" style={{fontWeight:300,color:'#4B4C4B',fontSize:'18px'}}>Please fill in the details below in order to create your profile.</Typography>
      </FieldBox>

    <FieldBox>
      <Formik 
            initialValues={{ phoneNumber: '',agreeToTerms:false}}
            validationSchema={this.validationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, values,isValid }) => (
              <Form>
              <Box style={{display:'flex', flexDirection:'row'}}>
              <Box>
                <Box style={{height:'98px',backgroundColor:'#F0FAFA', width:'98px', borderRadius:'24px', textAlign:'center', backgroundImage: `url(${banner})`, display:'flex', justifyContent:'center', alignItems:'center'}}>
                  {this.state.profilePhoto ? 
                  <Avatar variant="square" className="avatar"  src={this.state.profilePhoto} style={{ width: '100%',borderRadius:'24px', height: '100%'  }} /> 
                  : (<>
                    {this.state.initials?
                    (<Avatar variant="square" style={{height: '80px',width: '80px',borderRadius: '24px',  backgroundColor:'rgb(240, 250, 250)',  color: '#013D4F', fontSize:'32px', fontWeight:500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {this.state.initials}
                    </Avatar>
                    ): null}
                    </>)
                    }
                </Box>
              </Box>
              <Box style={{width:'400px',marginLeft:'20px'}}>
              <Box>
                {this.state.profilePhoto ? 
                (<Box>
                  <Button component="label" variant="outlined" style={{marginTop:'10px', color:'#013D4F',border:'1px solid #013D4F', borderRadius:'8px'}}><Typography style={{textTransform:'none', fontWeight:700, fontSize:'16px'}}>Re-upload Photo</Typography>
                    <input type="file" hidden onChange={(event)=>{this.handlePhotoChange(event)}}/>
                  </Button>
                
                  <Button data-test-id="delete-picture-button" variant="outlined"  onClick={this.handleDeletePicture} style={{color:'#C43937',borderRadius:'8px', border:'1px solid #C43937',marginTop:'10px', marginLeft:'10px'}}><Typography style={{textTransform:'none', fontWeight:700, fontSize:'16px'}}>Remove Photo</Typography></Button> 
                </Box>)
                :(
                <Box>
                  <Button component="label" variant="outlined" style={{color:'#013D4F', border:'1px solid #013D4F', borderRadius:'8px', marginTop:'10px'}}><Typography style={{textTransform:'none', fontWeight:700, fontSize:'16px'}}>Upload Profile Photo</Typography>
                    <input hidden type="file" onChange={(event)=>this.handlePhotoChange(event)}/>
                  </Button>
                </Box>)
                }
              </Box>
              {this.errorBoxAvatar()}
              <Box>
                <Typography color="secondary" style={{ color:'#C5CBC9',fontSize:'12px', marginTop:'14px'}} >Use images between 200x200 and 800x800 pixels in .jpg or .png format. If no picture is uploaded, your initials will be used.</Typography>
              </Box>
              </Box>
            </Box>

              <Box style={{ width: '100%', marginTop:'24px' }}>
              {this.state.errorFromBe && 
                <Box style={{backgroundColor:'#FEE2E2', height:'46px', width:'380px', borderLeft:'4px solid #C43937', marginBottom:'20px', borderRadius:'8px'}}>
                  <Typography style={{color:'#C43937', paddingTop:'13px', marginLeft:'10px', fontSize:'14px', fontWeight:700}}>{this.state.errorFromBe}</Typography>
                </Box>
              }
    
              <Field
              as={TextField}
              data-test-id="fullnameformik"
              className="fullnameclass"
              name="fullname"
              label="Full Name"
              variant="outlined"
              id="outlined-basic"
              fullWidth
              InputProps={{
               style: { borderRadius: "8px", paddingLeft:'5px'},
               onKeyDown: (event:React.KeyboardEvent<HTMLInputElement>) => {
                if (this.handleDown(event)) {
                  event.preventDefault();
                }
              },
              }}
              InputLabelProps={{
                style:{color:'#C5CBC9'}
            }}
              value={this.state.firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{this.handleNameChange(event)}}
              onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
               
                if (this.handleOnFocusFullName(event)) {
                  this.bbbb(event.target.labels)
                }
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                if (this.handleBlurr(event)) {
                  this.cccc(event.target.labels, "0px")
                }
                handleBlur(event);
              }}
              style={{ marginBottom: '20px'}}
            />


          <Field
          as={TextField}
          data-test-id="emailtest"
          name="email"
          label="Email Address"
          variant="outlined"
          fullWidth
          disabled
          InputProps={{
            style: { borderRadius: "8px", color:'#C5CBC9' }
          }}
          InputLabelProps={{
            style:{color:'#4B4C4B'}
        }}
          value={this.state.email}
          onBlur={handleBlur}
          style={{ marginBottom: '20px' }}
        />

        <Box position="relative">
         <Field
          as={TextField}
          label="Mobile Number"
          variant="outlined"
          fullWidth
          data-test-id="phoneNumber"
          style={{ position: 'relative' }}
          value={values.phoneNumber}
          name="phoneNumber"
          id="phoneNumber"
          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
            if (this.handleOnFocusFullName(event)) {
             this.bbbb(event.target.labels)
            }
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            if (this.handleBlurr(event)) {
              this.cccc(event.target.labels, "80px")
            }
            handleBlur(event);
          }}
          onChange={(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.handleRegex(event, handleChange)}
          inputProps={{
            maxLength: 10,
            style: { borderRadius: "8px",marginLeft:'80px' }
          }}
          InputLabelProps={{
            style:{marginLeft:'80px', color:'#C5CBC9'}
          }}
          InputProps={{
            style: {borderRadius:"8px"},
          }}
        />
         <Box position="end" style={{cursor: "pointer" ,display:"flex", alignItems:"center", position:'absolute', top:0, left:15}}>
              <img src={usaflag} style={{height:"14px", width:"18px"}}/>
              <p style={{marginLeft:"8px", fontSize:"16px", color:this.getColor(values.phoneNumber)}}>+</p>
              <Typography style={{fontSize:"16px",color:this.getColor(values.phoneNumber), marginTop:"1px"}}>1</Typography>
              <p style={{marginLeft:"12px", marginRight:"10px",color:this.getColor(values.phoneNumber), fontSize:"16px", fontWeight:300}}>|</p>
        </Box>
        </Box>

        <Box style={{marginLeft:'5px', marginTop:'15px'}}>
        <FormControlLabel
        data-test-id="formcontrol"
          control={
            <Checkbox
            data-test-id="checktest"
              name="agreeToTerms"
              checked={values.agreeToTerms}
              onChange={handleChange}
              onBlur={handleBlur}
              icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
              checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
            />
          }
          label={
            <Typography style={{color:'#4B4C4B', fontSize:'16px'}}>
              I agree to the
              <Button data-test-id="termstest" onClick={this.handleTermsCondition} variant="text" style={{fontWeight:600,fontSize:'16px',color:'#013D4F', textTransform:'none'}}>Terms and Conditions</Button> 
            </Typography>
          }
          style={{ marginBottom: '20px' }}
        />
        </Box>
       </Box>

              <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                <Buttondb
                  type="submit"
                  disabled={this.checkDisability(values, errors)}
                 
                  variant="contained"
                  style={{ width: '100%', height: '56px' }}
                >
                  <Typography style={{ textTransform: 'none', fontSize: '16px', fontWeight: 700 }}>Next Step</Typography>
                </Buttondb>

                <GoogleLogin
                  data-test-id="Gloginbutton"
                    clientId="441584058688-05ov470a7n99j7884oatccmqk4q5f2r8.apps.googleusercontent.com"
                    render={renderProps => (
                      <Button onClick={renderProps.onClick} disabled={renderProps.disabled}  style={{ height: '56px', marginTop: '15px', border: '1px solid #F0FAFA', display: 'flex', alignItems: 'center' }}>
                        <img src={googleimageicon} alt="icon" style={{ marginRight: '8px' }} /> 
                        <span style={{textTransform:'none', fontSize:'16px', fontWeight:500, color:'#013D4F', fontFamily:'Roboto'}}>Sign Up with Google</span> 
                      </Button>
                    )}
                    onSuccess={this.responseGoogleInviteUser}
                    onFailure={this.responseGoogleInviteUser}
                    cookiePolicy={'single_host_origin'}
                  /> 

                  {this.newModalGoogle()}
                  {this.dialogBox()}

                <Button data-test-id="gotologinbutton" onClick={this.gotoLogin}>
                  <Typography style={{textTransform:'none', fontSize:'16px', color:'#013D4F', fontWeight:500, fontFamily:'Roboto'}}>Not You?</Typography>
                </Button>
              </Box>
            </Form>
            )}
</Formik>
    </FieldBox>
</Box>

  </Grid>
    )
  }

  CreatePasswordScreen = ()=>{
    return (
      <Box  style={{display:'flex', flexDirection:'column', justifyContent:'center',  marginTop:'18%', marginBottom:'25%', paddingLeft:'18%'}}>
      
  
      <PasswordFieldBox>
      <NewBoxheader  style={{display:"flex",flexDirection:'column',marginBottom:'30px',alignItems:'start'}}>
        <Box className="gothamText " style={{fontWeight:300,color:'#013D4F',fontSize:'30px'}}>Create Password</Box>
        <Typography style={{marginTop:'10px',fontWeight:300,color:'#4B4C4B',fontSize:'18px', fontFamily:'Gotham'}}>Create a highly secure password with uppercase and lowercase letters and numbers</Typography>
      </NewBoxheader>
      <Formik 
                  initialValues={{ password2: '', password1: '' }}
                  validationSchema={this.validationSchema2}
                  onSubmit={this.handlePasswordSubmit}
                >
                  {({ errors, touched, handleChange, handleBlur, values }) => (
                    <Form >
                      <Box style={{ width: '100%' }}>
                      {this.state.errorFromBe && 
                      <Box style={{backgroundColor:'#FEE2E2', height:'46px', width:'380px', borderLeft:'4px solid #C43937', marginBottom:'20px', borderRadius:'8px'}}>
                        <Typography style={{color:'#C43937', paddingTop:'13px', marginLeft:'10px', fontSize:'14px', fontWeight:700}}>{this.state.errorFromBe}</Typography>
                      </Box>
                    }
                      {isError(touched.password2, errors.password2, 'password2')}
                      <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', border:`1px solid ${this.colorOfBorder(touched.password2, errors.password2 ,'#C43937', '#C5CBC9')}`, height: '56px', padding: '10px, 24px, 10px, 24px', borderRadius: '8px' }}>
                          <LockOutlinedIcon style={{ textAlign: 'start', padding: '5px', color: this.colorOfBorder( true ,values.password1,'#4B4C4B', '#C5CBC9'), marginLeft: '15px' }} />
                          <Field
                          className="password login_test_password"
                            name="password1"
                            data-test-id="passwordtest"
                            type={this.state.showPassword1 ? 'text' : 'password'}
                            placeholder="Password"
                            as={TextField}
                            InputProps={{
                              disableUnderline: true,
                              style: { border: 'none', height: '',marginTop:'5px' },
                              onKeyDown: (event:React.KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === ' ') {
                                  event.preventDefault();
                                }
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                  
                                  data-test-id="eyebutton"
                                  className="password_button_type"
                                    aria-label="toggle password visibility"
                                    onClick={()=>this.handlePasswordVisibility('password1')}
                                    edge="end"
                                    style={{ marginRight: '10px', color: values.password1 ? '#4B4C4B' : '#C5CBC9' }}
                                  >
                                    {this.state.showPassword1 ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            InputLabelProps={{ shrink: false }}
                            inputProps={{ maxLength: 15 }}
                            value={values.password1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box  style={{ width: '100%', marginTop: '16px' }} >
                        <Box  style={{display: 'flex', flexDirection: 'row', alignItems: 'center', border:this.colorOfBorder(touched.password2, errors.password2 ,'1px solid #C43937', '1px solid #C5CBC9'), height: '56px', padding: '10px, 24px, 10px, 24px', borderRadius: '8px' }}>
                          <LockOutlinedIcon style={{ textAlign: 'start', padding: '5px', color: this.colorOfBorder( true ,values.password1,'#4B4C4B', '#C5CBC9'), marginLeft: '15px' }} />
                          <Field
                          className="password login_test_password2"
                            name="password2"
                            data-test-id="passwordtest"
                            type={this.state.showPassword2 ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            as={TextField}
                            InputProps={{
                              disableUnderline: true,
                              style: { border: 'none', height: '',marginTop:'5px' },
                              onKeyDown: (event:React.KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === ' ') {
                                  event.preventDefault();
                                }
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                   data-test-id="eyebutton"
                                  className="password_button_type2"
                                  onClick={()=>this.handlePasswordVisibility('password2')}
                                    aria-label="toggle password visibility"
                                    style={{  color: values.password2 ? '#4B4C4B' : '#C5CBC9',marginRight: '10px', }}
                                    edge="end"
                                  >
                                    {this.state.showPassword2 ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ) }}
                              inputProps={{ maxLength: 15 }}
                            InputLabelProps={{ shrink: false }}
                            onChange={handleChange}
                            value={values.password2}
                            fullWidth
                            onBlur={handleBlur}
                            
                          />
                        </Box>
                      </Box>
                      <Box display={"flex"} className="errorcheckbox" style={{marginTop:'15px'}}  flexDirection={"column"} >
                        <Typography style={{fontSize:'14px',color:'#013D4F', fontWeight:400 }}>Password should have</Typography>
                        <Grid container  style={{marginTop:'15px'}} spacing={1}>
                        {this.passwordRestrictions.map((criteriaa, indexx) => (
                          <Grid item  key={indexx} xs={6}>
                            <Grid container alignItems="center">
                              <Grid item>
                              {criteriaa.test(values.password1) && (
                                <CheckCircleIcon style={{  height: '16px',color: '#26BBB3', width: '16px' }} />
                              )}
                              {!criteriaa.test(values.password1) && values.password1 && (
                                <CancelIcon style={{  height: '16px', width: '16px',color: '#C43937' }} />
                              )}
                              {!values.password1 && (
                                <RadioButtonUncheckedIcon style={{  width: '16px',height: '16px', color: '#4B4C4B' }} />
                              )}
                              </Grid>
                              <Grid item> <Typography style={{ color:'#4B4C4B',fontSize:'12px', fontWeight:400, marginTop:'2px',marginLeft:'5px'}}>{criteriaa.label}</Typography>
                              </Grid>

                            </Grid>

                          </Grid>
                        ))}

                        </Grid>

                      </Box>


                      <Box display={'flex'} flexDirection={'column'} style={{ width:'100%', marginTop:'30px' }}>
                        <Buttondb type="submit" disabled={!values.password1 || !values.password2 || Boolean(errors.password1) || Boolean(errors.password2)} variant="contained" style={{width:'100%', height: '56px' }}>
                          <Typography style={{textTransform: 'none', fontSize:'16px', fontWeight:700}}>Enter Vitu Marketplace</Typography>
                        </Buttondb>
                      </Box>
                    </Form>
                  )}
      </Formik>
    </PasswordFieldBox>
    </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Grid container spacing={0} style={{ minHeight: '912px'}}>
        <Grid className="hero" item xs={12} sm={12} md={8} lg={8} >
          <Box >
          <img src={vitulogoimage} style={{width:'91.27px',height:'42px', marginLeft:'20px', marginTop:'20px'}} />
          </Box>

        {(this.state.email &&  this.state.role_id) && this.SignUpForm()}
        {(this.state.accountidforinviteuser && !this.state.email &&  !this.state.role_id) && this.CreatePasswordScreen()}


        <Box style={{display:'flex', justifyContent:'space-between',flexDirection:'row', marginTop:'70px', marginLeft:'25px'}}>
          <Button disabled={true} variant="text">
            <Typography style={{textTransform:'none', color:'#C5CBC9',fontWeight:500,fontSize:'14px'}}>Copyright © 2024 Vitu</Typography>
          </Button>
          <Box style={{display:'flex'}}>
            <Button data-test-id="tcbutton" onClick={this.handleTermsCondition} variant="text">
            <Typography  style={{color:'#C5CBC9', fontSize:'14px',textDecoration:'underline', fontWeight:500, textTransform:'none'}}>Terms and Conditions</Typography>
            </Button>
            
            <Button data-test-id="contactbutton" variant="text" onClick={this.handleContactUs}>
              <Typography style={{marginLeft:'10px',textDecoration:'underline',color:'#C5CBC9',textTransform:'none', fontSize:'14px', fontWeight:500}}>Contact Us</Typography>
            </Button>
          </Box>
       </Box>
        </Grid>

        <ImageStyled item xs={12} sm={12} md={4} lg={4} style={{position:'fixed', height:'100vh', right:'0', width:'inherit'}}>
          <img src={inviteduserphoto} height="100%" width='100%'>
          </img>
        </ImageStyled>
     </Grid>  
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const PasswordFieldBox = styled(Box)({
  "@media  (max-width:449px)": {
    width:'300px'
  },
  "@media (min-width:500px) and  (max-width:570px)": {
    width:'350px'
  },
  "@media (min-width:571px) and (max-width:770px)": {
    width:'450px',
  },
  "@media (min-width:771px) and (max-width:1030px)": {
    width: '450px'
  },
  "@media (min-width:1031px) and (max-width:1250px)": {
    width: '512px'
  },
  "@media (min-width:1251px)": {
    width: '512px'
  }
})
const FieldBox = styled(Box)({
  "@media (max-width:400px)": {
    width:'250px'
  },
  "@media (min-width:401px) and (max-width:550px)": {
    width:'300px'
  },
  "@media (min-width:551px) and (max-width:749px)": {
    width:'400px'
  },
  "@media (min-width:750px) and (max-width:1030px)": {
    width:'500px'
  },
  "@media (min-width:1031px) and (max-width:1250px)": {
    width:'400px'
  },
  "@media (min-width:1251px)": {
    width:'512px'
  }
})
const ImageStyled = styled(Grid)({
  "@media (max-width:960px)": {
    display:'none'
  },
  "@media (min-width:961px)": {
    display:'block'
  }
})
const NewBoxheader = styled(Box)({
  '@media (min-width: 1451px)': {
    width: "780px",
  },
})
const ButtonDbb = styled(Button)({
  '&:disabled': {
    height: '50px',
    color: '#75D3F0',
    backgroundColor: '#F0FAFA',
  },
  '&:enabled': {
    backgroundColor: '#4FC8EC',
    height: '50px',
    color: 'white',
  },

});
const Buttondb = styled(Button)({
    '&:disabled': {
      color:'#75D3F0',
      width:'512px',
      backgroundColor:'#F0FAFA', height:'50px',
      '@media (max-width: 600px)': {
        width: "220px",
      },
      '@media (max-width: 700px)': {
        width: "300px",
      },
    },
    '&:enabled': {
      color:'white',
      width:'512px',
      backgroundColor:'#4FC8EC', height:'50px',
      '@media (max-width: 600px)': {
        width: "220px",
      },
      '@media (max-width: 700px)': {
        width: "300px",
      },
    },
    
  });

  const ButtondbGoogle = styled(Button)({
    width:'100%',
    '&:disabled': {
      color:'#75D3F0',
      backgroundColor:'#F0FAFA', height:'50px',
    },
    '&:enabled': {
      color:'white',
      backgroundColor:'#4FC8EC', height:'50px',
    },
  });

  const ValidatePhoneInvite = styled(Button)({
    "@media (max-width:469px)": {
      height:'49px',
      paddingTop:'2px',
      marginRight:'5px'
    },
    "@media (min-width:470px)": {
      marginRight:'15px'
    }
  })

// Customizable Area End