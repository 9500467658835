import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Document } from "../../projecttemplates/src/ProjectTemplatesDealDashboardController.web";
import { MenuAction } from "../../../components/src/CustomMenu";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  basicInfos: { [key: string]: any };
  documents: Document[];
  getDealInformation: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFiles: File[];
  selectedFile: Document;
  filteredDocuments: Document[];
  isUploadingDocuments: boolean;
  showProgress: boolean;
  edittingDocument: Document;
  documentLinks: string[];
  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesDocumentsUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadFilesApiId: string = '';
  renameFileApiId: string = '';
  deleteFileApiId: string = '';
  downloadAllFilesApiId: string = '';

  handleAddSelectedFiles(selectedFiles: File[]) {
    const files = this.state.selectedFiles;
    const filteredDuplicatedFiles = selectedFiles.filter(
      (selectFile) => !files.some((file) => file.name === selectFile.name)
    );
    this.setState({
      selectedFiles: [...files, ...filteredDuplicatedFiles],
    });
  }

  handleStopUploadingDocuments = () => {
    this.setState({
      isUploadingDocuments: false,
      selectedFiles: []
    });
  };

  handleUploadingDocuments = () => {
    this.setState({
      isUploadingDocuments: true,
    });
  };

  handleDeleteFile(deleteFile: File) {
    const filteredFiles = this.state.selectedFiles.filter(
      (file) => file.name !== deleteFile.name
    );
    this.setState({
      selectedFiles: filteredFiles,
    });
  }

  handleSelectFile(file: Document) {
    this.setState({
      selectedFile: file,
    });
  }

  handleFilterDocuments(event: any){
    const filterDocuments = this.props.documents.filter(file => file.filename.includes(event.target.value));
    this.setState({
      filteredDocuments: filterDocuments,
    });
  }

  downloadAllDocuments = () => {
    this.state.documentLinks.forEach(link => {
        window.open(link);
    });
  };
  
  makeDocumentReady(){
    const updatedFiles = this.state.filteredDocuments;
    const selectedFileIndex = updatedFiles.findIndex(file => file.filename === this.state.selectedFile.filename);
    if(selectedFileIndex > -1){
      updatedFiles[selectedFileIndex].type = 'Ready';
    }
    this.setState({
      filteredDocuments: updatedFiles,
    });
  }

  selectDocumentAction = (action: string, file: Document)=>{
    if(action === 'rename'){
      this.handleRenameDocument(file);
    }
    if(action === 'delete'){
      this.handleDeleteDocument(file);
    }
  }

  handleRenameDocument(file: Document){
    this.setState({
      edittingDocument: file
    })
  }

  renameFile = (event : any) => {
    let fileName = event.target.value;
    this.setState({
      edittingDocument:{
        ...this.state.edittingDocument,
        filename: fileName,
      }
    })
    if(event.key ==='Enter'){
      let fileName = this.state.edittingDocument.filename;
      if (!fileName.endsWith('.pdf')) {
        fileName += '.pdf';
      }
      this.setState({
        edittingDocument:{
          ...this.state.edittingDocument,
          filename: fileName,
        }
      }, this.handleRenameEdittingDocument)
    }
  }
  handleRenameEdittingDocument = () => {
    const formData = new FormData();
    formData.append('deal_document_id', this.state.edittingDocument.id);
    formData.append('document_name', this.state.edittingDocument.filename);

    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.renameFileApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/update_document/' + localStorage.getItem('createdDealId')
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  handleDownloadAllDocuments = (value: MenuAction) => {
    if(value === MenuAction.DownloadAllDocuments){
      const webHeader = {
        'Content-Type': 'application/json',
        token: localStorage.getItem('authToken'),
      };
      const webRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.downloadAllFilesApiId = webRequestMessage.messageId;
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_posts/deals/download_deal_documents/' + localStorage.getItem('createdDealId')
      );

      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(webHeader)
      );

      webRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
      return true;
    }
  }

  handleDeleteDocument(file: Document){
    const formData = new FormData();
    formData.append('deal[deal_document_id]', file.id);

    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadFilesApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/delete_deal_documents/' + localStorage.getItem('createdDealId')
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  uploadFiles(selectedFiles: File[]) {
    this.setState({showProgress: true});

    const formData = new FormData();
    formData.append('id', localStorage.getItem('createdDealId')!);
    selectedFiles.forEach(file => formData.append('deal_documents[]', file))

    const webHeader = {
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadFilesApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/upload_documents'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      selectedFiles: [],
      filteredDocuments: this.props.documents,
      selectedFile: {} as Document,
      isUploadingDocuments: false,
      showProgress: false,
      edittingDocument: {} as Document,
      documentLinks: []
    };

    this.handleFilterDocuments = this.handleFilterDocuments.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props) {
    if(prevProps.documents !== this.props.documents) {
      this.setState({filteredDocuments: this.props.documents});
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        switch(apiId){
          case this.uploadFilesApiId:
            this.setState({showProgress:false});
            this.props.getDealInformation();
            break;
          case this.renameFileApiId:
            this.setState({
              edittingDocument: {} as Document,
            })
            this.props.getDealInformation();
            break;
          case this.deleteFileApiId:
            this.props.getDealInformation();
            break;
          case this.downloadAllFilesApiId:
            this.setState({
              documentLinks: response.image_urls,
            });
            this.downloadAllDocuments();
            break;
        }
      }
      
    }
    // Customizable Area End
  }
}
