// Customizable Area Start
import React, { CSSProperties } from "react";

import { Box, Avatar, Typography, styled } from "@material-ui/core";

import DealershipsTableController, {
  Props,
} from "./DealershipsTableController.web";
import { ContentView, DealershipNormalized } from "./MyTeamController.web";
import { checkmark } from "./assets";
import moment from "moment";

export default class DealershipsTable extends DealershipsTableController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { data, handleCheck, isAdmin, handleDealershipEdit, showDealershipUsers, view } = this.props;
    const currentPageData = data.slice();    

    return (
      <StyledContainer>
        <Box className={isAdmin() ? "tableRow admin" : "tableRow user"}>
        {isAdmin() && view !== ContentView.DEALERSHIPS && <Box />}
          <Box className="thCell" style={{ justifyContent: "flex-start" }}>
            Dealerships
          </Box>
          <Box className="thCell" >Users</Box>
          <Box className="thCell" style={{ justifyContent: "flex-start" }}>
            Created on
          </Box>
          {isAdmin() && <Box style={{ justifyContent: "flex-end" }} className="thCell">Actions</Box>}
        </Box>
        {currentPageData.map((item: DealershipNormalized, i: number) => (
          <Box
            key={item.id}
            className={isAdmin() ? "tableRow admin" : "tableRow user"}
            style={{backgroundColor: item.checked ? "#F0FAFA" : "transparent"}}
          >
            {isAdmin() && view !== ContentView.DEALERSHIPS && <Box className="trCell" style={{ justifyContent: "flex-start" }}>
              <CheckboxContainer data-test-id={`checkbox-${i}`} onClick={() => handleCheck(item.id)}>
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={()=>{}}
                  className="checkbox"                  
                />
                <span className="checkmark">
                  <img src={checkmark} alt="checkmark" />
                </span>
              </CheckboxContainer>
            </Box>}

            <Box
              className="trCell"
              style={{
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <StyledAvatar src={item.logo} alt={item.name} variant="rounded" style={{backgroundColor: item.checked ? "#fff" : "#f7fcfc"}}>
                {item.name ? item.name.split(" ").map(word => word[0].toUpperCase()).join("").slice(0, 2) : ""}
              </StyledAvatar>
              <Typography onClick={() => {showDealershipUsers(item.id)}} data-test-id={`dealership-${i}`} className="name">{item.name}</Typography>
            </Box>
            <Box className="trCell">{item.users}</Box>
            <Box className="trCell" style={{ justifyContent: "flex-start" }}>
              {moment.utc(item.createdAt).local().format("MMMM D, YYYY h:mm a")}
            </Box>
            {isAdmin() && <Box className="trCell" style={{ justifyContent: "flex-end" }} >
              <Typography data-test-id={`edit-dealership-${i}`} className="actionButton" onClick={() => handleDealershipEdit(item.id)}>Edit</Typography>
            </Box>}
          </Box>
        ))}
      </StyledContainer>
    );
  }
}

const CheckboxContainer = styled(Box)({
  position: "relative",
  cursor: "pointer",
  width: "20px",
  height: "20px",
  border: "1px solid #C5CBC9",
  borderRadius: "6px",
  margin: 0,
  overflow: "hidden",

  "& .checkbox": {
    appearance: "none",
  },

  "& .checkmark": {
    position: "absolute",
    top: "0",
    left: "0",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "#4FC8EC",
  },

  "& input:checked + span": {
    display: "flex",
  },
});

const StyledContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",

  "& .thCell": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#C5CBC9",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  "& .tableRow": {
    display: "grid",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 0 12px 25px",

    "&.admin": {
      gridTemplateColumns: "20% 10% 20% 40%",
    },

    "&.user": {
      gridTemplateColumns: "20% 20% 1fr",
    }    
  },

  "& .trCell": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",

    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  "& .name": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    cursor: 'pointer',
  },

  "& .actionButton": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    cursor: "pointer",
  }
}))

const StyledAvatar = styled(Avatar)(({theme}) => ({
  width: "48px",
  height: "48px",
  borderRadius: "12px",
  backgroundColor: "#f7fcfc",
  color: "#4B4C4B",
}))


// Customizable Area End
