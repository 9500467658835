import React from "react";
// Customizable Area Start
import { Box, Avatar, Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  vitu,
  home,
  myTeam,
  allDeals,
  notifications,
  menu,
} from "./assets";
import { DashboardView } from "./DashboardController.web";
import MyTeam from "./MyTeam.web";
import ServiceProviderNotification from "../../pushnotifications/src/ServiceProviderNotification.web"
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentView, userData, showInviteUser } = this.state;    

    return (
      //Merge Engine DefaultContainer
      <Box>
        <ServiceProviderNotification navigation={this.props.navigation} id=""/>
        <Box sx={webStyles.container}>
          <Box sx={webStyles.header}>
            <Box sx={webStyles.headerLeftSide}>
              <button data-test-id="menu" style={webStyles.menuButton}>
                <img src={menu} alt="menu button" />
              </button>

              <Box sx={webStyles.logo}>
                <img src={vitu} width={52} height={24} alt="vitu logo" />
              </Box>
            </Box>

            <Box sx={webStyles.headerRightSide}>
              {currentView === DashboardView.MY_TEAM && userData?.attributes.role === "dealer_admin" && (
                <>
                  <button
                    onClick={this.navigateToCreateNewDealership}
                    data-test-id="create-dealership"
                    type="button"
                    style={webStyles.createDealershipButton}
                  >
                    Create Dealership
                  </button>

                  <button
                    data-test-id="invite-user"
                    type="button"
                    style={webStyles.inviteUserButton}
                    onClick={() => this.setShowInviteUser(true)}
                  >
                    Invite User
                  </button>
                </>
              )}

              <Box
                data-test-id="notifications"
                sx={webStyles.notificationsButton}
              >
                <img src={notifications} alt="notifications button" />
              </Box>

              <Box style={webStyles.userAvatar}>
                <Avatar
                  className="avatar"
                  src={userData?.attributes?.profilePicture || ""}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "24px",
                    textTransform: "uppercase",
                  }}
                >
                  {userData?.attributes?.fullName
                    .split(" ")
                    .map((string: string) => string[0])
                    .join("")}
                </Avatar>
              </Box>
            </Box>
          </Box>

          <Box sx={webStyles.main}>
            <Box sx={webStyles.sidebar}>
              <SidebarButton
                data-test-id="home-button"
                onClick={this.navigateToDashboard}
                style={{
                  background: this.getStyle(
                    currentView === DashboardView.HOME,
                    "#E8F8FD",
                    "transparent"
                  ),
                }}
              >
                <img src={home} alt="home button" />
                <span style={webStyles.buttonText}>Home</span>
              </SidebarButton>

              <SidebarButton
                data-test-id="deals-button"
                onClick={this.navigateToAllDeals}
                style={{
                  background: this.getStyle(
                    currentView === DashboardView.ALL_DEALS,
                    "#E8F8FD",
                    "transparent"
                  ),
                }}
              >
                <img src={allDeals} alt="deals button" />
                <span style={webStyles.buttonText}>All Deals</span>
              </SidebarButton>

              <SidebarButton
                data-test-id="team-button"
                onClick={this.navigateToMyTeam}
                style={{
                  background: this.getStyle(
                    currentView === DashboardView.MY_TEAM,
                    "#E8F8FD",
                    "transparent"
                  ),
                }}
              >
                <img src={myTeam} alt="team button" />
                <span style={webStyles.buttonText}>My Team</span>
              </SidebarButton>
            </Box>

            <Box sx={webStyles.content}>
              {currentView === DashboardView.HOME && (
                <Box style={webStyles.blankScreenContainer}>
                  <Typography style={webStyles.blankScreenText}>
                    Work in progress
                  </Typography>
                </Box>
              )}
              {currentView === DashboardView.MY_TEAM && (
                <MyTeam
                  userData={userData}
                  showInviteUser={showInviteUser}
                  setShowInviteUser={this.setShowInviteUser}
                  updateGroupName={this.updateGroupName}
                  navigateToEditDealership={this.navigateToEditDealership}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SidebarButton = styled(Button)({
  width: "80px",
  height: "65px",
  cursor: "pointer",

  "& > span": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textTransform: "capitalize",
  },
});

interface Styles {
  [key: string]: any;
}

const webStyles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    height: "69px",
    boxSizing: "border-box",
    padding: "0 32px",
    borderBottom: "1px solid #F0FAFA",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
  },

  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },

  logo: {
    width: "52px",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },

  createDealershipButton: {
    display: "flex",
    width: "161px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#013D4F",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  inviteUserButton: {
    display: "flex",
    width: "110px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#4FC8EC",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  main: {
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
  },

  sidebar: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0",
    gap: "32px",
    width: "120px",
    height: "100vh",
    borderRight: "1px solid #F0FAFA",
  },

  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
  },

  content: {
    width: "100%",
    marginLeft: "120px",
  },

  blankScreenContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  blankScreenText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "30px",
    fontWeight: 700,
  },
};
// Customizable Area End
