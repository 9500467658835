import React from "react";
// Customizable Area Start
import { Box,
  Button,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  Typography,
  styled,
  TextField,
  MenuItem,
  createTheme,
  Grid,
  AppBar,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Input,
  TablePagination,
  CircularProgress,
  Tooltip,
  MuiThemeProvider
 } from "@material-ui/core";
 import SearchIcon from '@material-ui/icons/Search';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
 import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
 import {settingIcon} from "../src/assets";
 import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
 import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
 import {TableRows, TablePendingDataSp, DeletedDatatableSp, RejectedOrExpiredData, TableDataAcceptedSp, TablePendingDataRow, TableDataDeletedorCompleted, TableDraftDataRow, } from './AlldealsController'


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const tooltipTheme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          padding: "8px",
          paddingLeft:'15px',
          paddingRight:'15px',
          backgroundColor: "#FFFFFF",
          boxShadow: "0 18px 32px 0 rgba(0, 0, 0, 0.25)",
          borderRadius:'8px',
          textAlign:'center'
        },
        arrow: {
          color: "#FFFFFF", 
        },
      },
    }
  })
// Customizable Area End

import BlockController, {
  Props,
} from "./AlldealsController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box></Box>

        <Box sx={webStyles.headerRightSide}>
          <Button variant="contained" style={{backgroundColor:'rgba(79, 200, 236, 1)', borderRadius:'8px',boxShadow:'none', display: this.checkRole() ? 'block' : 'none'}}>
            <Typography style={{color:'white', fontFamily:'Roboto', fontWeight:700, fontSize:'16px', textTransform:'none'}}>
              Create deal
            </Typography>
          </Button>

          <Box data-test-id="" sx={webStyles.notificationsButton}>
            <NotificationsOutlinedIcon/>
          </Box>

          <Box style={webStyles.userAvatar}>
            <Avatar
              className="avatar"
              variant="square"
              src={ ''}
              style={{
                width: "102%",
                height: "102%",
                backgroundColor: 'rgb(240, 250, 250)', 
                borderRadius: "24px",   
                color: '#013D4F'
              }}
            >
            </Avatar>
          </Box>
        </Box>
      </Box>
    )
  }

  tootltipStatus = ()=>{
    return(
      <MuiThemeProvider theme={tooltipTheme}>
        <Tooltip placement="right" title={<><p style={{color:"#4B4C4B", fontFamily:'Roboto', fontWeight:500, fontSize:'14px'}}>Current deal status</p></>} arrow>
          <InfoOutlinedIcon style={{height:'20px', color:'#C5CBC9'}}/>
        </Tooltip> 
      </MuiThemeProvider>
    )
  }

getStatusStyle = (status: string): React.CSSProperties => {
    switch (status) {
      case 'In Progress':
        return { display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #346472',color: '#346472', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
      case 'Issues Sent to Dealer':
        return { display: 'inline-block',whiteSpace: 'nowrap', border:'1px solid #FCCC0F',color: '#FCCC0F', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
      case 'Resolved, In Dealer Review':
        return { display: 'inline-block',whiteSpace: 'nowrap', border:'1px solid #4B4C4B',color: '#4B4C4B', padding: '6px 12px',fontFamily:'Roboto', fontSize:'14px', borderRadius: '26px', fontWeight: 500 };
    case '-':
        return{paddingLeft:'50px'}
    case 'Dealer Flags Incomplete':
        return { display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #C43937',color: '#C43937', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    case 'Completed':
        return { display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #26BBB3',color: '#26BBB3', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    default:
        return {};
    }
  };

  getStatusPendingStyle = (status: string) => {
    if(status==='Rejected'){
        return { color: '#F05E22', fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    }
    else if(status==='No Response'){
        return { color: '#475569',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    }
  };


  TabPanel(props : TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

ongoingTable = ()=>{
    return(
        <TableContainer  component={Box} style={{ width:'100%', overflowX:'auto',}}>
        <Box style={{overflowX: 'auto', width: '100%',}}>
          <Table aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead >
                <TableRow >
                  <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name </TabCellHeader>
                  <TabCellHeader>Dealership</TabCellHeader>
                  <TabCellHeader>States</TabCellHeader>
                <TabCellHeader>Date Created</TabCellHeader>

                <TabCellHeader>Date Accepted</TabCellHeader>
                <TabCellHeader>Service</TabCellHeader>
                <TabCellHeader>Fees</TabCellHeader>
                <TabCellHeader>Service Provider</TabCellHeader>
                <TabCellHeader style={{ borderRight:'none'}}> 
                    <Box 
                    style={{display:'flex', alignItems:'center'}}>
                        <Typography style={{fontFamily: 'Roboto', fontSize:'14px', color: '#C5CBC9',fontWeight:700,}}>Status </Typography>
                        {this.tootltipStatus()}
                    </Box> 
                </TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>
            {this.state.tableData.map((deal: TableRows, index:number) =>(
              <StyledTableRow  key={index}>
                <TabCells style={{borderLeft:'none'}}> {deal.id}</TabCells>
                <TabCells>{deal.customerLastName}</TabCells>
                <TabCells>{deal.dealership}</TabCells>
                <TabCells>{deal.states}</TabCells>

                <TabCells>{deal.dateCreated}</TabCells>
                <TabCells style={(deal.dateAccepted==='-') ? {textAlign: 'center', color:'#C5CBC9'}:{}}> { deal.dateAccepted }</TabCells>
                <TabCells style={(deal.service==='-')?{textAlign: 'center', color:'#C5CBC9'}:{}}>{deal.service}</TabCells>
                <TabCells> { deal.fees }</TabCells>
                <TabCells> 
                {deal.service && deal.service !== "-" ? (deal.service) : (
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                    >
                        <Box style={{display:'flex', alignItems:'center', gap:'5px'}}>
                            <Typography style={{textTransform:'none', fontSize:'16px', color:'#4FC8EC', fontWeight:700, fontFamily:'Roboto'}}> Hire</Typography>
                            <ArrowForwardIosIcon style={{height:'15px', color:'#4FC8EC'}}/>
                        </Box>
                    </Button>
                    )}
                </TabCells>
                <TabCells  style={{borderRight:'none'}}>
                    <Typography style={this.getStatusStyle(deal.status)}>
                        {deal.status}
                    </Typography>
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>

          </Table>
          </Box>
      </TableContainer>
    )
}  

pendingTable = ()=>{
    return(
        <TableContainer component={Box} style={{ width:'100%',  overflowX: 'auto',}}>
        <Box style={{overflowX: 'auto', width: '100%',  }}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}> Deal ID</TabCellHeader>
              <TabCellHeader> Customer's Last Name </TabCellHeader>
              <TabCellHeader>Dealership </TabCellHeader>
              <TabCellHeader> States</TabCellHeader>
              <TabCellHeader>Date Sent </TabCellHeader>
              <TabCellHeader> Service</TabCellHeader>
              <TabCellHeader> Fees</TabCellHeader>
              <TabCellHeader> Service Provider</TabCellHeader>
              <TabCellHeader style={{borderRight:'none'}}> 
                <Box style={{display:'flex', alignItems:'center'}}>
                    <Typography style={{fontFamily: 'Roboto', fontSize:'14px', color: '#C5CBC9', fontWeight:700, }}>Status</Typography>
                    {this.tootltipStatus()}
                </Box> 
            </TabCellHeader>
            <TabCellHeader>Actions</TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>

            {this.state.tablePendingData.map((deal:TablePendingDataRow, index:number) => (
              <StyledTableRow className="tablehover" key={index}>
                <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                <TabCells> { deal.customerLastName } </TabCells>
                <TabCells> { deal.dealership} </TabCells>
                <TabCells> { deal.states } </TabCells>
                <TabCells> {deal.dateSent }</TabCells>
                <TabCells>{deal.service}</TabCells>
                <TabCells> { deal.fees }</TabCells>
                <TabCells> { deal.serviceProvider }</TabCells>
                <TabCells  style={{borderRight:'none'}}>
                    <Typography style={this.getStatusPendingStyle(deal.status)}>
                        {deal.status}
                    </Typography>
                </TabCells>
                <TabCells>
                    <Box style={{display:'flex', alignItems:'center', gap:'30px'}}>
                        <Button disabled={this.checkDisablitiy(deal.status, 'withdraw')} variant="text"><Typography style={{textTransform:'none' , fontSize:'14px', fontWeight:700, fontFamily:'Roboto', color:this.checkDisablitiy(deal.status, 'withdraw') ? '#C5CBC9' : '#013D4F'}}>Withdraw</Typography></Button>
                        <Button disabled={this.checkDisablitiy(deal.status, 'hirenew')} variant="text"><Typography style={{textTransform:'none', color:this.checkDisablitiy(deal.status, 'hirenew') ? '#C5CBC9' : '#4FC8EC', fontSize:'14px', fontWeight:700, fontFamily:'Roboto', }}>Hire New</Typography></Button>
                    </Box>
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
} 

deletedOrCompletedTable = ()=>{
  const tablevalues = this.state.tabvalue===2 ? this.state.tableDataDeleted : this.state.tableDataCompleted
    return(
        <TableContainer  style={{ width:'100%', overflowX: 'auto',}} component={Box} >
        <Box style={{overflowX: 'auto', width: '100%'  ,}}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX:'auto', }}>
        < TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}> Deal ID </TabCellHeader>
                <TabCellHeader> Customer's Last Name</TabCellHeader>
              <TabCellHeader >Dealership </TabCellHeader>
                <TabCellHeader> States</TabCellHeader >
              <  TabCellHeader>Date Created </TabCellHeader>
              <TabCellHeader> {this.state.tabvalue==2 ? 'Date Cancelled' : 'Date Completed'}</TabCellHeader>
              <TabCellHeader> Service</TabCellHeader >
              <TabCellHeader>Fees</TabCellHeader >
              <TabCellHeader> Service Provider </TabCellHeader>
              <TabCellHeader style={{borderRight:'none',}}> 
                <Box style={{display:'flex', alignItems:'center',}}>
                    <Typography style={{fontFamily: 'Roboto',  fontSize:'14px', color: '#C5CBC9',fontWeight:700, }}> Status</Typography>
                   {this.tootltipStatus()}
                </Box> 
            </TabCellHeader>
        </TableRow>
            </TableHead>
          <TableBody>

            {tablevalues.map((deal:any, index:number) => (
              <StyledTableRow  key={index}>
                <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                <TabCells>{ deal.customerLastName } </TabCells>
                <TabCells>{ deal.dealership} </TabCells>
                <TabCells>{ deal.states } </TabCells>
                <TabCells>{deal.dateCreated } </TabCells>
                <TabCells>{ deal.dateCancelled }</TabCells>
                <TabCells>{deal.service} </TabCells >
                <TabCells>{ deal.fees }</TabCells>
                <TabCells>{ deal.serviceProvider }</TabCells>
                <TabCells  style={{borderRight:'none'}}>
                {this.state.tabvalue==2 ? 
                    <Typography style={{color:'#C5CBC9', fontFamily:'Roboto', fontWeight:500, fontSize:'14px'}}>
                        Deleted
                    </Typography>
                : <span style={{border:'1px solid #26BBB3', color:'#26BBB3', fontSize:'14px', padding:'6px 12px', fontWeight:400, fontFamily:'Roboto', borderRadius:'26px'}}>Completed</span>}
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
}  

draftTable = ()=>{
    return(
        <TableContainer component={Box} style={{ width:'100%',  overflowX: 'auto',}}>
        <Box style={{overflowX: 'auto', width: '100%',  }}>
          <Table  aria-label="sticky table" stickyHeader style={{overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                    <TabCellHeader style={{ borderLeft:'none'}}> Deal ID</TabCellHeader >
                    <TabCellHeader>Customer's Last Name </TabCellHeader>
                    <TabCellHeader>Dealership </TabCellHeader>
                    <TabCellHeader > States</TabCellHeader>
                    <TabCellHeader >Date Created </TabCellHeader>
                    <TabCellHeader > Type of Vehicle</TabCellHeader>
                    <TabCellHeader > Application Type</TabCellHeader>
                    <TabCellHeader style={{borderRight:'none'}}>Actions</TabCellHeader>
                </TableRow>
             </TableHead>
           <TableBody>
            {this.state.tableDataDraft.map((deal:TableDraftDataRow, index:number) => (
              <StyledTableRow className="tablehover"  key={index}>
                < TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
                < TabCells> { deal.customerLastName } </TabCells>
                < TabCells> { deal.dealership} </TabCells>
                < TabCells> { deal.states } </TabCells>
                < TabCells> {deal.dateCreated }</TabCells>
                <TabCells> { deal.typeOfVehicle }</TabCells>
                <TabCells> { deal.applicationType }</TabCells>
                <TabCells style={{borderRight:'none'}}> 
                    <Button variant="text">
                        <Box style={{display:'flex', alignItems:'center', gap:'5px'}}>
                            <Typography style={{textTransform:'none', fontSize:'16px', color:'#4FC8EC', fontWeight:700, fontFamily:'Roboto'}}> Continue</Typography>
                            <ArrowForwardIosIcon style={{height:'15px', color:'#4FC8EC'}}/>
                        </Box>
                    </Button>
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
}


acceptedTableSp = ()=>{
  return(
      <TableContainer component={Box }  style={{ width:'100%', overflowX:'auto',}}>
      <Box style={{overflowX: 'auto', width: '100%' }}>
        <Table aria-label="sticky table" stickyHeader style={{overflowX: 'auto'}}>
          < TableHead>
              <TableRow>
                  <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name </TabCellHeader>
                  <TabCellHeader>Dealership</TabCellHeader>
                  <TabCellHeader>States</TabCellHeader>
              <TabCellHeader>Date Received</TabCellHeader>
              <TabCellHeader>Date Accepted</TabCellHeader>
              <TabCellHeader>Service</TabCellHeader>
              <TabCellHeader>Registration fees</TabCellHeader>
              <TabCellHeader>Payout</TabCellHeader>
              <TabCellHeader style={{ borderRight:'none'}}> 
                  <Box 
                  style={{display:'flex', alignItems:'center'}}>
                      <Typography style={{fontFamily: 'Roboto', color: '#C5CBC9', fontWeight:700, fontSize:'14px'}}>Status</Typography>
                      {this.tootltipStatus()}
                  </Box> 
              </TabCellHeader>
          </TableRow>
          </TableHead>
        <TableBody>
          {this.state.tableDataAcceptedSp.map((deal:TableDataAcceptedSp, index:number) =>(
            <StyledTableRow  key={index}>
              <TabCells style={{borderLeft:'none'}}> {deal.id}</TabCells>
              <TabCells>{deal.customerLastName}</TabCells>
              <TabCells>{deal.dealership}</TabCells>
              <TabCells>{deal.states}</TabCells>
              <TabCells>{deal.dateReceived}</TabCells>
              <TabCells> { deal.dateAccepted }</TabCells>
              <TabCells>{deal.service}</TabCells>
              <TabCells> { deal.fees }</TabCells>
              <TabCells>{ deal.payout }</TabCells>
              <TabCells  style={{borderRight:'none'}}>
                  <Typography style={this.getStatusStyle(deal.status)}>
                      {deal.status}
                  </Typography>
              </TabCells>
            </StyledTableRow>
          ))
          }
          </TableBody>
        </Table>
        </Box>
    </TableContainer>
  )
} 

pendingTableSp=()=>{
  return(

    <TableContainer style={{ 
     width:'100%', 
     overflowX: 'auto',
     }} 
    component={Box} >
    <Box style={{overflowX: 'auto', width: '100%',  } }>
      <Table  aria-label="sticky table" stickyHeader  style={{ overflowX: 'auto'}}>
        < TableHead >
          <TableRow >
          <TabCellHeader style={{borderLeft:'none'}}> Deal ID</ TabCellHeader>
          <TabCellHeader> Customer's Last Name</TabCellHeader>
          <TabCellHeader >Dealership </ TabCellHeader>
          < TabCellHeader >States</TabCellHeader>
          <TabCellHeader>Date Received </TabCellHeader>
          <TabCellHeader> Service</TabCellHeader>
          <TabCellHeader> Payout</TabCellHeader>
          <TabCellHeader style={{borderRight:'none'}}> 
            <Box style={{display:'flex', alignItems:'center'}}>
                <Typography style={{fontFamily: 'Roboto', color: '#C5CBC9',fontSize:'14px', fontWeight:700, }}>Status</Typography>
                {this.tootltipStatus()}
            </Box> 
        </TabCellHeader>
        <TabCellHeader>Actions</TabCellHeader>
        </TableRow>
        </TableHead>
      <TableBody>

        {this.state.tablePendingDataSp.map((deal:TablePendingDataSp, index:number) => (
          <StyledTableRow className="tablehover" key={index}>
            <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
            <TabCells> { deal.customerLastName } </TabCells>
            <TabCells> { deal.dealership} </TabCells>
            <TabCells> { deal.states } </TabCells>
            <TabCells> {deal.dateReceived }</TabCells>
            <TabCells>{deal.service}</TabCells>
            <TabCells> { deal.payout }</TabCells>
            <TabCells  style={{borderRight:'none'}}>
                <Typography style={{ display: 'inline-block',  backgroundColor:'#FCCC0F',whiteSpace: 'nowrap',color: 'white', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 }}>
                    {deal.status}
                </Typography>
            </TabCells>
            <TabCells>
                <Box style={{display:'flex', alignItems:'center', gap:'30px'}}>
                    <Button variant="text"><Typography style={{textTransform:'none' , fontSize:'14px', fontWeight:700, fontFamily:'Roboto', color:'#26BBB3'}}>Accept</Typography></Button>
                    <Button variant="text"><Typography style={{textTransform:'none', color: '#C43937', fontSize:'14px', fontWeight:700, fontFamily:'Roboto', }}>Reject</Typography></Button>
                </Box>
            </TabCells>
          </StyledTableRow>
        ))
        }
        </TableBody>
      </Table>
      </Box>
  </TableContainer>
)
}

rejectOrExpiredTableSp=()=>{
  return(
    <TableContainer style={{width:'100%',   overflowX: 'auto',}}  component={ Box }>
    <Box style={{overflowX: 'auto', width: '100%',  } }>
      <Table  aria-label="sticky table"  stickyHeader  style={{ overflowX: 'auto'}}>
        <TableHead >
          < TableRow >
              < TabCellHeader style={{borderLeft:'none'}}> Deal ID</TabCellHeader >
                < TabCellHeader> Customer's Last Name </TabCellHeader >
              < TabCellHeader>  Dealership </TabCellHeader >
              < TabCellHeader>States</TabCellHeader >
          <TabCellHeader>Date Created </TabCellHeader>
          <TabCellHeader>Date Rejected or Expired </TabCellHeader>
          <TabCellHeader> Service</TabCellHeader>
          <TabCellHeader> Payout</TabCellHeader>
          <TabCellHeader style={{borderRight:'none'}}> 
            <Box style={{display:'flex', alignItems:'center'}}>
                <Typography style={{fontFamily: 'Roboto', color: '#C5CBC9',fontSize:'14px', fontWeight:700, }}>Status</Typography>
                {this.tootltipStatus()}
            </Box> 
        </TabCellHeader>
        </TableRow>
        </TableHead>
      <TableBody>

        {this.state.tableRejectedorExpiredDataSp.map((deal:RejectedOrExpiredData, index:number) => (
          <StyledTableRow className="tablehover" key={index}>
            <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
            <TabCells> { deal.customerLastName } </TabCells>
            <TabCells> { deal.dealership} </TabCells>
            <TabCells> { deal.states } </TabCells>
            <TabCells> {deal.dateCreated }</TabCells>
            <TabCells> {deal.dateRejectedOrExpired }</TabCells>
            <TabCells>{deal.service}</TabCells>
            <TabCells>{ deal.payout }</TabCells>
            <TabCells  style={{borderRight:'none'}}>
                <Typography style={{ color: deal.status==='Rejected'? '#F05E22' : '#C5CBC9', fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 }}>
                    {deal.status}
                </Typography>
            </TabCells>
          </StyledTableRow>
        ))
        }
        </TableBody>
      </Table>
      </Box>
  </TableContainer>
)
}

deletedTableSp = ()=>{
  return(
      <TableContainer component={Box} style={{ width:'100%', overflowX: 'auto',}}>
      <Box style={{overflowX: 'auto', width: '100%'  }}>
        <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto', }}>
        <TableHead>
          <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}>Deal ID</ TabCellHeader>
              <TabCellHeader>Customer's Last Name</ TabCellHeader>
              <TabCellHeader>Dealership </ TabCellHeader>
              <TabCellHeader>States</ TabCellHeader >
              <TabCellHeader>Date Created</ TabCellHeader>
            <TabCellHeader>Date Deleted</TabCellHeader>
            <TabCellHeader> Service</TabCellHeader >
            <TabCellHeader>Registration Fees</TabCellHeader >
            <TabCellHeader>Payout </TabCellHeader>
            <TabCellHeader style={{borderRight:'none',}}> 
              <Box style={{display:'flex', alignItems:'center',}}>
                  <Typography style={{fontFamily: 'Roboto', fontSize:'14px', color: '#C5CBC9',fontWeight:700, }}> Status</Typography>
                  {this.tootltipStatus()}
              </Box> 
          </TabCellHeader>
      </TableRow>
          </TableHead>
        <TableBody>

          {this.state.deletedDatatableSp.map((deal:DeletedDatatableSp, index:number) => (
            <StyledTableRow  key={index}>
              <TabCells style={{borderLeft:'none'}}>{deal.id}</TabCells>
              <TabCells>{ deal.customerLastName } </TabCells>
              <TabCells>{ deal.dealership} </TabCells>
              <TabCells>{ deal.states } </TabCells>
              <TabCells>{deal.dateCreated } </TabCells>
              <TabCells>{ deal.dateDeleted }</TabCells>
              <TabCells>{deal.service} </TabCells >
              <TabCells>{ deal.fees }</TabCells>
              <TabCells>{ deal.payout }</TabCells>
              <TabCells  style={{borderRight:'none'}}>
                <Typography style={{ display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #C43937',color: '#C43937', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500  }}>
                  {deal.status}
                </Typography>
              </TabCells>
            </StyledTableRow>
          ))
          }
          </TableBody>
        </Table>
        </Box>
    </TableContainer>
  )
}  


tabsHeaders = ()=>{
  if(this.checkRole()){
    return [
      `On Going (${this.state.ongoing_total})`,
      `Pending Hire (${this.state.pending_hire_total})`,
      `Deleted (${this.state.deleted_total})`,
      `Completed (${this.state.completed_total})`,
      `Drafts (${this.state.draft_total})`
  ];
  }
  else{
    return [
      `Accepted (${this.state.accepted_total})`,
      `Pending (${this.state.pending_hire_total})`,
      `Rejected or Expired (${this.state.rejected_or_expired_total})`,
      `Deleted (${this.state.deleted_total})`,
    ]
  }
}

tabel = ()=>{
    return(
    <>
     <style>
        {`
          .customTab {
            color: rgba(1, 61, 79, 1); 
            font-size: 16px;
            font-weight:500;
            font-family:Roboto;
            text-transform:none;
            min-width: auto;
          }

          .Mui-selected.customTab {
            color: rgba(1, 61, 79, 1); 
          }

          .customIndicator {
            background-color:rgba(1, 61, 79, 1);
          }
        `}
      </style>
    <AppBar position="static" color="default" style={{ backgroundColor:'white', boxShadow:'none', marginTop:'20px'}}>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 16px', marginBottom:'-30px'}}>
            <Tabs
                value={this.state.tabvalue}
                data-test-id="tabs"
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                classes={{
                indicator: 'customIndicator', 
                }}
                aria-label="full width tabs example"
                variant="scrollable" 
                scrollButtons="auto"
                >
                {this.tabsHeaders().map((label, index) => (
                <Tab
                    key={index}
                    label={label}
                    {...this.a11yProps(index)}
                    classes={{ root: 'customTab' }}
                    style={{
                        color: this.state.tabvalue === index ? '#013D4F' : '#C5CBC9',
                        margin: '0 20px',
                        fontFamily: 'Roboto', 
                        fontWeight: this.state.tabvalue === index ? 600 : 700, 
                        fontSize:'16px',
                        paddingBottom: '25px'
                    }}
                />
                ))}
            </Tabs>

            <Box style={{ display: 'flex', alignItems: 'start', gap: 1,}}>
                <Box style={{marginTop:'10px'}}>
                    <StyledTextField
                    variant="standard"
                    value={this.state.searchValue}
                    data-test-id="searchField"
                    style={{maxWidth:'177px'}}
                    placeholder="Search in deals"
                    size="small"
                    onChange={this.handleSearch}
                    InputProps={{
                        disableUnderline:true,
                        startAdornment: (
                        <SearchIcon style={{marginRight:'7px', color:'#C5CBC9', height:'25px'}}/>
                    ),
                    }}
                    />
                </Box>
                
                <Box>
                    <FormControl style={{width:'100px', marginTop:'-10px'}}>
                        <InputLabel id="demo-simple-select-label" style={{color:"#013D4F", fontSize:'14px', fontWeight:500}}>Sort By</InputLabel>
                        <Select
                            value={this.state.sortOption}
                            data-test-id="selectoption"
                            onChange={(event)=>this.handleSort(event)}
                            label="Sort By"
                            input={<Input disableUnderline />}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null,
                            }}
                            IconComponent={(props) => (
                                <ExpandMoreIcon {...props} style={{ color: '#013D4F' }} />
                            )}
                            >
                                {this.getSortOptions(this.state.tabvalue).map(option => (
                                  <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                 

                <IconButton aria-label="filter">
                    <img src={settingIcon} style={{height:'20px', width:'auto'}}/>
                </IconButton>
                
                <style>
               {` .custom-pagination-actions .MuiIconButton-root {
                    padding: 4px; 
                    margin: 0 2px; 
                }`}`
                </style>
                <TablePagination
                data-test-id="pagination"
                style={{
                  marginTop:'-5px',
                  color: '#4B4C4B', 
                  fontSize: '14px',
                  fontWeight:500,
                  fontFamily:'Roboto'
                }}
                rowsPerPageOptions={[]}
                component="div"
                count={this.state.tables[this.state.tabvalue].totalRecords}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.tables[this.state.tabvalue].page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${this.state.tables[this.state.tabvalue].page + 1} of ${Math.ceil(count / this.state.rowsPerPage)}`}
                classes={{
                    actions: 'custom-pagination-actions'
                  }}
                />
          </Box>
        </Box>
    </AppBar>

    <this.TabPanel value={this.state.tabvalue} index={0}>
        {this.checkRole() ? this.ongoingTable() : this.acceptedTableSp()}
    </this.TabPanel>
    <this.TabPanel  value={this.state.tabvalue} index={1}>
        {this.checkRole() ? this.pendingTable() : this.pendingTableSp()}
    </this.TabPanel>
    <this.TabPanel value={this.state.tabvalue} index={2}>
        {this.checkRole() ? this.deletedOrCompletedTable() : this.rejectOrExpiredTableSp()}
    </this.TabPanel>
    <this.TabPanel data-test-id="trackingDetailsTab" value={this.state.tabvalue} index={3}>
        {this.checkRole() ? this.deletedOrCompletedTable() : this.deletedTableSp()}
    </this.TabPanel>
    <this.TabPanel value={this.state.tabvalue} index={4}>
        {this.checkRole() && this.draftTable()}
    </this.TabPanel>
    </>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
      <Box height={'100vh'} overflow={'auto'}>
        <Box className="headerrrr" >
            {this.headerr()}
        </Box>

        {this.state.loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        ) :
        <>
        <Grid className="miancontainer" style={{marginLeft:'20px', marginTop:'20px'}}>
            <Box className="title">
                <Typography style={{fontSize:'32px', fontFamily:'Gotham light', color:'#013D4F', fontWeight:300}}>All deals({this.state.totaldeals})</Typography>
                <Typography style={{fontSize:'18px', fontFamily:'Gotham', color:'#4B4C4B', fontWeight:300}}>All deals with customizable filters to meet your needs.</Typography>
            </Box>

            <Grid className="table" style={{marginTop:'45px'}}>
                {this.tabel()}
            </Grid>
        </Grid>
        </>}
      </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const StyledTableRow = styled(TableRow)({
    
  });

const TabCellHeader = styled(TableCell)({
    fontFamily: 'Roboto', color: '#C5CBC9', border:'1px solid #F0FAFA', fontWeight:700, fontSize:'14px', backgroundColor:'white'
})
 
const TabCells = styled(TableCell)({
    border:'1px solid #F0FAFA', fontFamily: 'Gotham',color: '#191A19', fontWeight:400, fontSize:'16px'
})
const StyledTextField = styled(TextField)({
    '& input::placeholder': {
      color: '#C5CBC9',
      fontSize: '14px',
      opacity: 1,
      fontWeight:500
    },
    '& input': {
      fontSize: '14px', 
    }
  });

interface Styles {
    [key: string]: any;
  }
  const webStyles: Styles = {
    container: {
    display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    header: {
        display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      borderBottom: "1px solid #F0FAFA",
      justifyContent: "space-between",
      padding: "12px 32px",
    },
    notificationsButton: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      width: "24px",
      justifyContent: "center",
      height: "24px",
      cursor: "pointer",
    },
    headerRightSide: {display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "24px",
      
    },
    userAvatar: {
      height: "40px",
      borderRadius: "50%",
      width: "40px",
    },
    main: {
      display: "flex",
      position: "relative",
      flexWrap: "nowrap",
    },
  
  }
// Customizable Area End