import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"

export interface GetUserResponseSuccess {
    data: Data;
  }
  export interface Data {
    id: string;
    type: string;
    attributes: Attributes;
  }
  export interface Attributes {
    activated: boolean;
    country_code: string;
    email: string;
    full_phone_number: string;
    phone_number: string;
    type: string;
    full_name: string;
    created_at: string;
    updated_at: string;
    is_dealer_group: boolean;
    dealer_group_name?: null;
    role: string;
    profile_picture?: null;
    group_created_on?: null;
    is_deal_pending: boolean;
    deal_data?: null;
    solo_dealership_id: number;
  }
  
  interface GetUserError {
    errors:[
      {
        token:string;
      }
    ]
  }
export   interface TableDataAcceptedSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateReceived: string;
  dateAccepted: string;
  service: string;
  fees: string;
  payout: string;
  status: string;
}

export interface TableRows {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateAccepted?: string;
  service?: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TablePendingDataRow {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateSent: string;
  service: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TableDataDeletedorCompleted{
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateCancelled?: string;
  service?: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TableDraftDataRow {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  typeOfVehicle: string;
  applicationType: string;
}

export interface TablePendingDataSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateReceived: string;
  service: string;
  payout: string;
  status: string;
}


export interface DeletedDatatableSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateDeleted: string;
  service: string;
  fees: string;
  payout: string;
  status: string;
}
export interface RejectedOrExpiredData {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateRejectedOrExpired: string;
  service: string;
  payout: string;
  status: string; 
}

export interface ResponseBESp{
  accepted_total?: number,
  rejected_or_expired_total?: number,
  pending_total?: number,
  deleted_total?: number,
  total_deals: number;

  accepted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  rejected_or_expired: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  deleted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  pending: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
}
export interface ResponseDataBE {
  total_deals: number;
  ongoing_total?: number,
  pending_hire_total?: number,
  deleted_total?: number,
  draft_total?: number,
  completed_total?: number,

  ongoing: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  pending_hire: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  deleted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  completed: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  draft: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
}
export interface OngoingOrPendingHireOrDeletedOrCompletedOrDraft {
  data?: (DataEntity)[] | null;
}
export interface DataEntity {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  id: number;
  updated_at: string;
  application_type: string;
  registration_type: string;
  vehicle_vin: string;
  vehicle_type: string;
  vehicle_year: string;
  vehicle_weight_empty: string;
  vehicle_weight_gross: string;
  sale_type: string;
  sold_as: string;
  msrp: string;
  tsp: string;
  seller_zip: string;
  owner_first_name: string;
  owner_last_name: string;
  purchaser_zip: string;
  first_owner?: null;
  second_owner?: null;
  make?: null;
  odometer?: null;
  body_type?: null;
  is_draft: boolean;
  deal_documents?: (null)[] | null;
  date_created: string;
  deal_status: string;
  from_state: string;
  to_state: string;
  dealership_detail: DealershipDetail;
  account: Account;
  deal_documents_ids?: (null)[] | null;
  fees_data?: null;
  service_provider?: null;
  service?: null;
  date_accepted?: null;
}
export interface DealershipDetail {
  data: Dataa;
}
export interface Dataa {
  id: string;
  type: string;
  attributes: Attributes1;
}
export interface Attributes1 {
  dealership_name: string;
  business_credentials: string;
  deal_type?: (string)[] | null;
  created_at: string;
  updated_at: string;
  dealership_users: number;
  addresses: Addresses;
  bank_information: BankInformation;
  dealership_logo?: null;
  w9_forms?: (null)[] | null;
}
export interface Addresses {
  data?: (DataEntity1)[] | null;
}
export interface DataEntity1 {
  id: string;
  type: string;
  attributes: Attributes2;
}
export interface Attributes2 {
  address: string;
  address_type: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}
export interface BankInformation {
  data?: (null)[] | null;
}
export interface Account {
  data: Data1;
}
export interface Data1 {
  id: string;
  type: string;
  attributes: Attributes3;
}
export interface Attributes3 {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name: string;
  role: string;
  profile_picture?: null;
  group_created_on: string;
  is_dealership_pending: boolean;
  dealership_data?: null;
  solo_dealership_id?: null;
  is_any_dealership: boolean;
  solo_dealership_data?: null;
  assigned_dealership_ids?: null;
}

interface TableType{
  id: number,
  page:number,
  totalRecords:number
}

interface ResponseError{
  errors:[
    {message: string}
  ]
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profilePhoto:string | null |undefined;
  tabvalue: number;
  searchValue: string,
  sortColumn: string,
  filterState: string,
  page: number,
  rowsPerPage:number,
  role:string,
  totalRecords: number,

  sortOption:string,
  totaldeals:number,
  loading: boolean,
  currentTab:string,
  tables: TableType[],
  ongoing_total: number | undefined,
  pending_hire_total: number| undefined,
  deleted_total: number| undefined,
  draft_total: number| undefined,
  completed_total: number| undefined,

  accepted_total: number | undefined,
  rejected_or_expired_total: number | undefined

  tableData: TableRows[],
  tablePendingData: TablePendingDataRow[],
  tableDataCompleted: TableDataDeletedorCompleted[],
  tableDataDeleted: TableDataDeletedorCompleted[],
  tableDataDraft: TableDraftDataRow[],

  tableDataAcceptedSp:TableDataAcceptedSp[],
  tablePendingDataSp: TablePendingDataSp[],
  deletedDatatableSp:DeletedDatatableSp[],
  tableRejectedorExpiredDataSp: RejectedOrExpiredData[]
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AlldealsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changeNameApiCallId:string="";
  getuserDataApiCallId:string="";
  sendContactUsApiCallId:string="";
  getDealDataApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      profilePhoto:'',
      tabvalue:0,
      searchValue: '',
      sortColumn: '',
      filterState: '',
      page:0,
      rowsPerPage:8,
      totalRecords: 0,
      role:'',

      sortOption:'',
      totaldeals:0,
      loading: true,
      currentTab:'',
      ongoing_total: 0,
      pending_hire_total: 0,
      deleted_total: 0,
      draft_total: 0,
      completed_total: 0,

      accepted_total: 0,
      rejected_or_expired_total: 0,
      tables: [
        {id:1,  page: 0, totalRecords: 10 },
        {id:2,   page: 0, totalRecords: 10 },
        {id:3,   page: 0, totalRecords: 10 },
        {id:4,   page: 0, totalRecords: 10 },
        { id:5,  page: 0, totalRecords: 10 },
      ],

    tableDataAcceptedSp : [],
    tableData : [],
    tablePendingData : [],
    tableDataCompleted:[],
    tableDataDeleted : [],
    tableDataDraft : [],
    tablePendingDataSp : [],
    deletedDatatableSp : [],
    tableRejectedorExpiredDataSp : [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.getuserDataApiCallId){
            this.handleUserData(responseJson);
        }
        else if(apiRequestCallId===this.getDealDataApiCallId){
          this.handleDealdata(responseJson)
        }
      }
    } 

    // Customizable Area End
  }

  // Customizable Area Start

  handleDealdata=(responseJson:ResponseDataBE & ResponseBESp & ResponseError)=>{
    if(responseJson.errors){
      this.setState({totaldeals: 0, loading: false})
    }
    else if(this.checkRole()){
      this.setState({totaldeals: responseJson.total_deals, loading: false,
        ongoing_total: responseJson.ongoing_total,
        pending_hire_total: responseJson.pending_hire_total,
        deleted_total: responseJson.deleted_total,
        draft_total: responseJson.draft_total,
        completed_total: responseJson.completed_total,
      })
      this.setArgs(responseJson.ongoing_total, responseJson.pending_hire_total, responseJson.deleted_total,responseJson.completed_total, responseJson.draft_total)
      this.setOngoingdata(responseJson)
      this.setPendingData(responseJson)
      this.setDeleteddata(responseJson)
      this.setCompletedData(responseJson)
      this.setDraftdata(responseJson)

    }else if(this.state.role === ('service_provider' || 'service_provider_admin')){
      this.setState({
        totaldeals: responseJson.total_deals, 
        loading: false,
        accepted_total: responseJson.accepted_total,
        pending_hire_total: responseJson.pending_total,
        deleted_total: responseJson.deleted_total,
        rejected_or_expired_total: responseJson.rejected_or_expired_total
      })
      this.setArgs(responseJson.accepted_total, responseJson.pending_total, responseJson.rejected_or_expired_total, responseJson.deleted_total, 0)
      this.setAcceptedSpData(responseJson)
      this.setPendingDataSp(responseJson)
      this.setRejectedExpiredDataSp(responseJson)
      this.setDeletedDataSp(responseJson)
    }
  }


  setArgs = (value1: number|undefined, value2:number|undefined, value3:number|undefined, value4:number|undefined, value5:number|undefined) => {
    const valueMap: (number | undefined)[] = [value1, value2, value3, value4, value5];
    const updatedTables: TableType[] = this.state.tables.map((table): TableType => {
      const newValue = valueMap[table.id - 1];
      return { ...table, totalRecords: newValue ?? table.totalRecords };
  });

  this.setState({ tables: updatedTables });
  };

  convertToReadableFormat(input:string) {
    return input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); 
  }

  setOngoingdata=(responseJson:ResponseDataBE)=>{
    if(responseJson.ongoing.data) {
      const transformedData = responseJson.ongoing.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateAccepted: deal.attributes.date_accepted ? new Date(deal.attributes.date_accepted).toLocaleDateString() : "-",
        service: deal.attributes.service || "-",
        fees: deal.attributes.fees_data?.total_fee || "N/A",
        serviceProvider: deal.attributes.service_provider || "-",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({tableData: transformedData})
  }
  }

  setPendingData = (responseJson:ResponseDataBE) => {
    if(responseJson.pending_hire.data) {
      const transformedPendingdata = responseJson.pending_hire.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateSent: new Date(deal.attributes.date_created).toLocaleDateString(), 
      service: deal.attributes.service,
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: deal.attributes.pending_tab_status,
    }));
    this.setState({tablePendingData: transformedPendingdata})
  }
  };

  setDeleteddata = (responseJson:ResponseDataBE)=>{
    if(responseJson.deleted.data){
    const transformedDeleteddata = responseJson.deleted.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      dateCancelled: new Date(deal.attributes.date_cancelled).toLocaleDateString(),
      service: deal.attributes.service,
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: 'Deleted'
    }));
    this.setState({tableDataDeleted: transformedDeleteddata})
  }
  };

  setCompletedData=(responseJson:ResponseDataBE)=>{
    if(responseJson.completed.data){
    const transformedCompletedddata = responseJson.completed.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      dateCompleted: new Date(deal.attributes.date_completed).toLocaleDateString(),
      service: deal.attributes.service,
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: 'Completed'
    }));
    this.setState({tableDataCompleted: transformedCompletedddata})
  }
  }

  setDraftdata=(responseJson:ResponseDataBE)=>{
    if(responseJson.draft.data){
    const transformedDraftddata = responseJson.draft.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      typeOfVehicle: deal.attributes.vehicle_type,
      applicationType: deal.attributes.application_type,
    }));
    this.setState({tableDataDraft: transformedDraftddata})
  }
  }

  setAcceptedSpData=(responseJson: ResponseBESp)=>{
    if(responseJson.accepted.data) {
      const transformedacceptedData = responseJson.accepted.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateReceived: new Date(deal.attributes.date_received).toLocaleDateString(),
        dateAccepted: deal.attributes.date_accepted ? new Date(deal.attributes.date_accepted).toLocaleDateString() : "-",
        service: this.parseServiceString(deal.attributes.service) || "-",
        fees: deal.attributes.fees_data?.total_fee || "N/A",
        payout: deal.attributes.fees_data?.payout || "N/A",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({tableDataAcceptedSp: transformedacceptedData})
  }
  }

  setPendingDataSp=(responseJson: ResponseBESp)=>{
    if(responseJson.pending.data) {
      const transformedPendingSpData = responseJson.pending.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateReceived: new Date(deal.attributes.date_received).toLocaleDateString(),
        service: this.parseServiceString(deal.attributes.service) || "-",
        payout: deal.attributes.fees_data?.payout || "N/A",
        status: 'Available' 
      }));
      this.setState({tablePendingDataSp: transformedPendingSpData})
  }
  }

  setRejectedExpiredDataSp=(responseJson: ResponseBESp)=>{
    if(responseJson.rejected_or_expired.data) {
      const transformedrejectedExpiredData = responseJson.rejected_or_expired.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateRejectedOrExpired: deal.attributes.date_rejected_or_expired ? new Date(deal.attributes.date_rejected_or_expired).toLocaleDateString() : "-",
        service: this.parseServiceString(deal.attributes.service) || "-",
        payout: deal.attributes.fees_data?.payout || "N/A",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({tableRejectedorExpiredDataSp: transformedrejectedExpiredData})
  }
  }

  setDeletedDataSp=(responseJson: ResponseBESp)=>{
    if(responseJson.deleted.data) {
      const transformedDeletedSpData = responseJson.deleted.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateDeleted: deal.attributes.date_deleted ? new Date(deal.attributes.date_deleted).toLocaleDateString() : "-",
        service: this.parseServiceString(deal.attributes.service) || "-",
        fees: deal.attributes.fees_data?.total_fee || "N/A",
        payout: deal.attributes.fees_data?.payout || "N/A",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({deletedDatatableSp: transformedDeletedSpData})
  }
  }

  handleUserData=(responseJson:GetUserResponseSuccess & GetUserError)=>{
    if(!responseJson.errors){
      this.setState({role:responseJson.data.attributes.role}, ()=>{this.setCurrentTab(0)})
    }
  }

  parseServiceString = (serviceString: string): string => {
    let parsedValue = "-"; 
    if (serviceString) {
      const validJsonString = serviceString.replace(/=>/g, ":");
      if (validJsonString.startsWith("{") && validJsonString.endsWith("}")) {
        const serviceObject = JSON.parse(validJsonString);
        const firstKey = Object.keys(serviceObject)[0];
        parsedValue = firstKey || "-";
      }
    }
    return parsedValue;
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ 
        tabvalue: newValue, 
        searchValue: '', 
        sortColumn: '',
        filterState: '',
        sortOption:''
    },
    ()=>{this.setCurrentTab(this.state.tabvalue);});
};

setCurrentTab=(tabval:number)=>{
  if(this.checkRole()){
   switch (tabval) {
    case 0:
      this.setState({currentTab: 'ongoing'})
      break;
    case 1:
      this.setState({currentTab: 'pending_hire'})
      break;
    case 2:
      this.setState({currentTab: 'deleted'})
      break;
    case 3:
      this.setState({currentTab: 'completed'})
      break;
    case 4:
      this.setState({currentTab: 'draft'})
      break;
   }

  }else if(this.state.role === ('service_provider' || 'service_provider_admin')){
    switch (tabval) {
      case 0:
        this.setState({currentTab: 'accepted'})
        break;
      case 1:
        this.setState({currentTab: 'pending'})
        break;
      case 2:
        this.setState({currentTab: 'rejected_or_expired'})
        break;
      case 3:
        this.setState({currentTab: 'deleted'})
        break;
     }
  }
}

  a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
};


handleSort = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  this.setState({ sortOption: event.target.value as string });
};

checkDisablitiy = (status:string, field:string)=>{
    if(status==='Rejected' && field==='withdraw'){
        return true
    }
    else  if(status==='Rejected' && field==='hirenew'){
        return false
    }
    else  if(status==='No Response' && field==='withdraw'){
        return false
    }
    else return true
}


  handleChangePage = (event: unknown, newPage: number) => {
    const { tabvalue } = this.state;
    this.setState((prevState) => {
      const updatedTables = prevState.tables.map((table, index) => {
        if (index === tabvalue) {
          return { ...table, page: newPage };
        }
        return { ...table, page: 0 };
      });
      return { tables: updatedTables, loading: true };
    }, () => {
      const { tables, rowsPerPage, currentTab } = this.state;
      this.getDealsFromBe(tables[tabvalue].page, rowsPerPage, currentTab);
    });
  };
  
  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { tabvalue } = this.state;
    const rowsPerPage = parseInt(event.target.value, 8);
    this.setState((prevState) => {
      const updatedTables = prevState.tables.map((table, index) => {
        if (index === tabvalue) {
          return { ...table, page: 0 };
        }
        return { ...table, page:0 };
      });
      return { tables: updatedTables, rowsPerPage, loading: true };
    }, () => {
      const { tables, rowsPerPage, currentTab } = this.state;
      this.getDealsFromBe(tables[tabvalue].page, rowsPerPage, currentTab);
    });
  };

  async componentDidMount(){
    this.getUserDataFromBe();
    this.getDealsFromBe(this.state.tables[this.state.tabvalue].page, this.state.rowsPerPage, this.state.currentTab);
  }

  getDealsFromBe=async(page:number, rowperpage:number, currentTab:string)=>{
    const authtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/deals?page=${page + 1}&per_page=${rowperpage}&flag=${currentTab}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getUserDataFromBe=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

checkRole = ()=>{
  return (this.state.role === 'dealer' || this.state.role === 'dealer_admin')
}

 getSortOptions = (tabIndex : number) => {
  if(this.checkRole()){
    return ['Deal Id', "Customer's last name", 'Dealership', 'Date Sent'];
  }else{
  switch(tabIndex) {
    case 0: 
      return ['Dealership (A-Z)', 'Most Recent (Date Received)', 'Payout (High to Low)'];
    case 1: 
      return ['Dealership (A-Z)', 'Most Recent (Date Received)', 'Payout (High to Low)'];
    case 2: 
      return ['Dealership (A-Z)', 'Most Recent (Date Rejected)', 'Payout (High to Low)'];
    case 3: 
      return ['Dealership (A-Z)', 'Most Recent (Date Deleted)', 'Payout (High to Low)'];
    default:
      return [];
  }
  }
}
  // Customizable Area End

}